import { internalLinkInterface } from "../../../interfaces/elements/internalLinkInterface";
import { Link } from "react-router";
import { lazy, MouseEvent, startTransition, Suspense, useContext } from "react";
import { UrlContext } from "../../../contexts/urlContext";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export default function InternalLink(props: internalLinkInterface) {
  const url = useContext(UrlContext);
  const login = useAdminLoginContext((s) => s.isLogin);
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (activeMode.mode !== activeModes.off) {
      e.preventDefault();
      return;
    }
  };

  const showElement = () => {
    if (props.active) {
      return true;
    } else {
      return !!login;
    }
  };

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    if (!showElement()) {
      return null;
    }
    return (
      <div
        ref={refs}
        className={classNames(
          "button",
          props.pos,
          props.stil,
          animations.animationClasses,
          animations.animate,
          isEditMode ? "jslink" : "",
        )}
      >
        <Link
          target="_self"
          to={
            props.deleted
              ? url
              : url + (props.path !== "" ? "/" + props.path : props.path)
          }
          hrefLang=""
          title={props.title}
          onClick={handleLinkClick}
          viewTransition
        >
          {props.short_title}
        </Link>
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
