import {
  Dispatch,
  SetStateAction,
  startTransition,
  Suspense,
  useState,
} from "react";
import LoginModal from "../../popups/auth/loginModal";
import LogoutModal from "../../popups/auth/logoutModal";
import classNames from "classnames";
import { staticButtons } from "../../popups/edit/user/staticButtons";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import EditElement from "../../app/basicElements/editElement";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";
import { useUserLoginContext } from "../../../functions/auth/useUserLoginContext";

export default function UserLoginButton(props: staticButtons) {
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const login = useAdminLoginContext((s) => s.isLogin);
  const userLogin = useUserLoginContext((s) => s.isLogin);

  const closeModal = (setModalState: Dispatch<SetStateAction<boolean>>) => {
    setModalState(false);
  };

  const openModal = () => {
    if (activeMode.mode === activeModes.off) {
      startTransition(() => {
        if (login || userLogin) {
          setIsLogoutModalOpen(true);
        } else {
          setIsLoginModalOpen(true);
        }
      });
    }
  };
  const renderElement = (isEditMode: boolean) => {
    return (
      <div
        className={classNames(
          "button",
          props.pos,
          props.stil,
          animations.animationClasses,
          animations.animate,
          isEditMode ? "jslink" : "",
        )}
      >
        <button onClick={openModal}>
          {login || userLogin ? "Logout" : "Login"}
        </button>
        {isLoginModalOpen && (
          <Suspense fallback={null}>
            <LoginModal close={() => closeModal(setIsLoginModalOpen)} />
          </Suspense>
        )}
        {isLogoutModalOpen && (
          <Suspense fallback={null}>
            <LogoutModal close={() => closeModal(setIsLogoutModalOpen)} />
          </Suspense>
        )}
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
