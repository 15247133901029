import { create } from "zustand";
import { persist } from "zustand/middleware";
import { produce } from "immer";

interface baseCookieProps {
  baseCookie: boolean;
  googleAnalytics: boolean;
  googleAnalyticsID: string;
  setGoogleAnalyticsTrue: () => void;
  setGoogleAnalyticsFalse: () => void;
  setTrue: () => void;
  setFalse: () => void;
  setGoogleAnalyticsID: (id: string) => void;
  removeGoogleAnalyticsID: () => void;
  googleTagManager: boolean;
  googleTagManagerID: string;
  setGoogleTagManagerTrue: () => void;
  setGoogleTagManagerFalse: () => void;
  setGoogleTagManagerID: (id: string) => void;
  removeGoogleTagManagerID: () => void;
}

export const useBaseCookie = create<baseCookieProps>()(
  persist(
    (set) => ({
      baseCookie: false,
      googleAnalytics: false,
      googleAnalyticsID: "",
      googleTagManager: false,
      googleTagManagerID: "",
      setGoogleAnalyticsTrue: () => {
        set(
          produce((state: baseCookieProps) => {
            state.googleAnalytics = true;
          }),
        );
      },
      setGoogleAnalyticsFalse: () => {
        set(
          produce((state: baseCookieProps) => {
            state.googleAnalytics = false;
          }),
        );
      },
      setTrue: () => {
        set(
          produce((state: baseCookieProps) => {
            state.baseCookie = true;
          }),
        );
      },
      setFalse: () => {
        set(
          produce((state: baseCookieProps) => {
            state.baseCookie = false;
          }),
        );
      },
      setGoogleAnalyticsID: (id) => {
        set(
          produce((state: baseCookieProps) => {
            state.googleAnalyticsID = id;
          }),
        );
      },
      removeGoogleAnalyticsID: () => {
        set(
          produce((state: baseCookieProps) => {
            state.googleAnalyticsID = "";
          }),
        );
      },
      setGoogleTagManagerTrue: () => {
        set(
          produce((state: baseCookieProps) => {
            state.googleTagManager = true;
          }),
        );
      },
      setGoogleTagManagerFalse: () => {
        set(
          produce((state: baseCookieProps) => {
            state.googleTagManager = false;
          }),
        );
      },
      setGoogleTagManagerID: (id) => {
        set(
          produce((state: baseCookieProps) => {
            state.googleTagManagerID = id;
          }),
        );
      },
      removeGoogleTagManagerID: () => {
        set(
          produce((state: baseCookieProps) => {
            state.googleTagManagerID = "";
          }),
        );
      },
    }),
    {
      name: "baseCookieStore",
      version: 3,
    },
  ),
);
