import { postToApi } from "../../apiCommunication/apiComm";
import { apiUrls } from "../../../../enums/apiUrls";
import { basicApiResponse } from "../../../../interfaces/basicApiRespone";

export function getStickyContactData() {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.getStickyContactData, {})
      .then((data: basicApiResponse) => {
        if (data.data.success) {
          resolve(data.data.result);
        } else {
          reject(data.data.error);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
