import { animationInterface } from "../../interfaces/animations/animationInterface";

export function buildAnimationString(animationsArray: animationInterface[]) {
  let animations = "";
  let behaviours = "";
  let times = "";

  //sort animationsArray by alphabetical order
  animationsArray = [...animationsArray].sort((a, b) => {
    if (a.behavior < b.behavior) {
      return -1;
    }
    if (a.behavior > b.behavior) {
      return 1;
    }
    return 0;
  });

  //delete all animations with 'none' as animation
  animationsArray = animationsArray.filter((animation) => {
    return animation.animation !== "none";
  });

  animationsArray.forEach((animation, i) => {
    if (animation.animation !== "none") {
      behaviours += animation.behavior;
      behaviours += "-";
      times += i === 0 ? "ms" + animation.time : animation.time;
      times += "-";
    }
  });
  if (behaviours !== "") {
    animations =
      behaviours.substring(0, behaviours.length - 1) +
      " " +
      times.substring(0, times.length - 1);
  }
  return animations;
}
