import { useContext } from "react";
import { ParallaxContext } from "../../contexts/parallaxContext";
import { ParallaxController } from "../../classes/ParallaxController";

export function useParallaxController(): ParallaxController | null {
  const parallaxController = useContext(ParallaxContext);
  const isServer = typeof window === "undefined";
  if (isServer) {
    return null;
  }

  if (!parallaxController) {
    throw new Error("Error no ParallaxProvider set");
  }

  return parallaxController;
}
