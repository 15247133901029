import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { LoginResponseInterface } from "./login";
import { setToken } from "./token";

export function refreshToken() {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.refreshToken, {})
      .then((res: LoginResponseInterface) => {
        if (res.data.success) {
          setToken(res.data.token);
          resolve(res.data.token);
        } else {
          reject(res.data.error);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
