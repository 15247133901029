import { StrictMode } from "react";
import { AppContent } from "./appContent";
import { appProps } from "../../../interfaces/appProps";
import { SWRConfig } from "swr";
import { UrlContext } from "../../../contexts/urlContext";
import { StatsProvider } from "../providers/statsProvider";
import { AdminLoginProvider } from "../providers/adminLoginProvider";
import { UserLoginProvider } from "../providers/userLoginProvider";
import { GridDivCounterProvider } from "../providers/gridDivCounterProvider";

export function App({ host, isLogin, role, userLogin, ...props }: appProps) {
  return (
    <StrictMode>
      <SWRConfig value={{ fallbackData: props }}>
        <StatsProvider>
          <UrlContext.Provider value={host || ""}>
            <GridDivCounterProvider>
              <AdminLoginProvider isLogin={isLogin} role={role}>
                <UserLoginProvider isLogin={userLogin}>
                  <AppContent />
                </UserLoginProvider>
              </AdminLoginProvider>
            </GridDivCounterProvider>
          </UrlContext.Provider>
        </StatsProvider>
      </SWRConfig>
    </StrictMode>
  );
}
