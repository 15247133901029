import { useState, ChangeEvent } from "react";
import { produce } from "immer";
import { LinkStilSelect } from "../selects/linkStilSelect";
import { elementEditorModes } from "../../../../enums/edit/elementEditorModes";
import { animationInterface } from "../../../../interfaces/animations/animationInterface";
import PopupWrapper from "../../popupWrapper";
import { SvgImage } from "../../../app/basicElements/svgImage";
import { Svg } from "../../../../enums/svg";
import { ButtonPosSelect } from "../selects/buttonPosSelect";
import { AnimationsSelect } from "../selects/animationsSelect";
import { commonElementInterface } from "../../../../interfaces/elements/common/commonElementInterface";

export interface staticButtons extends commonElementInterface {
  stil: string;
  pos: string;
  animations: animationInterface[];
}

export default function StaticButtons(props: {
  elementData: staticButtons;
  mode: elementEditorModes;
  handleElementSaving: (elementData: staticButtons) => void;
  handleClosing?: () => void;
  handleDelete?: () => void;
}) {
  const [editorState, setEditorState] = useState<staticButtons>(
    props.elementData,
  );

  const handleAnimationChange = (animations: animationInterface[]) => {
    setEditorState(
      produce(editorState, (draftState) => {
        draftState.animations = animations;
      }),
    );
  };

  const handleStilChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEditorState(
      produce(editorState, (draftState) => {
        draftState.stil = e.currentTarget.value;
      }),
    );
  };

  const handlePosChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEditorState(
      produce(editorState, (draftState) => {
        draftState.pos = e.currentTarget.value;
      }),
    );
  };

  const handleElementSaving = () => {
    props.handleElementSaving(editorState);
  };

  return (
    <PopupWrapper>
      <div id="linkextern" className="large">
        <span>{props.elementData.element}</span>
        <div className="container">
          <hr className="half" />
          <label htmlFor="linkStil" className="w50 floatleft">
            <span className="icon">Farbe</span>
            <SvgImage image={Svg.color} />
            <LinkStilSelect
              id="linkStil"
              className="icon"
              onChange={handleStilChange}
              value={editorState.stil}
            />
          </label>
          <label htmlFor="linkPos" className="w50">
            <span className="icon">Anordnung</span>
            <SvgImage image={Svg.list} />
            <ButtonPosSelect
              id="linkPos"
              className="icon"
              onChange={handlePosChange}
              value={editorState.pos}
            />
          </label>
          <AnimationsSelect
            handleAnimationChange={handleAnimationChange}
            data={editorState.animations}
          />
          <hr />
        </div>
        <button id="rt" onClick={props.handleClosing}>
          <SvgImage image={Svg.delete} />
        </button>
        {props.mode === elementEditorModes.edit && (
          <button id="lb" onClick={props.handleDelete}>
            <SvgImage image={Svg.trash} />
          </button>
        )}
        <button id="rb" onClick={handleElementSaving}>
          <SvgImage image={Svg.check} />
        </button>
      </div>
    </PopupWrapper>
  );
}
