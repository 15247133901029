import { imageZoomContext } from "../../../contexts/imageZoomContext";
import { PropsWithChildren, useRef } from "react";
import { ImageZoomController } from "../../../classes/imageZoomController";

export function ImageZoomProvider(props: PropsWithChildren) {
  const useImageZoomContext = useRef<null | ImageZoomController>(null);

  if (typeof window !== "undefined" && useImageZoomContext.current === null)
    useImageZoomContext.current = new ImageZoomController();

  return (
    <imageZoomContext.Provider value={useImageZoomContext.current}>
      {props.children}
    </imageZoomContext.Provider>
  );
}
