import { PropsWithChildren, useRef } from "react";
import { userLoginState } from "../../../stateManagement/auth/userLoginState";
import { UserLoginContext } from "../../../contexts/userLoginContext";

type userLoginProviderProps = PropsWithChildren<{
  isLogin: boolean;
}>;
export function UserLoginProvider({
  children,
  ...props
}: userLoginProviderProps) {
  const storeRef = useRef<userLoginState>(null);
  if (!storeRef.current) {
    storeRef.current = userLoginState(props);
  }
  return (
    <UserLoginContext.Provider value={storeRef.current}>
      {children}
    </UserLoginContext.Provider>
  );
}
