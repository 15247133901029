import { RefObject } from "react";
import { ParallaxElement } from "./parallaxElement";

function testForPassiveScroll() {
  let supportsPassiveOption = false;
  try {
    const opts = Object.defineProperty({}, "passive", {
      get() {
        supportsPassiveOption = true;
        return true;
      },
    });
    window.addEventListener("test", null, opts);
    window.removeEventListener("test", null, opts);
  } catch (e) {
    /* empty */
  }
  return supportsPassiveOption;
}

export class ParallaxController {
  elements: ParallaxElement[];
  scrollContainer: Window;
  hasPassive: boolean;
  tick: boolean;
  scroll: number;
  vh: number;

  static init() {
    if (typeof window === "undefined") {
      throw new Error("Server Side");
    }

    return new ParallaxController();
  }

  constructor() {
    this.elements = [];
    this.scrollContainer = window;
    this.hasPassive = testForPassiveScroll();
    this.tick = false;
    this.addListeners();
    this.updateScrollAndVh();
  }

  getWindowData() {
    return [this.scrollContainer.scrollY, this.scrollContainer.outerHeight];
  }

  setScrollAndVh(scroll: number, vh: number) {
    this.scroll = scroll;
    this.vh = vh;
  }

  updateScrollAndVh() {
    const [scroll, vh] = this.getWindowData();
    this.setScrollAndVh(scroll, vh);
  }

  handleScroll() {
    this.updateScrollAndVh();
    // Only called if the last animation request has been
    // completed and there are parallax elements to update
    if (!this.tick && this.elements.length > 0) {
      this.tick = true;
      window.requestAnimationFrame(() => {
        this.updateAllElements();
      });
    }
  }

  handleUpdateCache() {
    this.updateScrollAndVh();
    this.updateAllElements();
  }

  addListeners() {
    this.scrollContainer.addEventListener(
      "scroll",
      () => {
        this.handleScroll();
      },
      this.hasPassive ? { passive: true } : false,
    );
    window.addEventListener(
      "resize",
      () => {
        this.handleUpdateCache();
      },
      this.hasPassive ? { passive: true } : false,
    );
    window.addEventListener(
      "blur",
      () => {
        this.handleUpdateCache();
      },
      this.hasPassive ? { passive: true } : false,
    );
    window.addEventListener(
      "focus",
      () => {
        this.handleUpdateCache();
      },
      this.hasPassive ? { passive: true } : false,
    );
    window.removeEventListener(
      "load",
      () => {
        this.handleUpdateCache();
      },
      false,
    );
  }

  removeListeners() {
    this.scrollContainer.removeEventListener(
      "scroll",
      () => {
        this.handleScroll();
      },
      false,
    );
    window.removeEventListener(
      "resize",
      () => {
        this.handleUpdateCache();
      },
      false,
    );
    window.removeEventListener(
      "blur",
      () => {
        this.handleUpdateCache();
      },
      false,
    );
    window.removeEventListener(
      "focus",
      () => {
        this.handleUpdateCache();
      },
      false,
    );
    window.removeEventListener(
      "load",
      () => {
        this.handleUpdateCache();
      },
      false,
    );
    //this._resizeObserver?.disconnect();
  }

  /*
  addResizeObserver() {
    try {
      const observedEl = this.scrollContainer;
      this.resizeObserver = new ResizeObserver(() => this.update());
      this.resizeObserver.observe(observedEl);
    } catch (e) {
      console.warn(
        'Failed to create the resize observer in the ParallaxController'
      );
    }
  }
   */

  createElement(
    ref: RefObject<HTMLDivElement>,
    pictureRef: RefObject<HTMLElement>,
    imgRef: RefObject<HTMLElement>,
  ): ParallaxElement {
    const newElement = new ParallaxElement(
      ref,
      pictureRef,
      imgRef,
      this.scroll,
      this.vh,
    );
    this.elements = this.elements
      ? [...this.elements, newElement]
      : [newElement];
    return newElement;
  }

  removeElementById(id: number) {
    if (!this.elements) return;
    this.elements = this.elements.filter((el) => el.id !== id);
  }

  updateElement(element: ParallaxElement) {
    element.setCachedValues(this.scroll, this.vh);
    element.calculationFunction();
  }

  public updateAllElements() {
    if (this.elements) {
      this.elements.forEach((element) => {
        this.updateElement(element);
      });
    }
    // reset ticking so more animations can be called
    this.tick = false;
  }

  updateElementById(id: number) {
    if (this.elements) {
      this.elements = this.elements.map((el) => {
        if (el.id === id) {
          return el.calculationFunction();
        }
        return el;
      });
    }
  }

  destroy() {
    this.removeListeners();
  }
}
