let gridIds = 0;

export function setGridDivId() {
  gridIds++;
  return gridIds;
}
export class GridDivCounterController {
  gridDivs: { id: number }[];
  constructor() {
    this.gridDivs = [];
  }

  addGridDiv() {
    this.gridDivs.push({
      id: setGridDivId(),
    });
    return this.gridDivs[this.gridDivs.length - 1];
  }

  removeGrid(id: number) {
    this.gridDivs = this.gridDivs.filter((grid) => grid.id !== id);
  }

  getSpecial(id: number) {
    const index = this.gridDivs.findIndex((image) => image.id === id);
    if (index === -1) {
      return false;
    }
    if (index === 0) {
      return "first";
    }
    if (index === this.gridDivs.length - 1) {
      return "last";
    }
    return false;
  }
}
