import { pageSeoData } from "../../../../backend/interfaces/requests/page/pageSeoData";
import { useContext } from "react";
import { HeaderContent } from "./headerContent";
import { type } from "../../../../backend/enums/database/kunden/openGraph/type";
import { UrlContext } from "../../../contexts/urlContext";
export function HeaderManager(props: {
  seo: pageSeoData;
  openGraph?: { type: type; value: string }[];
  style?: string;
  cssVariant?: "dev" | "live";
  host?: string;
}) {
  const host = useContext(UrlContext);

  return (
    <HeaderContent
      seo={props.seo}
      openGraph={props.openGraph}
      style={props.style}
      host={host}
    />
  );
}
