import { ChangeEvent, useState } from "react";
import classNames from "classnames";

export interface formTextAreaProps {
  element: string;
  name: string;
  labelText?: string;
  value: string;
  dependencyFulfilled: boolean;
  fullWidth?: boolean;
  hideRequired?: boolean;
  required?: boolean;
  placeholder?: string;
  keyId?: string;
  handleInput: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}
export function FormTextArea(props: formTextAreaProps) {
  const required = props.required === undefined ? false : props.required;
  const [error, setError] = useState(false);
  if (props.dependencyFulfilled === false) return null;

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (props.required && e.target.value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
    props.handleInput(e);
  };

  return (
    <label htmlFor={props.name + props.keyId}>
      {props.fullWidth ? null : (
        <p>
          {props.labelText}{" "}
          {props.required &&
          (typeof props.hideRequired === "undefined" ||
            props.hideRequired === false)
            ? "*"
            : ""}
        </p>
      )}
      <textarea
        required={required}
        value={props.value}
        name={props.name}
        id={props.name + props.keyId}
        onChange={handleInput}
        placeholder={props.placeholder}
        className={classNames(props.fullWidth ? "full" : null)}
      />
    </label>
  );
}
