import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { lazy, MouseEvent, startTransition, Suspense, useContext } from "react";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { socialLinkInterface } from "../../popups/edit/socialLinkEdit";
import { ContactAndSocialMediaDataContext } from "../../../contexts/contactAndSocialMediaDataContext";
import { firstCharUpper } from "../../../functions/generall/firstCharUpper";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);
export default function SocialLink(props: socialLinkInterface) {
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();
  const socialMedia = useContext(ContactAndSocialMediaDataContext);
  const handleLinkClick = (e: MouseEvent<HTMLDivElement>) => {
    if (activeMode.mode !== activeModes.off) {
      e.preventDefault();
      return;
    }
  };

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    return (
      <div
        ref={refs}
        className={classNames(
          "button",
          "sociallink",
          props.pos,
          props.stil,
          animations.animationClasses,
          animations.animate,
          isEditMode ? "jslink" : "",
        )}
        onClick={handleLinkClick}
      >
        <a
          className={classNames(props.type)}
          target="_blank"
          href={socialMedia.socialMedia[props.type]}
          hrefLang=""
          title={firstCharUpper(props.type)}
          rel="noopener noreferrer"
        >
          <SvgImage image={Svg[props.type]} />
        </a>
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
