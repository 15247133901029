import { useEffect, useState, ReactNode } from "react";
import { getModules } from "../../../functions/api/edit/getModules";

export enum optionsMode {
  dataprop = "dataprop",
  value = "value",
}
export function ModulesAsOptions(props: {
  mode: optionsMode;
  valueType: "id" | "path";
}) {
  const [options, setOptions] = useState<ReactNode>(null);
  useEffect(() => {
    getModules()
      .then((result) => {
        const data = result.map((module) => {
          if (props.mode === optionsMode.dataprop) {
            return (
              <option
                key={module.id}
                value={"link" + module.id}
                data-prop={props.valueType === "id" ? module.id : module.path}
              >
                {module.short_title.charAt(0).toUpperCase() +
                  module.short_title.slice(1)}
              </option>
            );
          } else if (props.mode === optionsMode.value) {
            return (
              <option
                key={module.id}
                value={props.valueType === "id" ? module.id : module.path}
              >
                {module.short_title.charAt(0).toUpperCase() +
                  module.short_title.slice(1)}
              </option>
            );
          }
          return (
            <option
              key={module.id}
              value={props.valueType === "id" ? module.id : module.path}
            >
              {module.short_title.charAt(0).toUpperCase() +
                module.short_title.slice(1)}
            </option>
          );
        });
        setOptions(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.mode]);

  return options;
}
