import { create, StateCreator } from "zustand";
import { persist } from "zustand/middleware";
import { produce } from "immer";

type remStatCookieProps = {
  statCookieRem: string;
  setRem: (remCookie: string) => void;
};

const remStatCookieConfig: StateCreator<
  remStatCookieProps,
  [["zustand/persist", unknown]]
> = (set) => ({
  statCookieRem: "",
  setRem: (remCookie: string) => {
    set(
      produce((state: remStatCookieProps) => {
        state.statCookieRem = remCookie;
      }),
    );
  },
});

export const remStatCookie = create<remStatCookieProps>()(
  persist(remStatCookieConfig, {
    name: "remStat",
  }),
);

interface tempStatCookieProps {
  statCookieTemp: string;
  setTemp: (tempCookie: string) => void;
}

export const tempStatCookie = create<tempStatCookieProps>()((set) => ({
  statCookieTemp: "",
  setTemp: (tempCookie: string) => {
    set(
      produce((state: tempStatCookieProps) => {
        state.statCookieTemp = tempCookie;
      }),
    );
  },
}));
