import React, { PropsWithChildren, useEffect, useRef } from "react";
import { ParallaxContext } from "../../../contexts/parallaxContext";
import { ParallaxController } from "../../../classes/ParallaxController";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import { delay } from "lodash";

const createController = () => {
  // Don't initialize on the server
  const isServer = typeof window === "undefined";

  if (!isServer) {
    // Must not be the server so kick it off...
    return ParallaxController.init();
  }
  return null;
};

const activeModeArray = [
  activeModes.off,
  activeModes.add,
  activeModes.grid,
  activeModes.move,
];

export function ParallaxProvider(props: PropsWithChildren) {
  const controller = useRef<null | ParallaxController>(null);
  const activeMode = useActiveMode();

  if (!controller.current) {
    controller.current = createController();
  }

  // remove the controller when unmounting
  useEffect(() => {
    return () => {
      controller.current && controller.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (activeModeArray.includes(activeMode.mode))
      delay(() => {
        controller.current && controller.current.updateAllElements();
      }, 600);
  }, [activeMode.mode]);

  return (
    <ParallaxContext.Provider value={controller.current}>
      {props.children}
    </ParallaxContext.Provider>
  );
}
