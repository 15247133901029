export enum Svg {
  "addbox" = "addbox",
  "addpage" = "addpage",
  "address" = "address",
  "animate" = "animate",
  "at" = "at",
  "back" = "back",
  "bell" = "bell",
  "blockquote" = "blockquote",
  "bold" = "bold",
  "boxes" = "boxes",
  "bug" = "bug",
  "button" = "button",
  "calendar" = "calendar",
  "camera" = "camera",
  "cart" = "cart",
  "cell" = "cell",
  "celladd" = "celladd",
  "celldelete" = "celldelete",
  "celledit" = "celledit",
  "check" = "check",
  "clock" = "clock",
  "color" = "color",
  "contact" = "contact",
  "contract" = "contract",
  "dailymotion" = "dailymotion",
  "de" = "de",
  "delete" = "delete",
  "description" = "description",
  "direction" = "direction",
  "dots" = "dots",
  "down" = "down",
  "download" = "download",
  "duplicate" = "duplicate",
  "edit" = "edit",
  "editor" = "editor",
  "elements" = "elements",
  "empty" = "empty",
  "en" = "en",
  "facebook" = "facebook",
  "favorite" = "favorite",
  "fax" = "fax",
  "figure" = "figure",
  "footer" = "footer",
  "format" = "format",
  "forward" = "forward",
  "function" = "function",
  "grid" = "grid",
  "h1" = "h1",
  "h2" = "h2",
  "h3" = "h3",
  "hashtag" = "hashtag",
  "header" = "header",
  "heart" = "heart",
  "highlight" = "highlight",
  "idea" = "idea",
  "image" = "image",
  "instagram" = "instagram",
  "invisible" = "invisible",
  "key" = "key",
  "left" = "left",
  "legende" = "legende",
  "level" = "level",
  "light" = "light",
  "line" = "line",
  "link" = "link",
  "linkedin" = "linkedin",
  "list" = "list",
  "login" = "login",
  "mail" = "mail",
  "marker" = "marker",
  "menu" = "menu",
  "messenger" = "messenger",
  "minus" = "minus",
  "move" = "move",
  "network" = "network",
  "opengraph" = "opengraph",
  "padding" = "padding",
  "padding2" = "padding2",
  "parent" = "parent",
  "password" = "password",
  "phone" = "phone",
  "plus" = "plus",
  "payment" = "payment",
  "pdf" = "pdf",
  "percent" = "percent",
  "phone1" = "phone1",
  "phone2" = "phone2",
  "pinterest" = "pinterest",
  "quote" = "quote",
  "radius" = "radius",
  "refresh" = "refresh",
  "right" = "right",
  "scroll" = "scroll",
  "search" = "search",
  "section" = "section",
  "settings" = "settings",
  "skype" = "skype",
  "sorted" = "sorted",
  "statistic" = "statistic",
  "status" = "status",
  "support" = "support",
  "svg" = "svg",
  "tableadd" = "tableadd",
  "tabledelete" = "tabledelete",
  "tableedit" = "tableedit",
  "telegram" = "telegram",
  "template" = "template",
  "tiktok" = "tiktok",
  "title" = "title",
  "trash" = "trash",
  "twitter" = "twitter",
  "unsorted" = "unsorted",
  "up" = "up",
  "upload" = "upload",
  "user" = "user",
  "ustid" = "ustid",
  "video" = "video",
  "viewmode" = "viewmode",
  "vimeo" = "vimeo",
  "visible" = "visible",
  "web" = "web",
  "whatsapp" = "whatsapp",
  "width" = "width",
  "xing" = "xing",
  "youtube" = "youtube",
  "zoom" = "zoom",
  "map" = "map",
  "image_upload" = "image_upload",
  "success" = "success",
  "load" = "load",
  child = "child",
  x = "x",
}
