import { useEffect, useRef, useState } from "react";
import { useParallaxController } from "./useParallaxController";
import { ParallaxElement } from "../../classes/parallaxElement";
import { gridStil } from "../../enums/edit/grid/gridStil";
import { useIsMounted } from "../generall/useIsMounted";

export function useParallax(stil: gridStil) {
  const controller = useParallaxController();
  const ref = useRef<HTMLDivElement>(null);
  const pictureRef = useRef<HTMLElement>(null);
  const imageRef = useRef<HTMLElement>(null);
  const [element, setElement] = useState<ParallaxElement>();
  const isMounted = useIsMounted();
  // create element
  useEffect(() => {
    let newElement: ParallaxElement;
    if (
      isMounted &&
      (stil === gridStil["Video (fest)"] || stil === gridStil["Bild (fest)"])
    ) {
      if (
        ref.current !== null &&
        pictureRef.current !== null &&
        imageRef.current !== null
      ) {
        newElement = controller?.createElement(ref, pictureRef, imageRef);
        setElement(newElement);
      } else {
        return undefined;
      }
      return () => {
        if (newElement) {
          controller?.removeElementById(newElement.id);
        }
      };
    } else if (typeof element !== "undefined") {
      controller?.removeElementById(element.id);
    }
    return undefined;
  }, [stil, isMounted]);

  /*
  // update element
  useEffect(() => {
    if (element) {
      if (props.disabled) {
        controller?.resetElementStyles(element);
        controller?.updateElementPropsById(element.id, parallaxProps);
      } else {
        controller?.updateElementPropsById(element.id, parallaxProps);
      }
    }
  }, [
    props.disabled,
    props.easing,
    props.endScroll,
    props.onChange,
    props.onEnter,
    props.onExit,
    props.onProgressChange,
    props.opacity,
    props.rootMargin,
    props.rotate,
    props.rotateX,
    props.rotateY,
    props.rotateZ,
    props.scale,
    props.scaleX,
    props.scaleY,
    props.scaleZ,
    props.shouldAlwaysCompleteAnimation,
    props.shouldDisableScalingTranslations,
    props.speed,
    props.startScroll,
    props.targetElement,
    props.translateX,
    props.translateY,
  ]);
   */

  return { ref, pictureRef, imageRef };
}
