import { useActiveMode } from "../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../enums/edit/activeModes";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { elementPos } from "../../enums/edit/elementPos";
import { createElement } from "react";
import { SvgImage } from "../../elements/app/basicElements/svgImage";
import { Svg } from "../../enums/svg";

export default function useGridMove(gridId?: string) {
  const gridModeActive = useActiveMode().mode === activeModes.gridMove;
  const dragGrid = useDraggable({
    id: gridId,
    disabled: !gridModeActive,
    data: { type: "element", id: gridId },
  });

  const dropGridBefore = useDroppable({
    id: gridId + "_up",
    data: { type: "element", id: gridId, pos: elementPos.before },
    disabled: !gridModeActive,
  });
  const dropGridAfter = useDroppable({
    id: gridId + "_down",
    data: { type: "element", id: gridId, pos: elementPos.after },
    disabled: !gridModeActive,
  });

  const isOverStyleUp = () => {
    if (dropGridBefore.isOver) {
      return {
        backgroundColor: "darkgray",
      };
    }
    return {};
  };
  const isOverStyleDown = () => {
    if (dropGridAfter.isOver) {
      return {
        backgroundColor: "darkgray",
      };
    }
    return {};
  };

  const setDropBefore = () => {
    if (gridModeActive) {
      return createElement(
        "s",
        {
          ref: dropGridBefore.setNodeRef,
          style: isOverStyleUp(),
          className: "jslink",
        },
        createElement(SvgImage, { image: Svg.download }),
      );
    } else {
      return null;
    }
  };

  const setDropAfter = () => {
    if (gridModeActive) {
      return createElement(
        "s",
        {
          ref: dropGridAfter.setNodeRef,
          style: isOverStyleDown(),
          className: "jslink",
        },
        createElement(SvgImage, { image: Svg.download }),
      );
    } else {
      return null;
    }
  };

  const transformOnMove = () => {
    if (dragGrid.isDragging) {
      //return {};
      return {
        //transform: `translate3d(${draggable.transform.x}px, ${draggable.transform.y}px, 1px)`,
        opacity: dragGrid.transform.x === 0 ? 1 : 0.5,
        //zIndex: 900,
      };
    }
    return {};
  };

  const setListeners = () => {
    if (gridModeActive) {
      return dragGrid.listeners;
    }
    return {};
  };

  const setNodeRef = (e?: HTMLElement) => {
    if (e === undefined) {
      return dragGrid.setNodeRef;
    }
    dragGrid.setNodeRef(e);
  };

  const setAttributes = () => {
    if (gridModeActive) {
      return dragGrid.attributes;
    }
    return {};
  };

  return {
    transformOnMove,
    setDropAfter,
    setDropBefore,
    setListeners,
    setNodeRef,
    setAttributes,
    gridModeActive,
  };
}
