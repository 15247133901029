import { getSvgUrl } from "../../../functions/api/graphics/getSvgUrl";
import { Svg } from "../../../enums/svg";
import InlineSVG from "react-inlinesvg";
import React from "react";

export function SvgImage(props: {
  image: Svg;
  onClick?: (e: React.MouseEvent) => void;
}) {
  return (
    <InlineSVG
      src={getSvgUrl(props.image)}
      cacheRequests={false}
      onClick={props.onClick ? props.onClick : null}
    />
  );
}
