import { PropsWithChildren } from "react";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import { DndContext, DragEndEvent, pointerWithin } from "@dnd-kit/core";
import { moveElement } from "../../../functions/api/edit/moveElement";
import { elementPos } from "../../../enums/edit/elementPos";
import { usePageMutator } from "../../../functions/api/page/usePageMutator";

export function Main(props: PropsWithChildren) {
  const activeMode = useActiveMode();
  const pageMutate = usePageMutator();
  const setEditModeClass = () => {
    switch (activeMode.mode) {
      case activeModes.move:
      case activeModes.add:
        return "editmode";
      case activeModes.edit:
        return "contentmode";
      case activeModes.grid:
      case activeModes.gridMove:
        return "gridmode";
      default:
        return null;
    }
  };

  const placeMovedElement = (event: DragEndEvent) => {
    if (
      activeMode.mode !== activeModes.move &&
      activeMode.mode !== activeModes.gridMove
    ) {
      return;
    }
    const { active, over } = event;
    if (!over) {
      //not over dropzone
      return;
    }
    if (
      active.data.current["id"] !== over.data.current["id"] ||
      (event.activatorEvent["ctrlKey"] as boolean) === true
    ) {
      moveElement(
        active.data.current["id"] as string,
        over.data.current["id"] as string,
        over.data.current["pos"] as elementPos,
        event.activatorEvent["ctrlKey"] as boolean,
      )
        .then(() => {
          pageMutate.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <DndContext
      onDragEnd={placeMovedElement}
      collisionDetection={pointerWithin}
    >
      <main>
        <article className={setEditModeClass()}>{props.children}</article>
      </main>
    </DndContext>
  );
}
