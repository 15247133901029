import { ChangeEvent } from "react";

export interface formSelectProps {
  element: string;
  name: string;
  labelText: string;
  options: { value: string; text: string }[];
  value: string;
  keyId?: string;
  handleInput: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export function FormSelect(props: formSelectProps) {
  return (
    <label htmlFor={props.name + props.keyId}>
      <p>{props.labelText}</p>
      <select
        value={props.value}
        onChange={props.handleInput}
        name={props.name}
        id={props.name + props.keyId}
      >
        {props.options.map((option, index) => (
          <option key={props.name + index} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </label>
  );
}
