import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";

export function logout() {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.logout, {})
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
