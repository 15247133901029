import { Loading } from "./loading";
import { Success } from "./success";
import { Autoresponder } from "./autoresponder";
import PopupWrapper from "../popupWrapper";

export function FeedbackDiv(props: {
  show: "loading" | "success" | "autoresponder" | "inactive";
  autoresponderData?: {
    headline: string;
    text: string;
  };
  handleClosing?: () => void;
}) {
  let feedBack = null;
  switch (props.show) {
    case "loading":
      feedBack = <Loading />;
      break;
    case "success":
      feedBack = <Success />;
      break;
    case "autoresponder":
      feedBack = (
        <Autoresponder
          autoresponderData={props.autoresponderData}
          handleClosing={props.handleClosing}
        />
      );
      break;
    default:
      feedBack = null;
  }

  if (props.show === "autoresponder") {
    return <PopupWrapper>{feedBack}</PopupWrapper>;
  } else {
    return (
      <div id={"feedback"} className={"show"}>
        {feedBack}
      </div>
    );
  }
}
