export enum socialMediaTypes {
  facebook = "facebook",
  instagram = "instagram",
  linkedin = "linkedin",
  pinterest = "pinterest",
  twitter = "x",
  vimeo = "vimeo",
  xing = "xing",
  youtube = "youtube",
  dailymotion = "dailymotion",
  tiktok = "tiktok",
}
