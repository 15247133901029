export enum gridStil {
  "Hell 1" = "gs1",
  "Hell 2" = "gs2",
  "Hell 3" = "gs3",
  "Hell S" = "gs12",
  "Dunkel 1" = "gs5",
  "Dunkel 2" = "gs6",
  "Dunkel 3" = "gs7",
  "Dunkel S" = "gs13",
  "Transparent" = "gs9",
  "Bild (mitlaufend)" = "gs4",
  "Bild (fest)" = "gs8",
  "Video (mitlaufend)" = "gs10",
  "Video (fest)" = "gs11",
}
