import { useContext } from "react";
import { useStore } from "zustand";
import { loginStateProps } from "../../interfaces/auth/loginStateProps";
import { UserLoginContext } from "../../contexts/userLoginContext";

export function useUserLoginContext<T>(
  selector: (state: loginStateProps) => T,
): T {
  const store = useContext(UserLoginContext);
  if (!store) throw new Error("Missing UserLogin.Provider in the tree");
  return useStore(store, selector);
}
