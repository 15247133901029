import { googleMapInterface } from "../../../interfaces/elements/googleMapInterface";
import { useGoogleCookie } from "../../../stateManagement/cookies/useGoogleCookie";
import { lazy, startTransition, Suspense, useCallback, useMemo } from "react";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export default function GoogleMap(props: googleMapInterface) {
  const activeMode = useActiveMode();
  const googleCookie = useGoogleCookie();
  const animations = useAnimations(props.animations);
  const generateGoogleMapUrl = () => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      props.address,
    )}`;
  };
  const generateGoogleStaticMapUrl = () => {
    const encodedAddress = encodeURIComponent(props.address);
    return `https://maps.googleapis.com/maps/api/staticmap?center=${encodedAddress}&zoom=${props.zoomLevel}&size=${props.width}x${props.height}&markers=color:red%7Cscale:2%7C${encodedAddress}&maptype=${props.mapType}&key=AIzaSyAcWJjzpPIOMhPe6eWs1K9Iu0jldi2ClT8`;
  };

  const openGoogleMaps = () => {
    if (props.function === "none") return;
    window.open(generateGoogleMapUrl(), "_blank");
  };

  const cookieElement = () => {
    return (
      <div className="box x1 bs1">
        <div>
          <p className="small poscenter">
            Google Maps - Mit der Aktivierung wird eine Verbindung zu Google
            aufgebaut und ggf. personenbezogene Daten gesendet.
          </p>
          <div
            className="button poscenter legal"
            onClick={googleCookie.setTrue}
          >
            <button>Karte aktivieren</button>
          </div>
        </div>
      </div>
    );
  };

  const onClickHandler = () => {
    if (activeMode.mode !== activeModes.off) return;
    openGoogleMaps();
  };

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    return !googleCookie.googleCookie ? (
      cookieElement()
    ) : (
      <picture
        className={classNames(animations.animationClasses, animations.animate)}
      >
        <img
          ref={refs}
          className={classNames(
            props.radius,
            "staticmap",
            isEditMode ? "jslink" : "",
            props.function === "maplink" && activeMode.mode === activeModes.off
              ? "bildlink"
              : "",
          )}
          src={generateGoogleStaticMapUrl()}
          title={props.title}
          alt=""
          onClick={onClickHandler}
        />
      </picture>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
