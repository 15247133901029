import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";
import { apiResponses } from "../../../../backend/enums/apiResponses";
import { elementPos } from "../../../enums/edit/elementPos";
import { useAdminLoginContext } from "../../auth/useAdminLoginContext";

export function moveElement(
  draggedId: string,
  droppedId: string,
  pos: elementPos,
  copy?: boolean,
) {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.moveElement, {
      draggedId: draggedId,
      droppedId: droppedId,
      elementPos: pos,
      copy: copy ? copy : false,
      lang: "de",
    })
      .then((result: basicApiResponse) => {
        if (result.data.success === false) {
          if (result.data.error == apiResponses.notLoggedIn) {
            useAdminLoginContext((s) => s.isLoggedOut)();
          }
          reject(result.data.error);
        }
        resolve(result);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
