import { ReactNode, createElement, HTMLProps } from "react";
import { buildSiteProps } from "../interfaces/buildSiteProps";
import { Main } from "../elements/app/structure/main";

import Slogan from "../elements/htmlElements/text/slogan";
import H1 from "../elements/htmlElements/text/h1";
import H2 from "../elements/htmlElements/text/h2";
import H3 from "../elements/htmlElements/text/h3";
import Zitat from "../elements/htmlElements/text/zitat";
import Abstand from "../elements/htmlElements/structure/abstand";
import Line from "../elements/htmlElements/structure/line";
import GridDiv from "../elements/htmlElements/structure/grid/gridDiv";
import GoogleMap from "../elements/htmlElements/media/googleMap";
import SvgObject from "../elements/htmlElements/media/svgObject";
import Image from "../elements/htmlElements/media/image";
import PdfObject from "../elements/htmlElements/media/pdfObject";
import Video from "../elements/htmlElements/media/video";
import InternalLink from "../elements/htmlElements/link/internalLink";
import ExternalLink from "../elements/htmlElements/link/externalLink";
import Box from "../elements/htmlElements/structure/box";
import ContentDiv from "../elements/app/structure/contentDiv";
import UList from "../elements/htmlElements/text/uList";
import OList from "../elements/htmlElements/text/oList";
import SpecialFunction from "../elements/htmlElements/link/specialFunction";
import Text from "../elements/htmlElements/text/text";
import OnPageForm from "../elements/htmlElements/onPageForms/onPageForm";
import SocialLink from "../elements/htmlElements/link/socialLink";
import CustomElementSkeleton from "../elements/htmlElements/customElement/customElementSkeleton";
import UserLoginButton from "../elements/htmlElements/user/userLoginButton";
import UserRegisterButton from "../elements/htmlElements/user/userRegisterButton";

//todo later move Converter into web-worker

class Converter {
  private create: typeof createElement = createElement;
  readonly elements = {
    Text: Text,
    GridDiv: GridDiv,
    Slogan: Slogan,
    H1: H1,
    H2: H2,
    H3: H3,
    Zitat: Zitat,
    Abstand: Abstand,
    Line: Line,
    Image: Image,
    GoogleMap: GoogleMap,
    SvgObject: SvgObject,
    PdfObject: PdfObject,
    Video: Video,
    InternalLink: InternalLink,
    ExternalLink: ExternalLink,
    Box: Box,
    ContentDiv: ContentDiv,
    UList: UList,
    OList: OList,
    SpecialFunction: SpecialFunction,
    OnPageForm: OnPageForm,
    SocialLink: SocialLink,
    customElement: CustomElementSkeleton,
    UserLoginButton: UserLoginButton,
    UserRegisterButton: UserRegisterButton,
  };

  // header and footer are not used at the moment = not fully customizable
  async buildSite(daten: buildSiteProps): Promise<{
    /*header: ReactNode;*/ main: ReactNode /* footer: ReactNode*/;
  }> {
    return Promise.all([
      //this.header(daten.header),
      this.main(daten.main),
      //this.footer(daten.footer),
    ])
      .then((res) => {
        return {
          //header: res[0],
          main: res[0],
          //footer: res[2],
        };
      })
      .catch((err) => {
        console.error(err);
        return {
          //header: '',
          main: "",
          //footer: '',
        };
      });
  }

  main(daten): Promise<ReactNode> {
    return new Promise((resolve) => {
      resolve(this.create(Main, {}, this.arrayWalker(daten)));
    });
  }
  /**
   * walk through the array and create the elements
   * @param daten
   */
  arrayWalker(daten) {
    const elems = [];
    for (const i in daten) {
      const children = [];

      if (typeof daten[i]["element"] === "undefined") {
        continue;
      }

      if (typeof daten[i]["children"] !== "undefined") {
        //elem has children

        children.push(this.arrayWalker(daten[i]["children"]));
      }

      elems.push(this.jsonToHTML(daten[i]["element"], daten[i], children));
    }

    return elems;
  }

  /**
   * create the element from the json data
   * @param element
   * @param daten
   * @param children
   */
  public jsonToHTML(
    element: string,
    daten: HTMLProps<never>,
    children?: ReactNode[],
  ) {
    if (typeof this.elements[element] !== "undefined") {
      return this.create(this.elements[element], daten, children);
    } else {
      console.error("Element not found: " + element);
      return "";
    }
  }
}

export { Converter };
