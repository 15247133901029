import { useState } from "react";
import { produce } from "immer";
import {
  FeedbackPopup,
  feedbackState,
} from "../../elements/popups/feedback/feedbackPopup";

export function useFeedback() {
  const [feedbackState, setFeedbackState] = useState<feedbackState>({
    show: "inactive",
    autoresponderData: null,
    handleClosing: null,
  });

  const showLoading = () => {
    setFeedbackState(
      produce(feedbackState, (draftState) => {
        draftState.show = "loading";
      }),
    );
  };

  const showSuccess = () => {
    setFeedbackState(
      produce(feedbackState, (draftState) => {
        draftState.show = "success";
      }),
    );
  };

  const hideFeedback = () => {
    setFeedbackState(
      produce(feedbackState, (draftState) => {
        draftState.show = "inactive";
      }),
    );
  };

  const showAutoresponder = (
    headline: string,
    text: string,
    handleClosing: () => void,
  ) => {
    setFeedbackState(
      produce(feedbackState, (draftState) => {
        draftState.show = "autoresponder";
        draftState.autoresponderData = { headline: headline, text: text };
        draftState.handleClosing = handleClosing;
      }),
    );
  };

  const currentFeedback = () => {
    if (feedbackState.show === "inactive") {
      return null;
    }
    return FeedbackPopup(feedbackState);
  };

  return {
    showLoading,
    showSuccess,
    hideFeedback,
    showAutoresponder,
    currentFeedback,
    feedbackState,
  };
}
