import { RefObject } from "react";
import { parallaxIds } from "../functions/elements/parallaxIds";

/**
 * Represents a parallax element.
 */
export class ParallaxElement {
  element: RefObject<HTMLDivElement>;
  id: number;
  offset: number;
  height: number;
  scroll: number;
  vh: number;
  pictureRef: RefObject<HTMLElement>;
  imgRef: RefObject<HTMLElement>;

  constructor(
    ref: RefObject<HTMLDivElement>,
    pictureRef: RefObject<HTMLElement>,
    imgRef: RefObject<HTMLElement>,
    scroll: number,
    vh: number,
  ) {
    this.element = ref;
    this.id = parallaxIds();
    this.imgRef = imgRef;
    this.pictureRef = pictureRef;
    this.setCachedValues(scroll, vh);
    this.setWillChange();
    this.setOnLoad();
  }

  /**
   * Refreshes the offset and height of the current element.
   * If the element is not available, the method returns early.
   *
   * @return {void}
   */
  refreshOffsetAndHeight(): void {
    if (this.element.current === null) return;
    const elemClientRect = this.element.current.getBoundingClientRect();
    this.offset = this.element.current.offsetTop;
    this.height = elemClientRect.height;
  }

  /**
   * Sets the opacity of the picture element.
   *
   */
  setOpacity() {
    this.pictureRef.current.style.opacity = "1";
  }

  setOnLoad() {
    //this.setOpacity();
    this.calculationFunction();
    this.imgRef.current.onload = () => {
      this.calculationFunction();
    };
  }
  setWillChange() {
    this.imgRef.current.style.willChange = "transform";
    this.pictureRef.current.style.willChange = "transform";
  }

  setEmptyStyle(elem: HTMLElement) {
    elem.style.transform = "";
    elem.style.top = "";
  }

  setTransform(elem: HTMLElement, value: number, type: "px" | "vh" | "") {
    if (elem === null) return;
    elem.style.transform = "translateY(" + value + type + ")";
    elem.style.top = "0";
  }

  setCachedValues(scroll: number, vh: number) {
    this.scroll = scroll;
    this.vh = vh;
  }

  calculationFunction() {
    this.refreshOffsetAndHeight();
    if (this.pictureRef.current === null || this.imgRef.current === null) {
      return this;
    }
    if (
      (this.offset < this.scroll && this.height + this.offset < this.scroll) ||
      this.offset > this.scroll + this.vh
    ) {
      this.setTransform(this.pictureRef.current, 100, "vh");
      this.setEmptyStyle(this.imgRef.current);
      return this;
    }
    if (
      this.offset + this.height > this.scroll + this.vh &&
      this.offset < this.scroll + this.vh &&
      this.scroll <= this.offset
    ) {
      const gridTop = this.offset - this.scroll;
      this.setTransform(this.pictureRef.current, gridTop, "px");
      this.setTransform(this.imgRef.current, -gridTop, "px");
      return this;
    }

    if (this.offset < this.scroll && this.height + this.offset > this.scroll) {
      this.setTransform(this.pictureRef.current, 0, "");
      this.setTransform(this.imgRef.current, 0, "");
      return this;
    }

    if (
      this.vh > this.height &&
      this.offset >= this.scroll &&
      this.offset + this.height < this.vh + this.scroll
    ) {
      const gridTop = this.offset - this.scroll;
      this.setTransform(this.pictureRef.current, gridTop, "px");
      this.setTransform(this.imgRef.current, -gridTop, "px");
      return this;
    }
    this.setEmptyStyle(this.imgRef.current);
    this.setEmptyStyle(this.pictureRef.current);
    return this;
  }
}
