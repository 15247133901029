import {
  lazy,
  PropsWithChildren,
  startTransition,
  Suspense,
  useState,
} from "react";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";
import { useUserLoginContext } from "../../../functions/auth/useUserLoginContext";

const LoginModal = lazy(
  () =>
    import(/* webpackChunkName: "loginModal" */ "../../popups/auth/loginModal"),
);

const LogoutModal = lazy(
  () =>
    import(
      /* webpackChunkName: "logoutModal" */ "../../popups/auth/logoutModal"
    ),
);

export default function AdminLoginButton(props: PropsWithChildren) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const login = useAdminLoginContext((s) => s.isLogin);
  const userLogin = useUserLoginContext((s) => s.isLogin);

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const openModal = () => {
    if (login || userLogin) {
      //logout modal
      startTransition(() => {
        setIsLogoutModalOpen(true);
      });
      return;
    }
    startTransition(() => {
      setIsLoginModalOpen(true);
    });
  };

  return (
    <>
      <div className={"jslink"} onClick={openModal}>
        {props.children}
      </div>
      {isLoginModalOpen ? (
        <Suspense fallback={null}>
          <LoginModal close={closeLoginModal} />
        </Suspense>
      ) : null}
      {isLogoutModalOpen ? (
        <Suspense fallback={null}>
          <LogoutModal close={closeLogoutModal} />
        </Suspense>
      ) : null}
    </>
  );
}
