import { produce } from "immer";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface elementAddCache {
  cache: {
    [key: string]: { [key: string]: never };
  };
  setCacheItem: (elementData: { [key: string]: never }) => void;
}

export const useElementAddCache = create<elementAddCache>()(
  persist(
    (set) => ({
      cache: {},
      setCacheItem: (elementData) => {
        if (
          elementData["element"] !== "Abstand" &&
          elementData["element"] !== "Line"
        )
          set(
            produce((state: elementAddCache) => {
              state.cache[elementData["element"] as string] = elementData;
            }),
          );
      },
    }),
    {
      storage: createJSONStorage(() => sessionStorage),
      name: "elementAddCache",
    },
  ),
);
