import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";

export function getCustomElements() {
  return new Promise((resolve) => {
    postToApi(apiUrls.getCustomElements, {})
      .then((data: basicApiResponse) => {
        if (data.data.success) {
          resolve(data.data.result);
        } else {
          resolve(false);
        }
      })
      .catch((e) => {
        console.error(e);
        resolve(false);
      });
  });
}
