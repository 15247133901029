import { postToApi } from "../../apiCommunication/apiComm";
import { apiUrls } from "../../../../enums/apiUrls";
import { basicApiResponse } from "../../../../interfaces/basicApiRespone";
import { Svg } from "../../../../enums/svg";

export function saveStickyContactData(data: {
  title: string;
  text: string;
  type: string;
  customForm: number;
  svg: Svg.at | Svg.mail | Svg.phone;
}) {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.saveStickyContactData, { data: data })
      .then((data: basicApiResponse) => {
        if (data.data.success) {
          resolve(data.data.result);
        } else {
          reject(data.data.error);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
