import { useContext } from "react";
import { useStore } from "zustand";
import { AdminLoginContext } from "../../contexts/adminLoginContext";
import { loginStateProps } from "../../interfaces/auth/loginStateProps";

export function useAdminLoginContext<T>(
  selector: (state: loginStateProps) => T,
): T {
  const store = useContext(AdminLoginContext);
  if (!store) throw new Error("Missing AdminLogin.Provider in the tree");
  return useStore(store, selector);
}
