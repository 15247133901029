import { ChangeEvent, Fragment, useRef, useState } from "react";
import { useImageUrls } from "../../../../functions/api/graphics/useImageUrls";
import classNames from "classnames";

export interface formRadioProps {
  element: string;
  labelText: string;
  name: string;
  values: string[];
  images?: string[];
  handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
  hideRequired?: boolean;
  required?: boolean;
  keyId?: string;
  showError?: boolean;
}

export function FormRadio(props: formRadioProps) {
  const urls = useImageUrls();
  const [activeImage, setActiveImage] = useState(0);
  const [mouseOverImage, setMouseOverImage] = useState(0);
  const elemRef = useRef<HTMLDivElement>(null);
  const getImage = () => {
    if (props.images) {
      if (mouseOverImage !== 0 && mouseOverImage !== activeImage) {
        return props.images[mouseOverImage];
      }
      return props.images[activeImage];
    }
    return null;
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setActiveImage(parseInt(e.target.dataset.index));
    props.handleInput(e);
  };

  const setErrorClass = () => {
    if (!elemRef.current) return null;
    let isError = true;
    if (props.showError && props.required) {
      const radios = elemRef.current.getElementsByTagName("input");
      for (let i = 0; i < radios.length; i++) {
        if (radios[i].checked) {
          isError = false;
          break;
        }
      }
    } else {
      isError = false;
    }
    if (isError) {
      return "error";
    } else {
      return null;
    }
  };

  return (
    <div className={classNames("radio", setErrorClass())} ref={elemRef}>
      <p>
        {props.labelText}{" "}
        {props.required === true &&
        (typeof props.hideRequired === "undefined" ||
          props.hideRequired === false)
          ? "*"
          : ""}
        {props.images && (
          <img
            src={urls.formImage + getImage() + ".jpg"}
            alt={props.labelText}
            title=""
          />
        )}
      </p>
      <span>
        {props.values.map((value, index) => (
          <Fragment key={props.name + value + index}>
            <input
              name={props.name}
              type="radio"
              onChange={handleInput}
              id={props.name + index}
              data-value={value}
              data-index={index}
            />
            <label
              htmlFor={props.name + index}
              onMouseEnter={() => setMouseOverImage(index)}
              onMouseLeave={() => setMouseOverImage(0)}
            >
              {value}
            </label>
          </Fragment>
        ))}
      </span>
    </div>
  );
}
