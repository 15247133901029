import { Children, PropsWithChildren } from "react";
import { useElementAdding } from "../../../functions/elements/useElementAdding";
import { elementPos } from "../../../enums/edit/elementPos";
import { useElementMove } from "../../../functions/elements/useElementMove";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";

export default function ContentDivEdit(
  props: PropsWithChildren<{ id: string }>,
) {
  const activeMode = useActiveMode();
  const elementAdding = useElementAdding();
  const elementMove = useElementMove(props.id);
  let childrenCount = 1;
  if (activeMode.mode !== activeModes.off) {
    childrenCount = Children.count(props.children);
  }
  return (
    <div>
      {childrenCount > 0
        ? props.children
        : elementAdding.elementAddElement(elementPos.children, props.id)}
      {childrenCount === 0 && elementMove.setDropChild()}
    </div>
  );
}
