import { useTranslation } from "react-i18next";
import {
  useState,
  FormEvent,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";
import PopupWrapper from "../popupWrapper";
import { Svg } from "../../../enums/svg";
import { SvgImage } from "../../app/basicElements/svgImage";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";
import { useUserLoginContext } from "../../../functions/auth/useUserLoginContext";

export default function LoginModal({ close }: { close: () => void }) {
  const { t } = useTranslation("popups", { keyPrefix: "login" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const login = useAdminLoginContext((s) => s.login);
  const userLogin = useUserLoginContext((s) => s.login);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);
    login(email, password)
      .then((success) =>
        success
          ? close()
          : userLogin(email, password)
              .then((success) => success && close())
              .catch(() => setError(true)),
      )
      .catch(() => setError(true));
  };

  const handleChange =
    (setter: Dispatch<SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setError(false);
      setter(e.target.value);
    };

  return (
    <PopupWrapper>
      <div id="login" className="small">
        <span>{t("systemlogin")}</span>
        <form onSubmit={handleSubmit} id="login_form">
          <div className="container">
            <label htmlFor="inputEmail">
              <span className="icon">{t("e-mail")}</span>
              <SvgImage image={Svg.at} />
              <input
                className="icon"
                type="email"
                id="inputEmail"
                value={email}
                onChange={handleChange(setEmail)}
              />
            </label>
            <label htmlFor="inputPassword">
              <span className="icon">{t("passwort")}</span>
              <SvgImage image={Svg.key} />
              <input
                className="icon"
                type="password"
                id="inputPassword"
                value={password}
                onChange={handleChange(setPassword)}
              />
            </label>
            {error && <p className="error">{t("error")}</p>}
            <hr />
          </div>
        </form>
        <button id="rb" type="submit" form="login_form">
          <SvgImage image={Svg.check} />
        </button>
        <button id="rt" onClick={close}>
          <SvgImage image={Svg.delete} />
        </button>
      </div>
    </PopupWrapper>
  );
}
