import { SvgImage } from "../../../app/basicElements/svgImage";
import { Svg } from "../../../../enums/svg";
import { animationTypes } from "../../../../enums/animations/animationTypes";
import { animationInterface } from "../../../../interfaces/animations/animationInterface";
import { ChangeEvent } from "react";

const timesStart = [1000, 2000, 3000, 4000, 5000];
const timesLoop = [
  1000, 2000, 3000, 4000, 5000, 7500, 10000, 12500, 15000, 20000,
];

const animations = {
  start: [
    {
      animationName: "Einfliegen von links",
      behaviours: [
        {
          className: "left1",
          displayName: "-100% ▸ 0%",
        },
        {
          className: "left2",
          displayName: "-100% ▸ +10% ▸ 0%",
        },
        {
          className: "left3",
          displayName: "-20% ▸ 0%",
        },
      ],
      num: 1,
    },
    {
      animationName: "Einfliegen von rechts",
      behaviours: [
        {
          className: "right1",
          displayName: "+100% ▸ 0%",
        },
        {
          className: "right2",
          displayName: "+100% ▸ -10% ▸ 0%",
        },
        {
          className: "right3",
          displayName: "+20% ▸ 0%",
        },
      ],
      num: 2,
    },
    {
      animationName: "Einfliegen von oben",
      behaviours: [
        {
          className: "top1",
          displayName: "-10% ▸ 0%",
        },
        {
          className: "top2",
          displayName: "-20% ▸ +5% ▸ 0%",
        },
      ],
      num: 3,
    },
    {
      animationName: "Einfliegen von unten",
      behaviours: [
        {
          className: "bottom1",
          displayName: "+10% ▸ 0%",
        },
        {
          className: "bottom2",
          displayName: "+20% ▸ -5% ▸ 0%",
        },
      ],
      num: 4,
    },
    {
      animationName: "Unschärfe",
      behaviours: [
        {
          className: "blur1",
          displayName: "10px ▸ 0px",
        },
      ],
      num: 5,
    },
    {
      animationName: "Transparenz",
      behaviours: [
        {
          className: "opacity1",
          displayName: "100% ▸ 0%",
        },
      ],
      num: 6,
    },
    {
      animationName: "Größe",
      behaviours: [
        {
          className: "scale1",
          displayName: "125% ▸ 100%",
        },
        {
          className: "scale2",
          displayName: "200% ▸ 100%",
        },
        {
          className: "scale3",
          displayName: "150% ▸ 90% ▸ 100%",
        },
      ],
      num: 7,
    },
  ],
  loop: [
    {
      animationName: "Schütteln",
      behaviours: [
        {
          className: "shake1",
          displayName: "Schütteln mit Rotieren",
        },
        {
          className: "shake2",
          displayName: "Schütteln horizontal",
        },
        {
          className: "shake3",
          displayName: "Schütteln vertikal",
        },
      ],
      num: 8,
    },
    {
      animationName: "Größe",
      behaviours: [
        {
          className: "zoom1",
          displayName: "100% ▸ 105% ▸ 100%",
        },
        {
          className: "zoom1",
          displayName: "100% ▸ 105% ▸ 95% ▸ 100%",
        },
      ],
      num: 9,
    },
    {
      animationName: "Spiegeln",
      behaviours: [
        {
          className: "mirroring1",
          displayName: "Spiegeln vertikal",
        },
        {
          className: "mirroring2",
          displayName: "Spiegeln horizontal",
        },
      ],
      num: 10,
    },
  ],
};

export function AnimationsSelect(props: {
  data?: animationInterface[];
  handleAnimationChange: (data: animationInterface[]) => void;
}) {
  const handleAnimationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    //build array of animations
    const index = parseInt(e.target.dataset.index);
    const animType = e.target.dataset.animtype;
    const value = e.target.value;
    const data = JSON.parse(JSON.stringify(props.data)) as animationInterface[]; //deep copy the props
    data[index][animType] = value;
    props.handleAnimationChange(data);
  };

  const createStartAnimations = () => {
    return animations.start.map((val) => {
      return (
        <option value={val.num} key={val.num + val.animationName}>
          {val.animationName}
        </option>
      );
    });
  };

  const createLoopAnimations = () => {
    return animations.loop.map((val) => {
      return (
        <option value={val.num} key={val.num + val.animationName}>
          {val.animationName}
        </option>
      );
    });
  };

  const createBehaviorOptions = (animNum: number) => {
    let options = animations.start.map((val) => {
      if (animNum === val.num) {
        return val.behaviours.map((behaviour, index) => {
          return (
            <option
              value={behaviour.className}
              key={behaviour.className + "start" + index}
            >
              {behaviour.displayName}
            </option>
          );
        });
      } else {
        return false;
      }
    });
    options = options.filter((opt) => opt !== false);
    if (options.length === 0) {
      options = animations.loop.map((val) => {
        if (animNum === val.num) {
          return val.behaviours.map((behaviour, index) => {
            return (
              <option
                value={behaviour.className}
                key={behaviour.className + "loop" + index}
              >
                {behaviour.displayName}
              </option>
            );
          });
        } else {
          return false;
        }
      });
    }

    return options;
  };

  const animationConfiguration = () => {
    return props.data.map((animation, index) => {
      return (
        <select
          key={"animation_" + index}
          data-index={index}
          className="icon"
          value={animation.animation}
          onChange={handleAnimationChange}
          data-animtype={"animation"}
        >
          <option value="none">Keine</option>
          <optgroup label="Startanimation">{createStartAnimations()}</optgroup>
          <optgroup label="Lauf. Animation">{createLoopAnimations()}</optgroup>
        </select>
      );
    });
  };
  const behaviorConfiguration = () => {
    return props.data.map((animation, index) => {
      return (
        <select
          key={"behavior_" + index}
          data-index={index}
          className="icon"
          data-animtype={"behavior"}
          value={animation.behavior}
          onChange={handleAnimationChange}
        >
          <option value="none">Keins</option>
          {createBehaviorOptions(parseInt(animation.animation))}
        </select>
      );
    });
  };

  const getTimes = (animation: number) => {
    if (isNaN(animation)) {
      return <option value={0}>0</option>;
    }
    let type: (animationTypes.loop | animationTypes.start | boolean)[] =
      animations.loop.map((anim) => {
        if (anim.num === animation) {
          return animationTypes.loop;
        } else {
          return false;
        }
      });
    type = type.filter((value) => value !== false);
    if (type.length === 0) {
      type = animations.start.map((anim) => {
        if (anim.num === animation) {
          return animationTypes.start;
        } else {
          return false;
        }
      });
    }
    type = type.filter((value) => value !== false);
    if (type.length === 0) {
      return <option value={0}>0</option>;
    }
    if (type[0] === animationTypes.start) {
      return timesStart.map((time) => {
        return (
          <option
            value={time}
            data-grouptype="start"
            key={"animationTimeOption_" + time}
          >
            {time / 1000} s
          </option>
        );
      });
    } else {
      return timesLoop.map((time) => {
        return (
          <option
            value={time}
            data-grouptype="loop"
            key={"animationTimeOption_" + time}
          >
            {time / 1000} s
          </option>
        );
      });
    }
  };

  const timeConfiguration = () => {
    return props.data.map((animation, index) => {
      return (
        <select
          value={animation.time}
          data-index={index}
          data-animtype={"time"}
          key={"animationTime_" + index}
          onChange={handleAnimationChange}
        >
          {getTimes(parseInt(animation.animation))}
        </select>
      );
    });
  };

  return (
    <>
      <label className="w40 floatleft nomobile">
        <span className="icon">Animation</span>
        <SvgImage image={Svg.animate} />
        {animationConfiguration()}
      </label>
      <label className="w40 floatleft nomobile">
        <span className="icon">Verhalten / Position</span>
        <SvgImage image={Svg.forward} />
        {behaviorConfiguration()}
      </label>
      <label className="w20 nomobile">
        <span className="icon">Dauer</span>
        <SvgImage image={Svg.clock} />
        {timeConfiguration()}
      </label>
    </>
  );
}
