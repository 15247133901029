import { createStore } from "zustand";
import { produce } from "immer";
import { loginStateProps } from "../../interfaces/auth/loginStateProps";
import { login } from "../../functions/api/auth/login";
import { logout } from "../../functions/api/auth/logout";
import { checkLogin } from "../../functions/api/auth/checkLogin";
import { apiUrls } from "../../enums/apiUrls";
import { userRoles } from "../../enums/userRoles";

export type userLoginState = ReturnType<typeof userLoginState>;

export const userLoginState = (init?: Partial<loginStateProps>) => {
  const initProps = {
    isLogin: false,
    role: userRoles.guest,
  };

  return createStore<loginStateProps>()((set) => ({
    ...initProps,
    ...init,
    login: async (email: string, password: string) => {
      return new Promise((resolve) => {
        login(email, password, apiUrls.userLogin)
          .then(() => {
            set(
              produce((state: loginStateProps) => {
                //all clear setstate
                state.isLogin = true;
                state.role = userRoles.user;
              }),
            );
            return resolve(true);
          })
          .catch(() => {
            //login data wrong or server error
            return resolve(false);
          });
      });
    },
    logout: () => {
      return new Promise((resolve) => {
        logout()
          .then(() => {
            set(
              produce((state: loginStateProps) => {
                state.isLogin = false;
                state.role = userRoles.guest;
              }),
            );
            return resolve(true);
          })
          .catch(() => {
            //server error
            return resolve(false);
          });
      });
    },
    checkLogin: () => {
      return new Promise((resolve) => {
        //if state is false, no need to check
        checkLogin(true)
          .then((response: { valid: boolean; role: userRoles }) => {
            if (response.valid === false || response.role !== userRoles.user) {
              //not logged in unset state
              set(
                produce((state: loginStateProps) => {
                  state.isLogin = false;
                  state.role = userRoles.guest;
                }),
              );
              return resolve(false);
            }
            return resolve(true);
          })
          .catch(() => {
            //server error
            return resolve(false);
          });
      });
    },
    isLoggedOut: () => {
      set(
        produce((state: loginStateProps) => {
          state.isLogin = false;
          state.role = userRoles.guest;
        }),
      );
    },
  }));
};
