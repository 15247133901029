import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";
import { apiResponses } from "../../../../backend/enums/apiResponses";
import { useAdminLoginContext } from "../../auth/useAdminLoginContext";

export function deleteElement(elementId: string) {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.deleteElement, {
      elementId: elementId,
      lang: "de",
    })
      .then((result: basicApiResponse) => {
        if (result.data.success === false) {
          if (result.data.error == apiResponses.notLoggedIn) {
            useAdminLoginContext((s) => s.isLoggedOut)();
          }
          reject(result.data.error);
        }
        resolve(result);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
