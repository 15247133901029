import { useActiveMode } from "../../stateManagement/edit/useActiveMode";
import { createElement, lazy, Suspense } from "react";
import { elementPos } from "../../enums/edit/elementPos";
import { activeModes } from "../../enums/edit/activeModes";

const AddElement = lazy(
  () =>
    import(
      /* webpackChunkName: "addElement" */ "../../elements/app/basicElements/addElement"
    ),
);
export function useElementAdding() {
  const isEditMode = useActiveMode().mode;
  const elementAddElement = (position: elementPos, elementKey: string) => {
    if (isEditMode !== activeModes.add) return null;
    return createElement(
      Suspense,
      { fallback: null },
      createElement(AddElement, { pos: position, elementId: elementKey }),
    );
  };

  return {
    isEditMode: isEditMode === activeModes.add,
    elementAddElement,
  };
}
