import { ReactNode, useState, lazy, createElement, Suspense } from "react";
import { useActiveMode } from "../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../enums/edit/activeModes";
import { editElement } from "../api/edit/editElement";
import { deleteElement } from "../api/edit/deleteElement";
import { editElementWithFile } from "../api/edit/editElementWithFile";
import { usePageMutator } from "../api/page/usePageMutator";
import PopupWrapper from "../../elements/popups/popupWrapper";
import { useElementAddCache } from "../../stateManagement/edit/useElementAddCache";

const Delete = lazy(
  () =>
    import(
      /* webpackChunkName: "delete" */ "../../elements/popups/edit/delete"
    ),
);
export function useElementEdit(elementId: string) {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const activeMode = useActiveMode().mode;
  const pageMutate = usePageMutator();
  const elementAddingCache = useElementAddCache();
  const handleElementEditing = (data: object) => {
    // @ts-expect-error ignore for now
    elementAddingCache.setCacheItem(data);
    editElement(data, elementId)
      .then(() => {
        void pageMutate.reload();
        setIsEditing(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleElementEditingWithFile = (
    data: object,
    file: Blob,
    filesDimensions?: object,
  ) => {
    setIsEditing(false);
    setIsUpdating(true);
    let cacheData = { ...data };
    if (typeof cacheData["file"] !== "undefined") {
      cacheData = { ...cacheData, file: "", originalFile: "" };
    }
    elementAddingCache.setCacheItem(cacheData);
    editElementWithFile(
      data,
      file,
      filesDimensions ? filesDimensions : null,
      elementId,
    )
      .then(() => {
        pageMutate.reload();
        setIsUpdating(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const activateEdit = () => {
    if (activeMode === activeModes.edit && !isEditing) {
      setIsEditing(true);
    }
  };

  const handleClosing = () => {
    setIsEditing(false);
  };

  const handleDelete = () => {
    deleteElement(elementId)
      .then(() => {
        pageMutate.reload();
        setIsEditing(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSetDelete = () => {
    setIsDeleting(true);
  };

  const handleCloseDelete = () => {
    setIsDeleting(false);
  };

  const editPortal = (editElement: ReactNode): ReactNode => {
    if (!isEditing) return null;
    if (!isDeleting) {
      return createElement(Suspense, {}, editElement);
    } else {
      return createElement(
        PopupWrapper,
        {},
        createElement(Delete, {
          handleClosing: handleCloseDelete,
          handleDelete: handleDelete,
        }),
      );
    }
  };

  return {
    activateEdit,
    handleElementEditing,
    handleElementEditingWithFile,
    handleClosing,
    activeMode,
    isEditMode: activeMode === activeModes.edit,
    editPortal,
    handleDelete,
    handleSetDelete,
    isUpdating,
  };
}
