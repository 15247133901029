import { pictureInterface } from "../../../interfaces/elements/pictureInterface";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { useImageUrls } from "../../../functions/api/graphics/useImageUrls";
import {
  lazy,
  startTransition,
  Suspense,
  useState,
  MouseEvent,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { ImageZoom } from "../../popups/imageZoom";
import PopupWrapper from "../../popups/popupWrapper";
import { Link } from "react-router";
import { imageUploadFormats } from "../../../enums/edit/imageUploadFormats";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useImageZoom } from "../../../functions/elements/useImageZoom";
import { UrlContext } from "../../../contexts/urlContext";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";
import { JSX } from "react/jsx-runtime";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export enum linkPosY {
  Unten = "",
  Mitte = "poscenter",
  Oben = "posstart",
}

export enum linkPosX {
  Mitte = "",
  Links = "posleft",
  Rechts = "posright",
}

export enum linkStyle {
  Standard = "",
  Alternative = "alternative",
  "Link Standard" = "link",
  "Link Alternative" = "linkalternative",
  "Ohne Text" = "notext",
}

/**
 * Function to set the class based on the image format
 * @param {imageUploadFormats} format - The format of the image
 * @returns {string} - The class name
 */
export const setFormatClass = (format: imageUploadFormats): string => {
  switch (format) {
    case imageUploadFormats.original:
      return "";
    case imageUploadFormats.breitformat16zu9:
      return "ratio-16-9";
    case imageUploadFormats.hochformat3zu4:
      return "ratio-3-4";
    case imageUploadFormats.panorama3zu1:
      return "ratio-3-1";
    case imageUploadFormats.quadrat:
      return "ratio-1-1";
    case imageUploadFormats.panorama5zu1:
      return "ratio-5-1";
    case imageUploadFormats.querformat4zu3:
      return "ratio-4-3";
  }
};

/**
 * Image component
 * @param {pictureInterface} props - The properties of the image
 * @returns {JSX.Element} - The rendered Image component
 */
export default function Image(props: pictureInterface): JSX.Element {
  const activeMode = useActiveMode();
  const urls = useImageUrls();
  const [displayZoomPopup, setDisplayZoomPopup] = useState(false);
  const fileName = props.fileName !== undefined ? props.fileName : props.file;
  const animations = useAnimations(props.animations);
  const login = useAdminLoginContext((s) => s.isLogin);
  const url = useContext(UrlContext);
  const imageZoom = useImageZoom(
    props.function.name === "zoom",
    fileName + ".webp?" + props.cacheString,
    props.title,
    props.alt,
  );
  const constructWidth = useMemo(() => {
    if (props.width.unit === "px") {
      return {
        width: props.width.value + "px",
      };
    } else {
      return {
        width: props.width.value + "%",
      };
    }
  }, [props.width]);

  const isInternalLink = useMemo(() => {
    return props.function.name.startsWith("link");
  }, [props.function.name]);

  const isExternalLink = useMemo(() => {
    return props.function.name.startsWith("extLink");
  }, [props.function.name]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      if (activeMode.mode !== activeModes.off) {
        e.preventDefault();
        return;
      }

      if (props.function.name === "zoom") {
        setDisplayZoomPopup(true);
        return;
      }
    },
    [activeMode.mode, props.function.name],
  );

  const handleZoomClosing = () => {
    setDisplayZoomPopup(false);
  };

  const getImageHeight = useMemo(() => {
    if (props.imageSize === undefined) {
      return "";
    }
    if (props.imageSize.medium !== undefined) {
      return props.imageSize.medium.height;
    }
    return "";
  }, [props.imageSize]);

  const getImageWidth = useMemo(() => {
    if (props.imageSize === undefined) {
      return "";
    }
    if (props.imageSize.medium !== undefined) {
      return props.imageSize.medium.width;
    }
    return "";
  }, [props.imageSize]);

  const constructFormatClass = useCallback(() => {
    return setFormatClass(props.format);
  }, [props.format]);
  const setRefs = (e: HTMLElement) => {
    startTransition(() => {
      animations.ref(e);
    });
  };

  const showElement = useCallback(() => {
    if (isInternalLink) {
      if (props.active) {
        return true;
      } else {
        return !!login;
      }
    } else {
      if (login) {
        return true;
      } else {
        return props.isLive;
      }
    }
  }, [isInternalLink, login, props.active, props.isLive]);

  const createWidthParam = (size: "small" | "medium" | "big" | null) => {
    const param = "&w=";
    switch (size) {
      case "small":
        return param + props.imageSize.small.width;
      case "medium":
        return param + props.imageSize.medium.width;
      case "big":
        return param + props.imageSize.big.width;
      default:
        return "";
    }
  };

  const pictureElement = (isEditMode: boolean, isUpdating: boolean) => {
    return (
      <picture
        key={props.cacheString}
        className={classNames(
          isEditMode ? "jslink" : "",
          animations.animationClasses,
          animations.animate,
          props.pos,
          props.radius === "radfulltop" || props.radius === "radfullbottom"
            ? props.radius
            : "",
        )}
      >
        <source
          srcSet={
            urls.images +
            fileName +
            ".webp?" +
            props.cacheString +
            createWidthParam("big")
          }
          width={props.imageSize.big.width}
          media="( min-width: 1600px )"
          type="image/webp"
        />
        <source
          srcSet={
            urls.images +
            fileName +
            ".jpg?" +
            props.cacheString +
            createWidthParam("big")
          }
          width={props.imageSize.big.width}
          media="( min-width: 1600px )"
          type="image/jpg"
        />
        <source
          srcSet={
            urls.images +
            fileName +
            ".webp?" +
            props.cacheString +
            createWidthParam("medium")
          }
          width={props.imageSize.medium.width}
          media="( min-width: 1280px)"
          type="image/webp"
        />
        <source
          srcSet={
            urls.images +
            fileName +
            ".jpg?" +
            props.cacheString +
            createWidthParam("medium")
          }
          width={props.imageSize.medium.width}
          media="( min-width: 1280px)"
          type="image/jpg"
        />
        <source
          srcSet={
            urls.images +
            fileName +
            ".webp?" +
            props.cacheString +
            createWidthParam("small")
          }
          width={props.imageSize.small.width}
          type="image/webp"
        />
        <source
          srcSet={
            urls.images +
            fileName +
            ".jpg?" +
            props.cacheString +
            createWidthParam("small")
          }
          width={props.imageSize.small.width}
          type="image/jpg"
        />
        <img
          className={classNames(
            constructFormatClass(),
            props.function.name === "zoom" ? "zoom" : "",
            props.function.name === "extLink" ? "bildlink" : "",
            isUpdating ? "temp" : "",
            props.radius,
          )}
          ref={setRefs}
          src={
            urls.images +
            fileName +
            ".jpg?" +
            props.cacheString +
            "&w=" +
            getImageWidth
          }
          alt={props.alt}
          title={props.title}
          loading={"lazy"}
          style={constructWidth}
          onClick={handleClick}
          width={getImageWidth}
          height={getImageHeight}
        />
        {isInternalLink ? (
          props.linkOptions === undefined ? (
            <span className="link">{props.short_title}</span>
          ) : props.linkOptions.style === linkStyle["Ohne Text"] ? null : (
            <span
              className={classNames(
                props.linkOptions.x,
                props.linkOptions.y,
                props.linkOptions.style,
              )}
            >
              {props.short_title}
            </span>
          )
        ) : null}
        {isExternalLink ? (
          props.linkOptions === undefined ? (
            <span className="link">{props.short_title}</span>
          ) : props.linkOptions.style ===
            linkStyle["Ohne Text"] ? null : typeof props.linkOptions.title !== //check if linkOptions is defined and style is not 'Ohne Text' then display the title if it is not empty
              "undefined" && props.linkOptions.title.trim() !== "" ? (
            <span
              className={classNames(
                props.linkOptions.x,
                props.linkOptions.y,
                props.linkOptions.style,
              )}
            >
              {props.linkOptions.title}
            </span>
          ) : null
        ) : null}
        {displayZoomPopup ? (
          <PopupWrapper>
            <ImageZoom
              image={fileName + ".webp?" + props.cacheString}
              handleClosing={handleZoomClosing}
              controller={imageZoom.controller}
              element={imageZoom.element}
              title={props.title}
              description={props.alt}
            />
          </PopupWrapper>
        ) : null}
      </picture>
    );
  };

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (activeMode.mode !== activeModes.off) {
      e.preventDefault();
      return;
    }
  };

  const externalLink = useMemo(() => {
    if (
      !props.function.param.startsWith("http://") &&
      !props.function.param.startsWith("https://")
    ) {
      return "https://" + props.function.param;
    } else {
      return props.function.param;
    }
  }, [props.function.param]);

  const renderElement = (isEditMode: boolean, isUpdating: boolean) => {
    if (showElement()) {
      if (isExternalLink) {
        return (
          <a
            target="_blank"
            href={externalLink}
            title={props.title}
            onClick={handleLinkClick}
          >
            {pictureElement(isEditMode, isUpdating)}
          </a>
        );
      } else if (isInternalLink) {
        return (
          <Link
            target="_self"
            to={
              props.deleted
                ? url
                : url + (props.path !== "" ? "/" + props.path : props.path)
            }
            hrefLang=""
            title={props.title}
            onClick={handleLinkClick}
          >
            {pictureElement(isEditMode, isUpdating)}
          </Link>
        );
      } else {
        return pictureElement(isEditMode, isUpdating);
      }
    } else {
      return null;
    }
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false, false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false, false);
  }
}
