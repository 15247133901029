import { ChangeEvent, useState } from "react";
import classNames from "classnames";

export enum FormTextInputType {
  TEXT = "text",
  EMAIL = "email",
  PASSWORD = "password",
  NUMBER = "number",
  DATE = "date",
  TELEPHONE = "tel",
}

export interface numberConfig {
  min: number;
  max: number;
  step: number;
}

export interface formTextInputProps {
  element: string;
  labelText: string;
  name: string;
  value: string;
  dependencyFulfilled: boolean;
  required?: boolean;
  type?: FormTextInputType;
  placeholder?: string;
  numberConfig?: numberConfig;
  showError?: boolean;
  keyId?: string;
  handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function FormTextInput(props: formTextInputProps) {
  const required = props.required === undefined ? false : props.required;
  const [error, setError] = useState(true);
  if (props.dependencyFulfilled === false) return null;

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.required && e.target.value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
    props.handleInput(e);
  };

  const setErrorClass = () => {
    if (props.showError && error && required) {
      return "error";
    }
    return null;
  };

  return (
    <label
      htmlFor={props.name + props.keyId}
      className={classNames(setErrorClass())}
    >
      <p>
        {props.labelText} {props.required === true ? "*" : ""}
      </p>
      <input
        type={props.type === undefined ? "text" : props.type}
        {...(props.numberConfig && {
          min: props.numberConfig.min,
          max: props.numberConfig.max,
          step: props.numberConfig.step,
        })}
        required={required}
        value={props.value}
        name={props.name}
        id={props.name + props.keyId}
        onChange={handleInput}
        placeholder={props.placeholder}
        className={classNames(
          props.type === FormTextInputType.NUMBER ? "left" : null,
        )}
      />
    </label>
  );
}
