import { AnimationsSelect } from "./selects/animationsSelect";
import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { ButtonPosSelect } from "./selects/buttonPosSelect";
import { elementEditorModes } from "../../../enums/edit/elementEditorModes";
import { useState, ChangeEvent, useEffect, useContext } from "react";
import { animationInterface } from "../../../interfaces/animations/animationInterface";
import { produce } from "immer";
import PopupWrapper from "../popupWrapper";
import { socialMediaTypes } from "../../../../backend/enums/database/kunden/socialMedia/socialMediaTypes";
import { firstCharUpper } from "../../../functions/generall/firstCharUpper";
import { commonElementInterface } from "../../../interfaces/elements/common/commonElementInterface";
import { ContactAndSocialMediaDataContext } from "../../../contexts/contactAndSocialMediaDataContext";

export interface socialLinkInterface extends commonElementInterface {
  type: socialMediaTypes;
  stil: string;
  pos: string;
  animations: animationInterface[];
}

export default function SocialLinkEdit(props: {
  elementData: socialLinkInterface;
  mode: elementEditorModes;
  handleElementSaving: (elementData: socialLinkInterface) => void;
  handleClosing?: () => void;
  handleDelete?: () => void;
}) {
  const [editorState, setEditorState] = useState<socialLinkInterface>(
    props.elementData,
  );
  const [activeTypes, setActiveTypes] = useState<socialMediaTypes[]>([]);
  const contactAndSocialMedia = useContext(ContactAndSocialMediaDataContext);
  useEffect(() => {
    // Check if the social media type is in contactAndSocialMedia
    if (
      !Object.keys(contactAndSocialMedia.socialMedia).includes(editorState.type)
    ) {
      setEditorState(
        produce(editorState, (draftState) => {
          draftState.type = socialMediaTypes.facebook;
        }),
      );
    }
    const active = [];
    for (const [key, value] of Object.entries(
      contactAndSocialMedia.socialMedia,
    )) {
      if (value) {
        active.push(key as socialMediaTypes);
      }
    }
    setActiveTypes(active);
  }, []);

  const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEditorState(
      produce(editorState, (draftState) => {
        draftState.type = e.currentTarget.value as socialMediaTypes;
      }),
    );
  };

  const handleAnimationChange = (animations: animationInterface[]) => {
    setEditorState(
      produce(editorState, (draftState) => {
        draftState.animations = animations;
      }),
    );
  };

  const handleStilChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEditorState(
      produce(editorState, (draftState) => {
        draftState.stil = e.currentTarget.value;
      }),
    );
  };

  const handlePosChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEditorState(
      produce(editorState, (draftState) => {
        draftState.pos = e.currentTarget.value;
      }),
    );
  };

  const handleElementSaving = () => {
    props.handleElementSaving(editorState);
  };

  return (
    <PopupWrapper>
      <div id="linkextern" className="large">
        <span>Social Media </span>
        <div className="container">
          <hr className="half" />
          <label htmlFor="socialLink">
            <span className="icon">
              Social Media
              <span>Wähle das gewünschte Social Media Netzwerk aus.</span>
            </span>
            <SvgImage image={Svg.link} />
            <select
              className="icon"
              name="socialLink"
              id="socialLink"
              value={editorState.type}
              onChange={handleTypeChange}
            >
              {activeTypes.map((key) => (
                <option key={key} value={key}>
                  {firstCharUpper(key)}
                </option>
              ))}
            </select>
          </label>
          <label htmlFor="linkStil" className="w50 floatleft">
            <span className="icon">Farbe</span>
            <SvgImage image={Svg.color} />
            <select
              id="linkStil"
              className="icon"
              onChange={handleStilChange}
              value={editorState.stil}
            >
              <option value="">Kundenfarben</option>
              <option value="original">Originalfarben</option>
              <option value="alternative">Alternative</option>
              <option value="onlyicon">Nur Symbol</option>
            </select>
          </label>
          <label htmlFor="linkPos" className="w50">
            <span className="icon">Anordnung</span>
            <SvgImage image={Svg.list} />
            <ButtonPosSelect
              id="linkPos"
              className="icon"
              onChange={handlePosChange}
              value={editorState.pos}
            />
          </label>
          <AnimationsSelect
            handleAnimationChange={handleAnimationChange}
            data={editorState.animations}
          />
          <hr />
          <p>
            Es können nur Social Media Netzwerke ausgewählt werden, die in den
            Globalen Einstellungen hinterlegt sind.
          </p>
          <hr />
        </div>
        <button id="rt" onClick={props.handleClosing}>
          <SvgImage image={Svg.delete} />
        </button>
        {props.mode === elementEditorModes.edit && (
          <button id="lb" onClick={props.handleDelete}>
            <SvgImage image={Svg.trash} />
          </button>
        )}
        <button id="rb" onClick={handleElementSaving}>
          <SvgImage image={Svg.check} />
        </button>
      </div>
    </PopupWrapper>
  );
}
