import { animationInterface } from "../../interfaces/animations/animationInterface";
import { useMemo } from "react";
import { buildAnimationString } from "./buildAnimationString";
import { useInView } from "react-intersection-observer";
import { useIsMounted } from "../generall/useIsMounted";

export function useAnimations(animationsArray: animationInterface[]) {
  const animationsString = useMemo(
    () => buildAnimationString(animationsArray),
    [animationsArray],
  );
  const isMounted = useIsMounted();
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
    skip: !isMounted,
  });
  return {
    animationClasses: animationsString,
    ref: inViewRef,
    animate: inView && animationsString !== "" ? "animate" : "",
  };
}
