import { ChangeEvent, Fragment, useRef } from "react";
import classNames from "classnames";

export interface formCheckboxProps {
  element: string;
  labelText: string;
  name: string;
  values: { text: string; name: string }[];
  handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  hideRequired?: boolean;
  showError?: boolean;
  keyId?: string;
  dependencyFulfilled?: boolean;
}

export function FormCheckbox(props: formCheckboxProps) {
  const required = props.required === undefined ? false : props.required;
  const elemRef = useRef<HTMLLabelElement>(null);
  if (props.dependencyFulfilled === false) return null;

  const setErrorClass = () => {
    if (!elemRef.current) return null;
    let isError = true;
    const checkboxes = elemRef.current.getElementsByTagName("input");
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === true) {
        isError = false;
        break;
      }
    }
    if (props.showError && isError && required) {
      return "error";
    }
    return null;
  };

  return (
    <label className={classNames("checkbox", setErrorClass())} ref={elemRef}>
      <p>
        {props.labelText}{" "}
        {typeof props.hideRequired === "undefined" && props.required === true
          ? "*"
          : ""}
      </p>
      <span
        className={
          typeof props.className !== "undefined" ? props.className : ""
        }
      >
        {props.values.map((value, i) => (
          <Fragment key={value.name + value.text + i}>
            <input
              name={value.name}
              type="checkbox"
              onChange={props.handleInput}
              id={value.name + props.keyId}
            />
            <label htmlFor={value.name + props.keyId}>{value.text}</label>
          </Fragment>
        ))}
      </span>
    </label>
  );
}
