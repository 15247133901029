import { svgObjectInterface } from "../../../interfaces/elements/svgObjectInterface";
import { useImageUrls } from "../../../functions/api/graphics/useImageUrls";
import {
  lazy,
  startTransition,
  MouseEvent,
  Suspense,
  useContext,
  useMemo,
  useCallback,
} from "react";
import classNames from "classnames";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import { Link } from "react-router";
import { UrlContext } from "../../../contexts/urlContext";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export default function SvgObject(props: svgObjectInterface) {
  const activeMode = useActiveMode();
  const urls = useImageUrls();
  const animations = useAnimations(props.animations);
  const login = useAdminLoginContext((s) => s.isLogin);
  const isInternalLink = useMemo(() => {
    return props.function.name.startsWith("link");
  }, [props.function.name]);
  const isExternalLink = useMemo(() => {
    return props.function.name.startsWith("extLink");
  }, [props.function.name]);
  const url = useContext(UrlContext);
  const constructWidth = useMemo(() => {
    if (props.width.unit === "px") {
      return {
        width: props.width.value + "px",
      };
    } else {
      return {
        width: props.width.value + "%",
      };
    }
  }, [props.width.value, props.width.unit]);

  const onClickHandler = useCallback(
    (e: MouseEvent<HTMLPictureElement>) => {
      if (activeMode.mode !== activeModes.off) {
        e.preventDefault();
        return;
      }
    },
    [activeMode.mode],
  );

  const setBildLinkClass = useMemo(() => {
    if (
      (props.function.name === "extLink" || isInternalLink) &&
      activeMode.mode === activeModes.off
    ) {
      return "bildlink";
    } else {
      return "";
    }
  }, [activeMode.mode, isInternalLink, props.function.name]);

  const showElement = useCallback(() => {
    if (isInternalLink) {
      if (props.active) {
        return true;
      } else {
        return !!login;
      }
    } else {
      if (login) {
        return true;
      } else {
        return props.isLive;
      }
    }
  }, [isInternalLink, login, props.active, props.isLive]);

  const getImageHeight = useMemo(() => {
    if (props.imageSize !== undefined) {
      return props.imageSize.height;
    }
    return "";
  }, [props.imageSize]);
  const getImageWidth = useMemo(() => {
    if (props.imageSize !== undefined) {
      return props.imageSize.width;
    }
    return "";
  }, [props.imageSize]);

  const setRef = (e: HTMLElement) => {
    startTransition(() => {
      animations.ref(e);
    });
  };

  const handleLinkClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (activeMode.mode !== activeModes.off) {
        e.preventDefault();
        return;
      }
    },
    [activeMode.mode],
  );

  const pictureElement = (isEditMode: boolean) => {
    return (
      <picture
        className={classNames(
          activeMode.mode === activeModes.move ? "objectdrag" : "",
          isEditMode ? "jslink" : "",
          animations.animationClasses,
          animations.animate,
          props.pos,
          props.padding ? props.padding : "",
        )}
        onClick={onClickHandler}
      >
        <img
          src={urls.svgObject + props.fileName + ".svg?" + props.cacheString}
          title={props.title ? props.title : ""}
          alt={props.title ? "Svg" + props.title : "Svg"}
          style={constructWidth}
          className={classNames(
            setBildLinkClass,
            props.shadow ? props.shadow : "",
          )}
          width={getImageWidth}
          height={getImageHeight}
          ref={setRef}
        />
      </picture>
    );
  };

  const externalLink = useMemo(() => {
    if (
      !props.function.param.startsWith("http://") &&
      !props.function.param.startsWith("https://")
    ) {
      return "https://" + props.function.param;
    } else {
      return props.function.param;
    }
  }, [props.function.param]);
  const renderElement = (isEditMode: boolean) => {
    if (showElement) {
      if (isExternalLink) {
        return (
          <a
            target="_blank"
            href={externalLink}
            title={props.title}
            onClick={handleLinkClick}
          >
            {pictureElement(isEditMode)}
          </a>
        );
      } else if (isInternalLink) {
        return (
          <Link
            target="_self"
            to={
              props.deleted
                ? url
                : url + (props.path !== "" ? "/" + props.path : props.path)
            }
            hrefLang=""
            title={props.title}
            onClick={handleLinkClick}
          >
            {pictureElement(isEditMode)}
          </Link>
        );
      } else {
        return pictureElement(isEditMode);
      }
    } else {
      return pictureElement(isEditMode);
    }
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
