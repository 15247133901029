import { PropsWithChildren, Suspense } from "react";
import { createPortal } from "react-dom";

export default function PopupWrapper(props: PropsWithChildren) {
  if (typeof document === "undefined") return null;
  return createPortal(
    <Suspense fallback={null}>
      <div id="popup" className="show">
        {props.children}
      </div>
    </Suspense>,
    document.body,
  );
}
