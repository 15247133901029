import { create } from "zustand";
import { persist } from "zustand/middleware";
import { produce } from "immer";

interface googleCookieProps {
  googleCookie: boolean;
  setTrue: () => void;
  setFalse: () => void;
}

export const useGoogleCookie = create<googleCookieProps>()(
  persist(
    (set) => ({
      googleCookie: false,
      setTrue: () => {
        set(
          produce((state: googleCookieProps) => {
            state.googleCookie = true;
          }),
        );
      },
      setFalse: () => {
        set(
          produce((state: googleCookieProps) => {
            state.googleCookie = false;
          }),
        );
      },
    }),
    {
      name: "googleCookieState",
    },
  ),
);
