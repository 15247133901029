import { oListInterface } from "../../../interfaces/elements/oListInterface";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { lazy, startTransition, Suspense, useCallback } from "react";
import { ParseText } from "./parseText";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { paddingOptions } from "../../../enums/edit/paddingOptions";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export default function OList(props: oListInterface) {
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    return (
      <ol
        ref={refs}
        className={classNames(
          animations.animationClasses,
          animations.animate,
          isEditMode ? "jslink" : "",
          props.padding ? props.padding : paddingOptions.paddingboth,
        )}
      >
        {props.listItems.map((item, index) => {
          return (
            <li
              className={classNames(
                props.sizeAndPos.size,
                props.sizeAndPos.pos,
              )}
              key={index}
            >
              <ParseText id={props.id} text={item} isEditMode={isEditMode} />
            </li>
          );
        })}
      </ol>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
