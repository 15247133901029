import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { elementEditorModes } from "../../../enums/edit/elementEditorModes";
import { popupSizes } from "../../../enums/popups/popupSizes";
import {
  elementPaddingInterface,
  elementPaddingOptions,
} from "../../htmlElements/structure/abstand";
import { enumMap } from "../../../functions/generall/enumMap";
import { ChangeEvent, useState } from "react";
import Delete from "./delete";
import { produce } from "immer";

const elementPaddingOptionsText = {
  [elementPaddingOptions.small]: "klein",
  [elementPaddingOptions.standard]: "normal",
  [elementPaddingOptions.large]: "groß",
};

export default function PaddingElementEdit(props: {
  handleClosing: () => void;
  handleElementSaving: (elementData: elementPaddingInterface) => void;
  handleDelete: () => void;
  mode: elementEditorModes;
  elementData: elementPaddingInterface;
}) {
  const [editorState, setEditorState] = useState<elementPaddingInterface>({
    ...props.elementData,
    paddingOption: props.elementData.paddingOption
      ? props.elementData.paddingOption
      : elementPaddingOptions.standard,
  });
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const handleElementSaving = () => {
    props.handleElementSaving(editorState);
  };

  const switchDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEditorState(
      produce((state) => {
        state.paddingOption = e.target.value as elementPaddingOptions;
      }),
    );
  };

  if (showDelete) {
    return (
      <Delete handleClosing={switchDelete} handleDelete={props.handleDelete} />
    );
  }
  return (
    <div id="olist" className={popupSizes.small}>
      <span>Abstand bearbeiten</span>
      <div className="container">
        <hr className="half" />
        <label htmlFor="linkStil" className="w100">
          <span className="icon">Größe</span>
          <SvgImage image={Svg.padding} />
          <select
            id="size"
            className="select"
            value={editorState.paddingOption}
            onChange={handleSizeChange}
          >
            {enumMap(elementPaddingOptions, (key: string, value: string) => (
              <option key={key} value={value}>
                {elementPaddingOptionsText[value]}
              </option>
            ))}
          </select>
        </label>
        <hr />
      </div>
      <button id="rt" onClick={props.handleClosing}>
        <SvgImage image={Svg.delete} />
      </button>
      {props.mode === elementEditorModes.edit && (
        <button id="lb" onClick={switchDelete}>
          <SvgImage image={Svg.trash} />
        </button>
      )}
      <button id="rb" onClick={handleElementSaving}>
        <SvgImage image={Svg.check} />
      </button>
    </div>
  );
}
