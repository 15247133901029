import { useUserMediaUrl } from "./useUserMediaUrl";

export function useUserGridMediaUrl() {
  const mediaUrl = useUserMediaUrl();
  return {
    medium: mediaUrl + "grid/medium/",
    big: mediaUrl + "grid/big/",
    large: mediaUrl + "grid/large/",
    grids: mediaUrl + "grids/",
    source: mediaUrl + "grid/original/",
    video: mediaUrl + "grid/video/",
    svg: mediaUrl + "grid/svg/",
  };
}
