import { statsSessionInterface } from "../interfaces/statsSessionInterface";
import {
  remStatCookie,
  tempStatCookie,
} from "../stateManagement/cookies/useStatCookie";
import { createIdentifier } from "../functions/stat/createIdentifier";
import { sendStatsData } from "../functions/stat/sendStatsData";

export class StatsController {
  private readonly remStatCookie: string;
  private readonly tempStatCookie: string;
  private session: statsSessionInterface;
  public dataSend = false;
  constructor() {
    if (!window) return; // server side
    let temp = tempStatCookie.getState().statCookieTemp;
    if (temp === "") {
      temp = createIdentifier(20);
      tempStatCookie.getState().setTemp(temp);
    }
    this.tempStatCookie = temp;
    temp = remStatCookie.getState().statCookieRem;
    if (temp === "") {
      temp = createIdentifier(20);
      remStatCookie.getState().setRem(temp);
    }
    this.remStatCookie = temp;
    this.startSession();
    this.attachUnloadEvent();
  }

  private attachUnloadEvent() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden" && this.dataSend === false) {
        this.dataSend = true;
        this.prepareAndSend();
      }
    });
  }

  private sendToServer(dataToSend: statsSessionInterface) {
    // send data to server
    void sendStatsData(
      dataToSend.loadTime,
      dataToSend.unloadTime,
      dataToSend.clientStart.innerWidth,
      dataToSend.clientStart.innerHeight,
      dataToSend.language,
      navigator.userAgent,
      dataToSend.referrer,
      this.remStatCookie,
      this.tempStatCookie,
      dataToSend.page.location,
    );
    this.startSession();
  }

  public setDataSendFalse() {
    this.dataSend = false;
  }

  public prepareAndSend() {
    if (!this.session) return;
    const dataToSend = this.session;
    dataToSend.unloadTime = new Date();
    dataToSend.clientEnd = this.getClient();
    this.sendToServer(dataToSend);
  }

  private startSession() {
    this.session = {
      clientEnd: {
        innerHeight: 0,
        innerWidth: 0,
        outerHeight: 0,
        outerWidth: 0,
      },
      clientName: "",
      loadTime: new Date(),
      unloadTime: new Date(),
      language: navigator.language,
      referrer: window.document.referrer,
      remCookie: this.remStatCookie,
      tempCookie: this.tempStatCookie,
      clientStart: this.getClient(),
      page: {
        location: location.pathname + location.search,
        href: window.location.href,
        origin: window.location.origin,
        title: window.document.title,
      },
    };
  }

  private getClient() {
    return {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      outerWidth: window.outerWidth,
      outerHeight: window.outerHeight,
    };
  }

  destroy() {
    document.removeEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden" && this.dataSend === false) {
        this.dataSend = true;
        this.prepareAndSend();
      }
    });
  }
}
