import {
  lazy,
  Suspense,
  useContext,
  useState,
  MouseEvent,
  startTransition,
} from "react";
import { ContactAndSocialMediaDataContext } from "../../../contexts/contactAndSocialMediaDataContext";
import { contactTypes } from "../../../../backend/enums/database/kunden/contact/contactTypes";
import { animationInterface } from "../../../interfaces/animations/animationInterface";
import { Svg } from "../../../enums/svg";
import { SvgImage } from "../../app/basicElements/svgImage";
import { commonElementInterface } from "../../../interfaces/elements/common/commonElementInterface";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";

const ContactForm = lazy(
  () =>
    import(
      /* webpackChunkName: "contactForm" */ "../../popups/contact/contactForm"
    ),
);

const CustomForm = lazy(
  () =>
    import(
      /* webpackChunkName: "customForm" */ "../../popups/contact/customForm"
    ),
);
const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export enum specialFunctionsTypeEnum {
  email = "email",
  phone = "phone",
  telegram = "telegram",
  whatsapp = "whatsapp",
  skype = "skype",
  messenger = "messenger",
  contactform = "contactform",
  special = "special",
}
export enum specialFunctionsDesignEnum {
  text = "text",
  icon = "icon",
}

export interface specialFunctionInterface extends commonElementInterface {
  type: specialFunctionsTypeEnum;
  design: specialFunctionsDesignEnum;
  text?: string;
  customFormNumber?: number;
  animations?: animationInterface[];
  stil?: string;
  pos?: string;
}

export default function SpecialFunction(props: specialFunctionInterface) {
  const contactAndSocialMedia = useContext(ContactAndSocialMediaDataContext);
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();
  const closeContactForm = () => {
    setContactFormOpen(false);
  };
  const openContactForm = () => {
    if (activeMode.mode !== activeModes.off) {
      return;
    }
    setContactFormOpen(true);
  };

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (activeMode.mode !== activeModes.off) {
      e.preventDefault();
    }
  };

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    switch (props.type) {
      case specialFunctionsTypeEnum.email:
        return (
          <div
            className={classNames(
              "button",
              "intern",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
            ref={refs}
          >
            <a
              target="_self"
              href={
                "mailto:" + contactAndSocialMedia.contact[contactTypes.mail]
              }
              hrefLang=""
              title=""
              aria-label={props.text ? props.text : "Per E-Mail anfragen"}
              onClick={handleClick}
            >
              {props.design === specialFunctionsDesignEnum.icon ? (
                <SvgImage image={Svg.mail} />
              ) : props.text ? (
                props.text
              ) : (
                "Per E-Mail anfragen"
              )}
            </a>
          </div>
        );
      case specialFunctionsTypeEnum.phone:
        return (
          <div
            className={classNames(
              "button",
              "intern",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
          >
            <a
              target="_self"
              href={"tel:" + contactAndSocialMedia.contact[contactTypes.phone]}
              hrefLang=""
              title=""
              aria-label={props.text ? props.text : "Jetzt anrufen"}
              onClick={handleClick}
            >
              {props.design === specialFunctionsDesignEnum.icon ? (
                <SvgImage image={Svg.phone} />
              ) : props.text ? (
                props.text
              ) : (
                "Jetzt anrufen"
              )}
            </a>
          </div>
        );
      case specialFunctionsTypeEnum.messenger:
        return (
          <div
            className={classNames(
              "button",
              "sociallink",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
          >
            <a
              target="_blank"
              href={
                "https://m.me/" +
                contactAndSocialMedia.contact[contactTypes.messenger]
              }
              hrefLang=""
              className={classNames(props.type)}
              title=""
              aria-label={props.text ? props.text : "Facebook Messenger"}
              onClick={handleClick}
            >
              {props.design === specialFunctionsDesignEnum.icon ? (
                <SvgImage image={Svg.messenger} />
              ) : props.text ? (
                props.text
              ) : (
                "Messenger"
              )}
            </a>
          </div>
        );
      case specialFunctionsTypeEnum.skype:
        return (
          <div
            className={classNames(
              "button",
              "sociallink",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
          >
            <a
              target="_blank"
              href={contactAndSocialMedia.contact[contactTypes.skype]}
              hrefLang=""
              title=""
              className={classNames(props.type)}
              aria-label={props.text ? props.text : "Skype"}
              onClick={handleClick}
            >
              {props.design === specialFunctionsDesignEnum.icon ? (
                <SvgImage image={Svg.skype} />
              ) : props.text ? (
                props.text
              ) : (
                "Skype"
              )}
            </a>
          </div>
        );
      case specialFunctionsTypeEnum.telegram:
        return (
          <div
            className={classNames(
              "button",
              "sociallink",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
          >
            <a
              target="_blank"
              href={
                "https://telegram.me/" +
                contactAndSocialMedia.contact[contactTypes.telegram]
              }
              hrefLang=""
              title=""
              className={classNames(props.type)}
              aria-label={props.text ? props.text : "Telegram"}
              onClick={handleClick}
            >
              {props.design === specialFunctionsDesignEnum.icon ? (
                <SvgImage image={Svg.telegram} />
              ) : props.text ? (
                props.text
              ) : (
                "Telegram"
              )}
            </a>
          </div>
        );
      case specialFunctionsTypeEnum.whatsapp:
        return (
          <div
            className={classNames(
              "button",
              "sociallink",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
          >
            <a
              target="_blank"
              href={
                "https://wa.me/" +
                contactAndSocialMedia.contact[contactTypes.whatsapp]
              }
              className={classNames(props.type)}
              hrefLang=""
              title=""
              aria-label={props.text ? props.text : "WhatsApp"}
              onClick={handleClick}
            >
              {props.design === specialFunctionsDesignEnum.icon ? (
                <SvgImage image={Svg.whatsapp} />
              ) : props.text ? (
                props.text
              ) : (
                "WhatsApp"
              )}
            </a>
          </div>
        );
      case specialFunctionsTypeEnum.contactform:
        return (
          <div
            className={classNames(
              "button",
              "intern",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
          >
            <button
              className="jslink"
              aria-label={props.text ? props.text : "Jetzt Kontakt aufnehmen"}
              onClick={openContactForm}
            >
              {props.design === specialFunctionsDesignEnum.icon ? (
                <SvgImage image={Svg.mail} />
              ) : props.text ? (
                props.text
              ) : (
                "Jetzt Kontakt aufnehmen"
              )}
            </button>
            {contactFormOpen ? (
              <Suspense>
                <ContactForm handleClosing={closeContactForm} />
              </Suspense>
            ) : null}
          </div>
        );
      case specialFunctionsTypeEnum.special:
        return (
          <div
            className={classNames(
              "button",
              "intern",
              props.pos,
              props.stil,
              animations.animationClasses,
              animations.animate,
              isEditMode ? "jslink" : "",
            )}
          >
            <button
              className="jslink"
              aria-label={props.text ? props.text : "Jetzt Kontakt aufnehmen"}
              onClick={openContactForm}
            >
              {props.text ? props.text : "Jetzt Kontakt aufnehmen"}
            </button>
            {contactFormOpen ? (
              <Suspense>
                <CustomForm
                  handleClosing={closeContactForm}
                  customFormNumber={props.customFormNumber}
                  headline={props.text ? props.text : "Jetzt Kontakt aufnehmen"}
                />
              </Suspense>
            ) : null}
          </div>
        );
      default:
        return null;
    }
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
