import { footerAddressDataInterface } from "./footer";
import { useMemo } from "react";

export function FooterAddress({
  address,
}: {
  address: footerAddressDataInterface;
}) {
  const buildAddress = useMemo(() => {
    const parts = [
      address.company.trim(),
      address.owner.trim(),
      address.streetAddress.trim(),
      address.zip.trim() + " " + address.city.trim(),
    ].filter((part) => part !== "");

    return parts.join(" · ");
  }, [address]);

  return (
    <div id="address">
      <p>{buildAddress}</p>
    </div>
  );
}
