let imageIds = 0;

export function setImageId() {
  imageIds++;
  return imageIds;
}
export class ImageZoomController {
  zoomImages: {
    id: number;
    imageSrc: string;
    title: string;
    description: string;
  }[];

  addImage(src: string, title: string, description: string) {
    if (!this.zoomImages) {
      this.zoomImages = [];
    }

    this.zoomImages.push({
      id: setImageId(),
      imageSrc: src,
      title: title,
      description: description,
    });

    return this.zoomImages[this.zoomImages.length - 1];
  }

  removeImage(id: number) {
    this.zoomImages = this.zoomImages.filter((image) => image.id !== id);
  }

  getNextImage(id: number) {
    const index = this.zoomImages.findIndex((image) => image.id === id);
    if (index === this.zoomImages.length - 1) {
      return this.zoomImages[0];
    }
    return this.zoomImages[index + 1];
  }

  getPreviousImage(id: number) {
    const index = this.zoomImages.findIndex((image) => image.id === id);
    if (index === 0) {
      return this.zoomImages[this.zoomImages.length - 1];
    }
    return this.zoomImages[index - 1];
  }

  getImageCount() {
    return this.zoomImages.length;
  }
}
