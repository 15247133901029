import { useActiveMode } from "../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../enums/edit/activeModes";
import { elementPos } from "../../enums/edit/elementPos";
import {
  Suspense,
  createElement,
  useState,
  ReactNode,
  lazy,
  startTransition,
} from "react";
import { deleteElement } from "../api/edit/deleteElement";
import PopupWrapper from "../../elements/popups/popupWrapper";
import { editGridElement } from "../api/edit/editGridElement";
import { usePageMutator } from "../api/page/usePageMutator";

const AddElement = lazy(
  () =>
    import(
      /* webpackChunkName: "addElement" */ "../../elements/app/basicElements/addElement"
    ),
);

const Delete = lazy(
  () =>
    import(
      /* webpackChunkName: "delete" */ "../../elements/popups/edit/delete"
    ),
);

export function useGridEdit(gridId?: string) {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const pageMutate = usePageMutator();
  const isGridEdit = useActiveMode().mode;

  const gridAdd = (position: elementPos, elementKey: string) => {
    if (isGridEdit !== activeModes.grid) return null;
    return createElement(
      Suspense,
      { fallback: null },
      createElement(AddElement, {
        pos: position,
        elementId: elementKey,
        element: "GridDiv",
      }),
    );
  };

  const handleGridSaving = (
    objectData: object,
    file: Blob,
    fileType: "image" | "video" | "none",
    filesDimensions?: object,
    svgFiles?: { blob: Blob; name: string }[],
  ) => {
    if (!gridId) return;
    void editGridElement(
      objectData,
      gridId,
      fileType,
      file,
      filesDimensions ? filesDimensions : null,
      svgFiles ? svgFiles : null,
    ).then((r) => {
      if (r === false) return; //todo error handling and visual feedback
      pageMutate.reload();
      setIsEditing(false);
    });
  };

  const activateEdit = () => {
    startTransition(() => {
      if (isGridEdit === activeModes.grid && !isEditing) {
        setIsEditing(true);
      }
    });
  };

  const handleClosing = () => {
    setIsEditing(false);
  };

  const handleDelete = () => {
    deleteElement(gridId)
      .then(() => {
        pageMutate.reload();
        setIsEditing(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSetDelete = () => {
    setIsDeleting(true);
  };

  const handleCloseDelete = () => {
    setIsDeleting(false);
  };

  const editPortal = (editGrid: ReactNode) => {
    if (!isEditing) return null;
    if (!isDeleting) {
      return createElement(Suspense, { fallback: null }, editGrid);
    } else {
      return createElement(
        PopupWrapper,
        {},
        createElement(Delete, {
          handleClosing: handleCloseDelete,
          handleDelete: handleDelete,
        }),
      );
    }
  };

  return {
    gridAdd,
    editPortal,
    handleGridSaving,
    activateEdit,
    handleClosing,
    handleSetDelete,
    isEditing,
  };
}
