const baseUrl = process.env.REACT_APP_API_SERVER;
export const apiUrls = {
  base: baseUrl,
  locales: `${baseUrl}/locales/`,
  svg: `${baseUrl}/media/svg/`,
  getPage: `${baseUrl}/page/get`,
  getPageData: `${baseUrl}/page/getData`,
  updatePageData: `${baseUrl}/page/updateData`,
  checkPagePath: `${baseUrl}/page/checkPath`,
  createPage: `${baseUrl}/page/createPage`,
  deletePage: `${baseUrl}/page/deletePage`,
  getMainMenu: `${baseUrl}/page/getMainMenu`,
  login: "/d56b699830e77ba53855679cb1d252da",
  refreshToken: "/03b62516184fb6ef591f45bd4974b753",
  logout: "/4236a440a662cc8253d7536e5aa17942",
  checkLogin: "/c0ef4307f8ff9b9beded5c3967c53e9a",
  checkLoginInitial: "/c0ef4307f8ff9b9beded5c3967c53e9aini",
  addElement: `${baseUrl}/edit/addElement`,
  addElementWithFile: `${baseUrl}/edit/addElementWithFile`,
  addGridElement: `${baseUrl}/edit/addGridElement`,
  editElement: `${baseUrl}/edit/editElement`,
  editElementWithFile: `${baseUrl}/edit/editElementWithFile`,
  editGridElement: `${baseUrl}/edit/editGridElement`,
  moveElement: `${baseUrl}/edit/moveElement`,
  deleteElement: `${baseUrl}/edit/deleteElement`,
  getAllModules: `${baseUrl}/modules/getAll`,
  getPageDesigns: `${baseUrl}/settings/getPageDesigns`,
  savePageDesign: `${baseUrl}/settings/savePageDesign`,
  getOpengraph: `${baseUrl}/settings/getOpengraph`,
  saveOpengraph: `${baseUrl}/settings/saveOpengraph`,
  getPageListData: `${baseUrl}/settings/getPageListData`,
  contractDetails: `${baseUrl}/globalSettings/contractDetails`,
  getAddress: `${baseUrl}/globalSettings/getAddress`,
  saveAddress: `${baseUrl}/globalSettings/saveAddress`,
  getBankAccount: `${baseUrl}/globalSettings/getBankAccount`,
  getContactData: `${baseUrl}/globalSettings/getContactData`,
  saveContactData: `${baseUrl}/globalSettings/saveContactData`,
  getSocialMediaData: `${baseUrl}/globalSettings/getSocialMediaData`,
  saveSocialMediaData: `${baseUrl}/globalSettings/saveSocialMediaData`,
  getUserList: `${baseUrl}/globalSettings/getUserList`,
  getUser: `${baseUrl}/globalSettings/getUser`,
  checkEmail: `${baseUrl}/globalSettings/checkEmail`,
  saveUser: `${baseUrl}/globalSettings/saveUser`,
  createUser: `${baseUrl}/globalSettings/createUser`,
  deleteUser: `${baseUrl}/globalSettings/deleteUser`,
  getMainMenuData: `${baseUrl}/globalSettings/getMainMenuData`,
  saveMainMenu: `${baseUrl}/globalSettings/saveMainMenu`,
  getGoogleCoordinates: `${baseUrl}/globalSettings/getGoogleCoordinates`,
  saveGoogleCoordinates: `${baseUrl}/globalSettings/saveGoogleCoordinates`,
  getCustomForms: `${baseUrl}/globalSettings/getCustomForms`,
  getCustomForm: `${baseUrl}/globalSettings/getCustomForm`,
  saveFormEdit: `${baseUrl}/globalSettings/saveFormEdit`,
  sendContactForm: `${baseUrl}/forms/sendContactForm`,
  getStickyContactData: `${baseUrl}/globalSettings/getStickyContactData`,
  saveStickyContactData: `${baseUrl}/globalSettings/saveStickyContactData`,
  getStickyContactButton: `${baseUrl}/globalSettings/getStickyContactButton`,
  switchMaintenanceMode: `${baseUrl}/settings/switchMaintenanceMode`,
  saveRedirect: `${baseUrl}/settings/saveRedirect`,
  createNewPassword: `${baseUrl}/globalSettings/createNewPassword`,
  savePageListData: `${baseUrl}/settings/savePageListData`,
  generateCss: `${baseUrl}/settings/generateCss`,
  generateCssDev: "https://core.multitype.dev/settings/generateCss",
  getOnPageForms: `${baseUrl}/edit/getOnPageForms`,
  sendOnPageForm: `${baseUrl}/forms/sendOnPageForm`,
  copyContentToPage: `${baseUrl}/edit/copyContentToPage`,
  sendData: "/68c4283db8074b12df1660b31c0220a9",
  getCampaigns: `${baseUrl}/stats/getCampaigns`,
  copyStyleData: `${baseUrl}/settings/copyStyleData`,
  generateCssForAll: "/settings/generateCssForAll",
  getPageStatsData: `${baseUrl}/stats/getPageStatsData`,
  saveCampaign: `${baseUrl}/stats/saveCampaign`,
  userPostFunctions: "/ae33a5c0b0301b0f2c7ff57660a4db85",
  saveAutoresponderData: `${baseUrl}/globalSettings/saveAutoresponderData`,
  getAutoresponderData: `${baseUrl}/globalSettings/getAutoresponderData`,
  getCustomElements: `${baseUrl}/edit/getCustomElements`,
  getGoogleAnalyticsUsage: `${baseUrl}/settings/getGoogleAnalyticsUsage`,
  userLogin: "/7eec54842ae92700ea21f7289a2ed383",
  getSitePermissions: `${baseUrl}/globalSettings/getSitePermissions`,
};
