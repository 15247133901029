import { getSvgUrl } from "../../../functions/api/graphics/getSvgUrl";
import { Svg } from "../../../enums/svg";

export function Success() {
  return (
    <div id={"success"} className={"show"}>
      <img src={getSvgUrl(Svg.success)} alt="erfolgreich" />
    </div>
  );
}
