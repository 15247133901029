import { HTMLProps } from "react";

export function ButtonPosSelect(props: HTMLProps<HTMLSelectElement>) {
  return (
    <select {...props}>
      <option value="posleft">Links</option>
      <option value="poscenter">Mittig</option>
      <option value="posright">Rechts</option>
      <option value="posfull">Volle Breite</option>
      <option value="floatleft">Nebeneinander links</option>
      <option value="floatright">Nebeneinander rechts</option>
    </select>
  );
}
