import { MouseEvent } from "react";
import classNames from "classnames";

export interface formSendButtonProps {
  element: string;
  text: string;
  name: string;
  handleInput: () => void;
  pos?: string;
}
export function FormSendButton(props: formSendButtonProps) {
  const handleSend = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.handleInput();
  };
  return (
    <div className={classNames("formbutton", props.pos)}>
      <button name={props.name} type="submit" onClick={handleSend}>
        {props.text}
      </button>
    </div>
  );
}
