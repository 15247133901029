import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";

export default function Delete(props: {
  handleClosing: () => void;
  handleDelete: () => void;
  isModule?: boolean;
}) {
  return (
    <div id="delete" className="small">
      <span>Löschen</span>
      <div className="container">
        <hr />
        <p>
          Willst du das {props.isModule ? "Modul" : "Element"} wirklich löschen?
          Du kannst den Vorgang nicht rückgängig machen!
        </p>
        <hr />
      </div>
      <button id="rt" onClick={props.handleClosing}>
        <SvgImage image={Svg.delete} />
      </button>
      <button id="rb" onClick={props.handleDelete}>
        <SvgImage image={Svg.trash} />
      </button>
    </div>
  );
}
