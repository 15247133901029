import { getSvgUrl } from "../../../functions/api/graphics/getSvgUrl";
import { Svg } from "../../../enums/svg";

export function Loading() {
  return (
    <div id={"loading"} className={"show"}>
      <img src={getSvgUrl(Svg.load)} alt="wird geladen" />
    </div>
  );
}
