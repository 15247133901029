import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";

export function sendOnPageForm(
  formId: number,
  data: object,
  files: File[] = [],
) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("formId", formId.toString());
    formData.append("data", JSON.stringify(data));
    files.forEach((file, i) => {
      // append
      formData.append("file" + i, file);
    });
    if (files.length > 0) {
      formData.append("amountFiles", files.length.toString());
    } else {
      formData.append("amountFiles", "0");
    }
    postToApi(apiUrls.sendOnPageForm, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((response: basicApiResponse) => {
        if (response.data.success) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
