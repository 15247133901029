import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { externalLinkInterface } from "../../../interfaces/elements/externalLinkInterface";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { lazy, MouseEvent, startTransition, Suspense } from "react";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);
export default function ExternalLink(props: externalLinkInterface) {
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (activeMode.mode !== activeModes.off) {
      e.preventDefault();
      return;
    }
  };

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    return (
      <div
        ref={refs}
        className={classNames(
          "button",
          "extern",
          props.pos,
          props.stil,
          animations.animationClasses,
          animations.animate,
          isEditMode ? "jslink" : "",
        )}
      >
        <a
          target="_blank"
          href={props.link}
          hrefLang=""
          title={props.title}
          onClick={handleLinkClick}
          rel="noopener noreferrer"
        >
          <SvgImage image={Svg.link} />
          {props.title}
        </a>
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
