import { create } from "zustand";
import { persist } from "zustand/middleware";
import { produce } from "immer";
import { supportedVideoSites } from "../../enums/edit/supportedVideoSites";

interface videoProps {
  youtube: boolean;
  vimeo: boolean;
  dailymotion: boolean;
  setTrue: (type: supportedVideoSites) => void;
  setFalse: (type: supportedVideoSites) => void;
}
export const useVideoCookie = create<videoProps>()(
  persist(
    (set) => ({
      youtube: false,
      vimeo: false,
      dailymotion: false,
      setTrue: (type) =>
        set(
          produce((state: videoProps) => {
            state[type] = true;
          }),
        ),
      setFalse: (type) =>
        set(
          produce((state: videoProps) => {
            state[type] = false;
          }),
        ),
    }),
    {
      name: "videoPlayerCookieState",
      skipHydration: true,
    },
  ),
);
