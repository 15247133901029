import { breadCrumbs } from "../../../../backend/types/breadCrumbs";
import { Link } from "react-router";
import { useContext } from "react";
import { PageDesignContext } from "../../../contexts/pageDesignContext";
import classNames from "classnames";
import { UrlContext } from "../../../contexts/urlContext";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";

export function BreadCrumb(props: { breadCrumbs: breadCrumbs }) {
  const pageDesign = useContext(PageDesignContext);
  const url = useContext(UrlContext);
  const activeMode = useActiveMode();
  if (activeMode.mode !== activeModes.off) return null;
  if ((pageDesign.breadcrumb as unknown as string) === "0") return null;
  return (
    <nav className={classNames(pageDesign.breadcrumbColor)}>
      <div className="grid medium">
        <ol>
          {props.breadCrumbs.map((site) => {
            return (
              <li key={site.short_title + "_breadcrumb"}>
                <Link to={url + "/" + site.path} title={site.short_title}>
                  {site.short_title}
                </Link>
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
}
