import { CustomElement } from "./customElement";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { Suspense } from "react";
import EditElement from "../../app/basicElements/editElement";
import { useIsMounted } from "../../../functions/generall/useIsMounted";
import { customElementInterface } from "../../../interfaces/elements/customElementInterface";

export default function CustomElementSkeleton(props: customElementInterface) {
  const activeMode = useActiveMode();
  const isMounted = useIsMounted();

  if (!isMounted) return null;
  const renderElement = (isEditMode: boolean) => {
    if (isEditMode || activeMode.mode !== activeModes.off) {
      return (
        <div className={classNames("jslink")}>
          <CustomElement elementContent={props.customElementData.contents} />
        </div>
      );
    } else {
      return (
        <CustomElement elementContent={props.customElementData.contents} />
      );
    }
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
