import { ChangeEvent, useEffect, useState } from "react";
import { Svg } from "../../../../enums/svg";
import { SvgImage } from "../../../app/basicElements/svgImage";
import { getStickyContactData } from "../../../../functions/api/settings/global/getStickyContactData";
import { saveStickyContactData } from "../../../../functions/api/settings/global/saveStickyContactData";
import { usePageMutator } from "../../../../functions/api/page/usePageMutator";
import { ModulesAsOptions, optionsMode } from "../../edit/modulesAsOptions";

export interface stickyContactInterface {
  title: string;
  text: string;
  type: stickyContactType;
  customForm: number;
  link?: string;
  linkValue?: string;
  svg: Svg.mail | Svg.phone | Svg.at;
  customForms?: { id: number; name: string }[];
  selected?: string;
}

export enum stickyContactType {
  contactform = "contactform",
  email = "email",
  phone = "phone",
  special = "special",
  link = "link",
}
export function EditStickyContact(props: {
  handleBack: () => void;
  handleClosing: () => void;
}) {
  const [stickyContactData, setStickyContactData] =
    useState<stickyContactInterface>({
      title: "",
      text: "",
      type: stickyContactType.contactform,
      customForm: 0,
      svg: Svg.mail,
      customForms: [],
      link: "",
      linkValue: "",
      selected: stickyContactType.contactform,
    });
  const mutate = usePageMutator();
  useEffect(() => {
    getStickyContactData()
      .then((data: stickyContactInterface) => {
        if (data.type === stickyContactType.special) {
          data.selected = data.customForm.toString();
        } else {
          data.selected = data.type;
        }
        if (data.type === stickyContactType.link) {
          data.selected = data.linkValue;
        }
        setStickyContactData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const createCustomFormOptions = () => {
    return stickyContactData.customForms.map((form) => {
      return (
        <option
          value={form.id}
          data-customform={true}
          key={form.id + form.name}
        >
          {form.name}
        </option>
      );
    });
  };

  const handleStickySaving = () => {
    const saveType = ["contactform", "email", "phone"].includes(
      stickyContactData.selected,
    )
      ? stickyContactData.selected
      : stickyContactData.selected.startsWith("link")
        ? "link"
        : "special";
    const sendData = {
      ...stickyContactData,
      type: saveType,
      customForm:
        saveType === "special" ? Number(stickyContactData.selected) : 0,
    };
    saveStickyContactData(sendData)
      .then(() => {
        mutate.reload();
        props.handleClosing();
      })
      .catch(console.error);
  };

  const handleChange =
    (field: keyof stickyContactInterface) =>
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (field === "selected" && e.target.value.startsWith("link")) {
        const linkEvent = e as ChangeEvent<HTMLSelectElement>;
        setStickyContactData({
          ...stickyContactData,
          type: stickyContactType.link,
          link: linkEvent.target.options[linkEvent.target.selectedIndex].dataset
            .prop,
          linkValue: linkEvent.target.value,
          selected: linkEvent.target.value,
        });
      } else {
        setStickyContactData({ ...stickyContactData, [field]: e.target.value });
      }
    };

  return (
    <div id="editstickycontact" className="small">
      <span>Schnellkontakt</span>
      <div className="container">
        <hr className="half" />
        <label htmlFor="editstickycontacttext1">
          <span className="icon">Titel</span>
          <SvgImage image={Svg.title} />
          <input
            className="icon"
            type="text"
            name="editstickycontacttext1"
            id="editstickycontacttext1"
            value={stickyContactData.title}
            placeholder=""
            onChange={handleChange("title")}
          />
        </label>
        <label htmlFor="editstickycontacttext2">
          <span className="icon">Text</span>
          <SvgImage image={Svg.description} />
          <input
            className="icon"
            type="text"
            name="editstickycontacttext2"
            id="editstickycontacttext2"
            value={stickyContactData.text}
            placeholder=""
            onChange={handleChange("text")}
          />
        </label>
        <label htmlFor="editstickycontacttype">
          <span className="icon">Funktion</span>
          <SvgImage image={Svg.settings} />
          <select
            id="editstickycontacttype"
            name="editstickycontacttype"
            className="icon"
            onChange={handleChange("selected")}
            value={stickyContactData.selected}
          >
            <option value={stickyContactType.contactform}>
              Kontaktformular
            </option>
            <option value={stickyContactType.phone}>Telefon</option>
            <option value={stickyContactType.email}>E-Mail</option>
            <optgroup label="Interne Verlinkung">
              <ModulesAsOptions
                mode={optionsMode.dataprop}
                valueType={"path"}
              />
            </optgroup>
            {createCustomFormOptions()}
          </select>
        </label>
        <label htmlFor="editstickycontacticon">
          <span className="icon">Symbol</span>
          <SvgImage image={Svg.status} />
          <select
            id="editstickycontacticon"
            name="editstickycontacticon"
            className="icon"
            value={stickyContactData.svg}
            onChange={handleChange("svg")}
          >
            <option value={Svg.mail}>Nachricht</option>
            <option value={Svg.phone}>Telefon</option>
            <option value={Svg.at}>E-Mail</option>
          </select>
        </label>
        <hr />
      </div>
      <button id="lt" className="jslink" onClick={props.handleBack}>
        <SvgImage image={Svg.left} />
      </button>
      <button id="rb" className="jssend" onClick={handleStickySaving}>
        <SvgImage image={Svg.check} />
      </button>
      <button id="rt" className="jsclose" onClick={props.handleClosing}>
        <SvgImage image={Svg.delete} />
      </button>
    </div>
  );
}
