import React, { HTMLProps, ReactNode } from "react";
import { JSX } from "react/jsx-runtime";
import useScript from "../../../functions/generall/useScript";
import {
  activeModeProps,
  useActiveMode,
} from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";

const createElement = {
  ceSpan: (
    props: JSX.IntrinsicAttributes &
      React.ClassAttributes<HTMLSpanElement> &
      React.HTMLAttributes<HTMLSpanElement>,
    key: string,
    children: ReactNode[],
  ): ReactNode => {
    return <span {...props} key={key} children={children} />;
  },
  ceDiv: (
    props: JSX.IntrinsicAttributes &
      React.ClassAttributes<HTMLDivElement> &
      React.HTMLAttributes<HTMLDivElement>,
    key: string,
    children: ReactNode[],
  ): ReactNode => {
    return (
      <div
        {...props}
        key={key}
        children={children}
        style={props.style ? props.style : null}
      />
    );
  },
  ceAnchor: (
    props: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    key: string,
    children: ReactNode[],
  ): ReactNode => {
    return (
      <a
        {...props}
        key={key}
        children={children}
        style={props.style ? props.style : null}
      />
    );
  },
  ceImg: (
    props: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    key: string,
  ): ReactNode => {
    return (
      <img {...props} key={key} style={props.style ? props.style : null} />
    );
  },
  cePrint: (
    props: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    key: string,
    children: ReactNode[],
    activeMode: activeModeProps,
  ): ReactNode => {
    return (
      <div key={key} className={"button posleft"}>
        <button
          {...props}
          style={props.style ? props.style : null}
          onClick={() => {
            if (activeMode.mode === activeModes.off) {
              window.print();
            }
          }}
        >
          Diese Seite Drucken
        </button>
      </div>
    );
  },
};

/*
{
  element: 'ceSpan' | 'ceDiv' | 'ceAnchor' | 'ceImg',
  props: JSX.IntrinsicAttributes
  children:
}
 */

export interface customElementProps {
  element: "ceSpan" | "ceDiv" | "ceAnchor" | "ceImg" | "ceScript" | "cePrint";
  props?: JSX.IntrinsicAttributes;
  children?: customElementProps[];
}

/**
 * walk through the array and create the elements
 * @param daten
 * @param activeMode
 */
function arrayWalker(
  daten: customElementProps[],
  activeMode: activeModeProps,
): ReactNode[] {
  const elems = [];
  let i = 0;
  daten.forEach((element) => {
    const children = [];

    if (typeof element["element"] === "undefined") {
      return;
    }

    if (typeof element["children"] !== "undefined") {
      //elem has children
      children.push(arrayWalker(element["children"], activeMode));
    }
    if (element["element"] === "ceScript") {
      useScript(element["src"] as string);
    } else {
      const key = element["element"] + i;
      elems.push(
        jsonToHTML(
          element["element"],
          element as HTMLProps<never>,
          key,
          children,
          activeMode,
        ),
      );
    }
    i++;
  });

  return elems;
}

/**
 * create the element from the json data
 * @param element
 * @param props
 * @param key
 * @param children
 * @param activeMode
 */
function jsonToHTML(
  element: "ceSpan" | "ceDiv" | "ceAnchor" | "ceImg" | "cePrint",
  props: HTMLProps<never>,
  key: string,
  children?: ReactNode[],
  activeMode?: activeModeProps,
): ReactNode {
  if (typeof createElement[element] !== "undefined") {
    // @ts-expect-error ignore
    return createElement[element](props, key, children, activeMode);
  } else {
    console.error("Element not found: " + element);
    return "";
  }
}

export function CustomElement(props: { elementContent: customElementProps[] }) {
  const activeMode = useActiveMode();
  return arrayWalker(props.elementContent, activeMode);
}
