import classNames from "classnames";

import { useUserGridMediaUrl } from "../../../../functions/url/useUserGridMediaUrl";
import { videoFileFormats } from "../../../../enums/videoFileFormats";
import { RefObject } from "react";
import { gridStil } from "../../../../enums/edit/grid/gridStil";

export default function GridVideo(props: {
  stil: gridStil;
  videoName: string;
  cacheString: string;
  mediaValues: string[];
  pictureRef: RefObject<HTMLDivElement>;
  imageRef: RefObject<HTMLVideoElement>;
}) {
  const gridUrls = useUserGridMediaUrl();
  return (
    <div
      className={classNames("gridvideo", props.stil, props.mediaValues)}
      ref={props.pictureRef}
      /*style={
        props.stil === gridStil['Video (fest)']
          ? {opacity: 0, transition: 'opacity 0.1s ease-in-out'}
          : null
      }*/
    >
      <video
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        src={
          gridUrls.video +
          props.videoName +
          videoFileFormats.mp4 +
          "?" +
          props.cacheString
        }
        ref={props.imageRef}
      />
    </div>
  );
}
