import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import PopupWrapper from "../popupWrapper";
import { usePageMutator } from "../../../functions/api/page/usePageMutator";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";

export default function LogoutModal(props: { close: () => void }) {
  const logoutFunction = useAdminLoginContext((s) => s.logout);
  const pageMutate = usePageMutator();
  const logout = () => {
    logoutFunction()
      .then(() => {
        pageMutate.reload();
        props.close();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <PopupWrapper>
      <div id="logout" className="small">
        <span>Systemlogout</span>
        <div className="container">
          <hr />
          <p>Willst du dich wirklich abmelden?</p>
          <hr />
        </div>
        <button id="rt" className="jsclose" onClick={props.close}>
          <SvgImage image={Svg.delete} />
        </button>
        <button id="rb" className="jssend" onClick={logout}>
          <SvgImage image={Svg.login} />
        </button>
      </div>
    </PopupWrapper>
  );
}
