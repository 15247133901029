import { HTMLProps } from "react";

interface linkStilSelectProps extends HTMLProps<HTMLSelectElement> {}

export function LinkStilSelect(props: linkStilSelectProps) {
  return (
    <select {...props}>
      <option value="">Standard</option>
      <option value="alternative">Alternative</option>
      <option value="link">Link Standard</option>
      <option value="linkalternative">Link Alternative</option>
    </select>
  );
}
