import { useLocation } from "react-router";
import { useBaseCookie } from "../../stateManagement/cookies/useBaseCookie";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { ReactTagManager } from "react-gtm-ts";
export function useAnalytics() {
  const location = useLocation();
  const cookie = useBaseCookie();

  /*
  useEffect(() => {
    if (cookie.googleAnalytics && cookie.googleAnalyticsID !== '') {
      ReactGA.initialize(cookie.googleAnalyticsID);
      ReactGA.send({hitType: 'pageview', page: window.location.pathname});
    }
  }, []);

   */
  useEffect(() => {
    if (cookie.googleAnalytics && cookie.googleAnalyticsID !== "") {
      ReactGA.initialize(cookie.googleAnalyticsID);
      //ReactGA.send({hitType: 'pageview', page: window.location.pathname});
    }
  }, [cookie.googleAnalyticsID, cookie.googleAnalytics]);

  useEffect(() => {
    if (cookie.googleTagManager && cookie.googleTagManagerID !== "") {
      ReactTagManager.init({
        code: cookie.googleTagManagerID,
      });
    }
  }, [cookie.googleTagManagerID, cookie.googleTagManager]);

  useEffect(() => {
    if (cookie.googleAnalytics && cookie.googleAnalyticsID !== "") {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [location.pathname]);

  return null;
}
