import { PropsWithChildren, useContext } from "react";
import { Logo } from "../basicElements/logo";
import { PageDesignContext } from "../../../contexts/pageDesignContext";
import classNames from "classnames";
export function Header(
  props: PropsWithChildren<{
    logoTitle: string;
  }>,
) {
  const pageDesign = useContext(PageDesignContext);
  return (
    <header
      className={classNames(
        pageDesign.logoColor,
        pageDesign.logoPosition,
        pageDesign.headerColor,
      )}
    >
      <div className={classNames("grid", "medium")}>
        <Logo title={props.logoTitle} />
      </div>
      {props.children}
    </header>
  );
}
