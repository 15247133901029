import { lazy, PropsWithChildren, startTransition, Suspense } from "react";
import { boxInterface } from "../../../interfaces/elements/boxInterface";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);
export default function Box(props: PropsWithChildren<boxInterface>) {
  const activeMode = useActiveMode();
  const animations = useAnimations(props.animations);

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    return (
      <div
        ref={refs}
        className={classNames(
          "box",
          props.cellCount,
          props.stil,
          props.pos,
          animations.animationClasses,
          animations.animate,
          isEditMode ? "jslink" : "",
          props.padding ? props.padding : "",
        )}
      >
        {props.children}
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
