import { PropsWithChildren, Suspense } from "react";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import ContentDivEdit from "../basicElements/contentDivEdit";

export default function ContentDiv(props: PropsWithChildren<{ id: string }>) {
  const activeMode = useActiveMode();

  const renderElement = () => {
    return <div>{props.children}</div>;
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement()}>
        <ContentDivEdit id={props.id} children={props.children} />
      </Suspense>
    );
  } else {
    return renderElement();
  }
}
