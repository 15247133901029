import { ChangeEvent, Fragment, ReactElement, useState } from "react";
import classNames from "classnames";

export interface formFileProps {
  element: string;
  name: string;
  labelText?: string;
  values: FileList[];
  getFiles?: (files: File[]) => void;
  dependencyFulfilled: boolean;
  allowedFileTypes: string[];
  required?: boolean;
  maxFileSize?: number;
  maxFiles?: number;
  inputTexts?: string[];
  subText?: string;
  handleInput: (file: File, number: number, name: string) => void;
}

export function FormFile(props: formFileProps) {
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      //get the number of the input
      const number = parseInt(e.target.getAttribute("data-number"));
      //check if there is a file in the array at the position of the input
      if (typeof files[number] !== "undefined") {
        // replace
        setFiles(
          files.map((file, i) => {
            if (i === number) {
              return e.target.files[0];
            }
            return file;
          }),
        );
      } else {
        //add the file to the array
        setFiles([...files, e.target.files[0]]);
      }
      //call the parent function
      props.handleInput(e.target.files[0], number, props.name);
    }
  };

  const createInputs = () => {
    const inputs: ReactElement[] = [];
    for (let i = 0; i < props.maxFiles; i++) {
      const inputText =
        typeof props.inputTexts !== "undefined"
          ? typeof props.inputTexts[i] !== "undefined"
            ? props.inputTexts[i]
            : "Hier klicken..."
          : "Hier klicken...";
      inputs.push(
        <Fragment key={props.name + i}>
          <input
            id={props.name + i}
            name={props.name + i}
            type="file"
            data-number={i}
            accept={
              typeof props.allowedFileTypes === "undefined"
                ? "*/*"
                : props.allowedFileTypes.join(",")
            }
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
          <label
            htmlFor={props.name + i}
            className={classNames(
              typeof files[i] !== "undefined" ? "checked" : null,
            )}
          >
            {typeof files[i] !== "undefined" ? files[i].name : inputText}
          </label>
        </Fragment>,
      );
    }
    return inputs;
  };
  return (
    <div className="files">
      {props.labelText ? (
        <p>
          {props.labelText}
          {typeof props.subText !== "undefined" ? (
            <>
              <br />
              {props.subText}
            </>
          ) : null}
        </p>
      ) : null}
      <span>{createInputs()}</span>
    </div>
  );
}
