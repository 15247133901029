import { useContext, useEffect, useState } from "react";
import { GridDivCounterContext } from "../../contexts/gridDivCounterContext";

export function useGridDivCounter() {
  const controller = useContext(GridDivCounterContext);
  const [id, setId] = useState<number>(null);
  useEffect(() => {
    const newEle = controller?.addGridDiv();
    if (newEle) {
      setId(newEle.id);
    }
    return () => {
      if (newEle) {
        controller.removeGrid(newEle.id);
      }
    };
  }, []);

  return { controller, id };
}
