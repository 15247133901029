import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { lazy, Suspense } from "react";
import { commonElementInterface } from "../../../interfaces/elements/common/commonElementInterface";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export interface elementPaddingInterface extends commonElementInterface {
  paddingOption?: elementPaddingOptions;
}

export enum elementPaddingOptions {
  small = "small",
  standard = "",
  large = "large",
}

export default function Abstand(props: elementPaddingInterface) {
  const activeMode = useActiveMode();

  const renderElement = (isEditMode: boolean) => {
    return (
      <div
        className={classNames(
          "padding",
          isEditMode ? "jslink" : "",
          props.paddingOption
            ? props.paddingOption
            : elementPaddingOptions.standard,
        )}
      >
        <span />
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
