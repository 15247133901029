import { Context, createContext } from "react";
import { contactDataInterface } from "../elements/popups/settings/global/contactData";
import { socialMediaDataInterface } from "../elements/popups/settings/global/socialMedia";
import { footerAddressDataInterface } from "../elements/app/structure/footer";

interface contactAndSocialMediaDataContextInterface {
  contact: contactDataInterface;
  socialMedia: socialMediaDataInterface;
  addressData: footerAddressDataInterface;
}

export const ContactAndSocialMediaDataContext: Context<contactAndSocialMediaDataContextInterface> =
  createContext({
    contact: {},
    socialMedia: {},
    addressData: {},
  } as contactAndSocialMediaDataContextInterface);
