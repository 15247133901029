import { Svg } from "../../../enums/svg";
import { SvgImage } from "../../app/basicElements/svgImage";
export function Autoresponder(props: {
  autoresponderData: {
    headline: string;
    text: string;
  };
  handleClosing: () => void;
}) {
  return (
    <div id="autoresponse" className="medium">
      <span>{props.autoresponderData.headline}</span>
      <div className="container">
        <hr className="half" />
        <p>{props.autoresponderData.text}</p>
        <hr />
      </div>
      <button id="rb" className="jsclose" onClick={props.handleClosing}>
        <SvgImage image={Svg.check} />
      </button>
    </div>
  );
}
