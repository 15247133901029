import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";

export function getMainMenu() {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.getMainMenu, {})
      .then((response: basicApiResponse) => {
        if (response.data.success) {
          resolve(response.data.result);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
