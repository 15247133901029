import { mutate } from "swr";
import { useLocation } from "react-router";
import { useContext } from "react";
import { ParallaxContext } from "../../../contexts/parallaxContext";

export function usePageMutator() {
  const location = useLocation();
  const parallax = useContext(ParallaxContext);
  const executeMutate = () => {
    void mutate(location.pathname).then(() => {
      if (parallax !== null) {
        parallax.updateAllElements();
      }
    });
  };

  return {
    reload: executeMutate,
  };
}
