import { lazy, Suspense, useContext, useState } from "react";
import { PageDesignContext } from "../../../contexts/pageDesignContext";
import classNames from "classnames";
import { SvgImage } from "../basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { contactTypes } from "../../../../backend/enums/database/kunden/contact/contactTypes";
import { socialMediaTypes } from "../../../../backend/enums/database/kunden/socialMedia/socialMediaTypes";
import { ContactAndSocialMediaDataContext } from "../../../contexts/contactAndSocialMediaDataContext";
import { FooterAddress } from "./footerAddress";
import AdminLoginButton from "../buttons/adminLoginButton";
export interface footerAddressDataInterface {
  company: string;
  owner: string;
  streetAddress: string;
  zip: string;
  city: string;
}

const LocationMap = lazy(
  () =>
    import(/* webpackChunkName: "locationMap" */ "../../popups/locationMap"),
);

export function Footer(props: { isMap: boolean }) {
  const pageDesign = useContext(PageDesignContext);
  const contactAndSocialMedia = useContext(ContactAndSocialMediaDataContext);
  const [locationMapOpen, setLocationMapOpen] = useState(false);
  const openLocationMap = () => {
    setLocationMapOpen(true);
  };
  const closeLocationMap = () => {
    setLocationMapOpen(false);
  };

  return (
    <footer
      className={classNames(
        pageDesign.footerStyle,
        pageDesign.footerColor,
        pageDesign.footerFontColor,
      )}
    >
      <div className="grid medium">
        <FooterAddress address={contactAndSocialMedia.addressData} />
        <div id="contact">
          {contactAndSocialMedia.contact[contactTypes.whatsapp] !== "" ? (
            <a
              target="_blank"
              href={
                "https://api.whatsapp.com/send?phone=" +
                contactAndSocialMedia.contact[contactTypes.whatsapp]
              }
              title="Whatsapp"
              rel="noopener noreferrer"
              key="contactwhatsapp"
            >
              <SvgImage image={Svg.whatsapp} />
            </a>
          ) : null}
          {contactAndSocialMedia.contact[contactTypes.phone] !== "" ? (
            <a
              target="_blank"
              href={"tel:" + contactAndSocialMedia.contact[contactTypes.phone]}
              title="Telefon"
              rel="noopener noreferrer"
              key="contactphone"
            >
              <SvgImage image={Svg.phone} />
            </a>
          ) : null}
          {contactAndSocialMedia.contact[contactTypes.mail] !== "" ? (
            <a
              target="_blank"
              href={
                "mailto:" + contactAndSocialMedia.contact[contactTypes.mail]
              }
              title="E-Mail"
              rel="noopener noreferrer"
              key="contactmail"
            >
              <SvgImage image={Svg.at} />
            </a>
          ) : null}
          {contactAndSocialMedia.contact[contactTypes.telegram] !== "" ? (
            <a
              target="_blank"
              href={
                "https://web.telegram.org/#/im?p=" +
                contactAndSocialMedia.contact[contactTypes.telegram]
              }
              title="Telegram"
              rel="noopener noreferrer"
              key="contacttelegram"
            >
              <SvgImage image={Svg.telegram} />
            </a>
          ) : null}
          {contactAndSocialMedia.contact[contactTypes.skype] !== "" ? (
            <a
              target="_blank"
              href={
                "https://web.skype.com/en/?p=" +
                contactAndSocialMedia.contact[contactTypes.skype]
              }
              title="Skype"
              rel="noopener noreferrer"
              key="contactskype"
            >
              <SvgImage image={Svg.skype} />
            </a>
          ) : null}
          {contactAndSocialMedia.contact[contactTypes.messenger] !== "" ? (
            <a
              target="_blank"
              href={
                "https://www.messenger.com/t/" +
                contactAndSocialMedia.contact[contactTypes.messenger]
              }
              title="Messenger"
              rel="noopener noreferrer"
              key="contactmessenger"
            >
              <SvgImage image={Svg.messenger} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.facebook] !==
          "" ? (
            <a
              target="_blank"
              href={
                contactAndSocialMedia.socialMedia[socialMediaTypes.facebook]
              }
              title="Facebook"
              rel="noopener noreferrer"
              key="socialmediafacebook"
            >
              <SvgImage image={Svg.facebook} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.instagram] !==
          "" ? (
            <a
              target="_blank"
              href={
                contactAndSocialMedia.socialMedia[socialMediaTypes.instagram]
              }
              title="Instagram"
              rel="noopener noreferrer"
              key="socialmediainstagram"
            >
              <SvgImage image={Svg.instagram} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.linkedin] !==
          "" ? (
            <a
              target="_blank"
              href={
                contactAndSocialMedia.socialMedia[socialMediaTypes.linkedin]
              }
              title="Linkedin"
              rel="noopener noreferrer"
              key="socialmedialinkedin"
            >
              <SvgImage image={Svg.linkedin} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.pinterest] !==
          "" ? (
            <a
              target="_blank"
              href={
                contactAndSocialMedia.socialMedia[socialMediaTypes.pinterest]
              }
              title="Pinterest"
              rel="noopener noreferrer"
              key="socialmediapinterest"
            >
              <SvgImage image={Svg.pinterest} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.twitter] !==
          "" ? (
            <a
              target="_blank"
              href={contactAndSocialMedia.socialMedia[socialMediaTypes.twitter]}
              title="Twitter"
              rel="noopener noreferrer"
              key="socialmediatwitter"
            >
              <SvgImage image={Svg.twitter} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.vimeo] !== "" ? (
            <a
              target="_blank"
              href={contactAndSocialMedia.socialMedia[socialMediaTypes.vimeo]}
              title="Vimeo"
              rel="noopener noreferrer"
              key="socialmediavimeo"
            >
              <SvgImage image={Svg.vimeo} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.xing] !== "" ? (
            <a
              target="_blank"
              href={contactAndSocialMedia.socialMedia[socialMediaTypes.xing]}
              title="Xing"
              rel="noopener noreferrer"
              key="socialmediaxing"
            >
              <SvgImage image={Svg.xing} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.youtube] !==
          "" ? (
            <a
              target="_blank"
              href={contactAndSocialMedia.socialMedia[socialMediaTypes.youtube]}
              title="Youtube"
              rel="noopener noreferrer"
              key="socialmediayoutube"
            >
              <SvgImage image={Svg.youtube} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.dailymotion] !==
          "" ? (
            <a
              target="_blank"
              href={
                contactAndSocialMedia.socialMedia[socialMediaTypes.dailymotion]
              }
              title="Dailymotion"
              rel="noopener noreferrer"
              key="socialmediadailymotion"
            >
              <SvgImage image={Svg.dailymotion} />
            </a>
          ) : null}
          {contactAndSocialMedia.socialMedia[socialMediaTypes.tiktok] !== "" ? (
            <a
              target="_blank"
              href={contactAndSocialMedia.socialMedia[socialMediaTypes.tiktok]}
              title="Tiktok"
              rel="noopener noreferrer"
              key="socialmediatiktok"
            >
              <SvgImage image={Svg.tiktok} />
            </a>
          ) : null}
        </div>
        {props.isMap ? (
          <div id="form">
            <div className="jslink" onClick={openLocationMap}>
              <SvgImage image={Svg.marker} />
              <span>Standortkarte</span>
            </div>
          </div>
        ) : null}
        {locationMapOpen ? (
          <Suspense fallback={null}>
            <LocationMap handleClosing={closeLocationMap} />
          </Suspense>
        ) : null}
        <div id="copyright">
          <a
            target="_blank"
            href="https://www.multitype.de"
            title="Multitype"
            rel="noopener noreferrer"
          >
            <p> © 2024 - Multitype CMS</p>
          </a>
        </div>
        <div id="developer">
          <AdminLoginButton>
            <SvgImage image={Svg.login} />
          </AdminLoginButton>
        </div>
      </div>
    </footer>
  );
}
