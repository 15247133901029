import { PropsWithChildren } from "react";
import { adminLoginState } from "../../../stateManagement/auth/adminLoginState";
import { AdminLoginContext } from "../../../contexts/adminLoginContext";
import { userRoles } from "../../../enums/userRoles";

type adminLoginProviderProps = PropsWithChildren<{
  isLogin: boolean;
  role: userRoles;
}>;
export function AdminLoginProvider({
  children,
  ...props
}: adminLoginProviderProps) {
  return (
    <AdminLoginContext.Provider value={adminLoginState(props)}>
      {children}
    </AdminLoginContext.Provider>
  );
}
