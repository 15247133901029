import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";
import { setToken } from "./token";
import { userRoles } from "../../../enums/userRoles";

interface checkLoginResponse extends basicApiResponse {
  data: {
    token: string;
    success: boolean;
    role: userRoles;
  };
}

export function checkLogin(
  initial?: boolean,
): Promise<{ valid: boolean; role: userRoles }> {
  return new Promise((resolve, reject) => {
    postToApi(initial ? apiUrls.checkLoginInitial : apiUrls.checkLogin, {})
      .then((result: checkLoginResponse) => {
        if (result.data.success) {
          setToken(result.data.token);
        }
        resolve({
          valid: result.data.success,
          role: result.data.role,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}
