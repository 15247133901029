import { useImageUrls } from "../../functions/api/graphics/useImageUrls";
import { SvgImage } from "../app/basicElements/svgImage";
import { Svg } from "../../enums/svg";
import { ImageZoomController } from "../../classes/imageZoomController";
import { useState } from "react";

export function ImageZoom(props: {
  image: string;
  handleClosing: () => void;
  title: string;
  description: string;
  controller: ImageZoomController;
  element: { id: number; imageSrc: string; title: string; description: string };
}) {
  const urls = useImageUrls();
  const [currentImage, setCurrentImage] = useState<{
    id: number;
    imageSrc: string;
    title: string;
    description: string;
  }>({
    id: props.element.id,
    imageSrc: props.image,
    title: props.title,
    description: props.description,
  });

  const nextImage = () => {
    const next = props.controller.getNextImage(currentImage.id);
    setCurrentImage(next);
  };
  const previousImage = () => {
    const previous = props.controller.getPreviousImage(currentImage.id);
    setCurrentImage(previous);
  };
  return (
    <div id="imagezoom" className="image">
      <span>{currentImage.title}</span>
      <div className="container image">
        <hr />
        <label className="img">
          <img src={urls.uploadOriginal + currentImage.imageSrc} alt="" />
        </label>
        <hr />
        <p>{currentImage.description}</p>
        <hr />
      </div>
      {props.controller.getImageCount() > 1 ? (
        <>
          <button id="rb" className="jslink" onClick={nextImage}>
            <SvgImage image={Svg.right} />
          </button>
          <button id="lb" className="jslink" onClick={previousImage}>
            <SvgImage image={Svg.left} />
          </button>
        </>
      ) : null}
      <button id="rt" className="jsclose" onClick={props.handleClosing}>
        <SvgImage image={Svg.delete} />
      </button>
    </div>
  );
}
