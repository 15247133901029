import { createPortal } from "react-dom";
import { createElement } from "react";
import { FeedbackDiv } from "./feedbackDiv";

export interface feedbackState {
  show: "loading" | "success" | "inactive" | "autoresponder";
  autoresponderData?: {
    headline: string;
    text: string;
  };
  handleClosing?: () => void;
}

/**
 * creates a feedback popup and renders it in the body via createPortal
 * @param show
 * @param autoresponderData
 * @param handleClosing
 * @constructor
 */
export function FeedbackPopup({
  show,
  autoresponderData,
  handleClosing,
}: feedbackState) {
  return createPortal(
    createElement(FeedbackDiv, {
      show: show,
      autoresponderData: autoresponderData,
      handleClosing: handleClosing,
    }),
    document.body,
  );
}
