import { postToApi } from "../apiCommunication/apiComm";
import { AxiosResponse } from "axios";
import { setToken } from "./token";
import { userRoles } from "../../../enums/userRoles";

export interface LoginResponseInterface extends AxiosResponse {
  data: {
    success: boolean;
    role: userRoles;
    token: string;
    error?: string;
  };
}

export function login(
  email: string,
  password: string,
  endPoint: string,
): Promise<userRoles> {
  return new Promise((resolve, reject) => {
    postToApi(endPoint, { email: email, password: password })
      .then((result: LoginResponseInterface) => {
        if (result.data.success) {
          setToken(result.data.token);
        }
        resolve(result.data.role);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
