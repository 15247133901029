import { animationInterface } from "../../../interfaces/animations/animationInterface";
import { textSizeAndPosInterface } from "../../../interfaces/elements/common/textSizeAndPosInterface";
import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { lazy, startTransition, Suspense } from "react";
import { ParseText } from "./parseText";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { paddingOptions } from "../../../enums/edit/paddingOptions";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export default function H1(props: {
  text: string;
  editorText: string;
  sizeAndPos: textSizeAndPosInterface;
  isLive: boolean;
  animations: animationInterface[];
  element: string;
  id: string;
  padding: paddingOptions;
}) {
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
    });
  };
  const renderElement = (isEditMode: boolean) => {
    return (
      <h1
        ref={refs}
        className={classNames(
          props.sizeAndPos.size,
          props.sizeAndPos.pos,
          animations.animationClasses,
          animations.animate,
          props.padding,
          isEditMode ? "jslink" : "",
        )}
      >
        <ParseText id={props.id} text={props.text} isEditMode={isEditMode} />
      </h1>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
