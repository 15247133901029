import { useActiveMode } from "../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../enums/edit/activeModes";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { SvgImage } from "../../elements/app/basicElements/svgImage";
import { Svg } from "../../enums/svg";
import { createElement } from "react";
import { elementPos } from "../../enums/edit/elementPos";

export function useElementMove(elementId: string) {
  const moveModeActive = useActiveMode().mode === activeModes.move;
  const draggable = useDraggable({
    id: elementId,
    disabled: !moveModeActive,
    data: { type: "element", id: elementId },
  });

  const droppableBefore = useDroppable({
    id: elementId + "_up",
    data: { type: "element", id: elementId, pos: elementPos.before },
    disabled: !moveModeActive,
  });
  const droppableAfter = useDroppable({
    id: elementId + "_down",
    data: { type: "element", id: elementId, pos: elementPos.after },
    disabled: !moveModeActive,
  });
  const droppableChild = useDroppable({
    id: elementId + "_child",
    data: { type: "element", id: elementId, pos: elementPos.children },
    disabled: !moveModeActive,
  });

  const isOverStyleUp = () => {
    if (droppableBefore.isOver) {
      return {
        backgroundColor: "darkgray",
      };
    }
    return {};
  };
  const isOverStyleDown = () => {
    if (droppableAfter.isOver) {
      return {
        backgroundColor: "darkgray",
      };
    }
    return {};
  };

  const isOverStyleChild = () => {
    if (droppableChild.isOver) {
      return {
        backgroundColor: "darkgray",
      };
    }
    return {};
  };
  const setDropBefore = () => {
    if (moveModeActive) {
      return createElement(
        "s",
        { ref: droppableBefore.setNodeRef, style: isOverStyleUp() },
        createElement(SvgImage, { image: Svg.download }),
      );
    } else {
      return null;
    }
  };

  const setDropAfter = () => {
    if (moveModeActive) {
      return createElement(
        "s",
        { ref: droppableAfter.setNodeRef, style: isOverStyleDown() },
        createElement(SvgImage, { image: Svg.download }),
      );
    } else {
      return null;
    }
  };

  const setDropChild = () => {
    if (moveModeActive) {
      return createElement(
        "s",
        { ref: droppableChild.setNodeRef, style: isOverStyleChild() },
        createElement(SvgImage, { image: Svg.download }),
      );
    } else {
      return null;
    }
  };

  const transformOnMove = () => {
    if (draggable.isDragging) {
      //return {};
      return {
        //transform: `translate3d(${draggable.transform.x}px, ${draggable.transform.y}px, 1px)`,
        opacity: draggable.transform.x === 0 ? 1 : 0.5,
        //zIndex: 900,
      };
    }
    return {};
  };

  const setListeners = () => {
    if (moveModeActive) {
      return draggable.listeners;
    }
    return {};
  };

  const setNodeRef = (e?: HTMLElement) => {
    if (moveModeActive) {
      if (e === undefined) {
        return draggable.setNodeRef;
      }
      draggable.setNodeRef(e);
    }
    return null;
  };

  const setAttributes = () => {
    if (moveModeActive) {
      return draggable.attributes;
    }
    return {};
  };

  return {
    transformOnMove,
    setDropAfter,
    setDropBefore,
    setDropChild,
    setListeners,
    setNodeRef,
    setAttributes,
    moveModeActive,
  };
}
