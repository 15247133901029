import classNames from "classnames";
import { activeModes } from "../../../enums/edit/activeModes";
import { lazy, Suspense } from "react";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);
export default function Line(props: {
  isLive: boolean;
  element: string;
  id: string;
}) {
  const activeMode = useActiveMode();

  const renderElement = (isEditMode: boolean) => {
    return (
      <div className={classNames("line", isEditMode ? "jslink" : "")}>
        <span />
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
