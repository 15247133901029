import {
  Dispatch,
  SetStateAction,
  startTransition,
  Suspense,
  useState,
} from "react";
import classNames from "classnames";
import { staticButtons } from "../../popups/edit/user/staticButtons";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import EditElement from "../../app/basicElements/editElement";
import { RegisterUser } from "../../popups/user/registerUser";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";
import { useUserLoginContext } from "../../../functions/auth/useUserLoginContext";

export default function UserRegisterButton(props: staticButtons) {
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const login = useAdminLoginContext((s) => s.isLogin);
  const userLogin = useUserLoginContext((s) => s.isLogin);

  const closeModal = (setModalState: Dispatch<SetStateAction<boolean>>) => {
    setModalState(false);
  };

  const openModal = () => {
    if (login) {
      return;
    }
    startTransition(() => {
      setIsLoginModalOpen(true);
    });
  };
  const renderElement = (isEditMode: boolean) => {
    if (userLogin === true) {
      return null;
    }
    return (
      <div
        className={classNames(
          "button",
          props.pos,
          props.stil,
          animations.animationClasses,
          animations.animate,
          isEditMode ? "jslink" : "",
        )}
        onClick={openModal}
      >
        <button>{"Registrierung"}</button>
        {isLoginModalOpen && (
          <Suspense fallback={null}>
            <RegisterUser
              handleClosing={() => closeModal(setIsLoginModalOpen)}
            />
          </Suspense>
        )}
      </div>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
