import { create } from "zustand";
import { produce } from "immer";
import { activeModes } from "../../enums/edit/activeModes";

export interface activeModeProps {
  mode: activeModes;
  setMode: (newMode: activeModes) => void;
}

/**
 * state for the active site mode (edit, add, move, off)
 */
export const useActiveMode = create<activeModeProps>()((set) => ({
  mode: activeModes.off,
  setMode: (newMode: activeModes) =>
    set(
      produce((state: activeModeProps) => {
        state.mode = newMode;
      }),
    ),
}));
