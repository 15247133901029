import { apiUrls } from "../../enums/apiUrls";
import { getCurrentHost } from "../url/getCurrentHost";
import { getCurrentPath } from "../url/getCurrentPath";

export function sendStatsData(
  loadTime: Date,
  unloadTime: Date,
  innerWidth: number,
  innerHeight: number,
  language: string,
  userAgent: string,
  referrer: string,
  sessionRem: string,
  sessionTemp: string,
  path: string,
): void {
  const data = {
    statsData: {
      loadTime,
      unloadTime,
      innerWidth,
      innerHeight,
      language,
      userAgent,
      referrer,
      sessionRem,
      sessionTemp,
    },
    host: getCurrentHost(),
    path: path || getCurrentPath(),
    lang: "de",
  };
  navigator.sendBeacon(apiUrls.sendData, JSON.stringify(data));
}
