import { videoInterface } from "../../../interfaces/elements/videoInterface";
import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { supportedVideoSites } from "../../../enums/edit/supportedVideoSites";
import { useVideoCookie } from "../../../stateManagement/cookies/useVideoCookie";
import classNames from "classnames";
import {
  Suspense,
  useEffect,
  lazy,
  MouseEvent,
  startTransition,
  useRef,
  useCallback,
} from "react";
import { useAnimations } from "../../../functions/elements/useAnimations";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import { useNavigate } from "react-router";
import { useImageUrls } from "../../../functions/api/graphics/useImageUrls";
import { useInView } from "react-intersection-observer";

const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

export default function Video(props: videoInterface) {
  const animations = useAnimations(props.animations);
  const activeMode = useActiveMode();
  const navigate = useNavigate();
  const url = useImageUrls();
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    void useVideoCookie.persist.rehydrate();
  }, []);
  const videoState = useVideoCookie();
  const [inViewRef] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        if (videoRef.current && videoRef.current.paused) {
          void videoRef.current.play();
        }
      }
    },
  });

  const acceptYoutube = useCallback(() => {
    videoState.setTrue(supportedVideoSites.youtube);
  }, [videoState]);

  const youtubeMessage = useCallback(() => {
    return (
      <span className="youtube">
        <div className="poscenter">
          <SvgImage image={Svg.youtube} />
          <button onClick={acceptYoutube}>Youtube aktivieren</button>
          <p className="poscenter small">
            Ich bin damit einverstanden, dass mir Inhalte von Youtube angezeigt
            werden. Damit können personenbezogene Daten an Youtube übermittelt
            werden. Dazu ist ggf. die Speicherung von Cookies auf deinem Gerät
            notwendig. Mehr Informationen findest du in den
            Datenschutzbestimmungen.
          </p>
        </div>
      </span>
    );
  }, [acceptYoutube]);

  const acceptVimeo = useCallback(() => {
    videoState.setTrue(supportedVideoSites.vimeo);
  }, [videoState]);
  const vimeoMessage = () => {
    return (
      <span className="vimeo">
        <div className="poscenter">
          <SvgImage image={Svg.vimeo} />
          <button onClick={acceptVimeo}>Vimeo aktivieren</button>
          <p className="poscenter small">
            Ich bin damit einverstanden, dass mir Inhalte von Vimeo angezeigt
            werden. Damit können personenbezogene Daten an Vimeo übermittelt
            werden. Dazu ist ggf. die Speicherung von Cookies auf deinem Gerät
            notwendig. Mehr Informationen findest du in den
            Datenschutzbestimmungen.
          </p>
        </div>
      </span>
    );
  };

  const acceptDailyMotion = () => {
    videoState.setTrue(supportedVideoSites.dailymotion);
  };
  const dailymotionMessage = () => {
    return (
      <span className="dailymotion">
        <div className="poscenter">
          <SvgImage image={Svg.dailymotion} />
          <button onClick={acceptDailyMotion}>Vimeo aktivieren</button>
          <p className="poscenter small">
            Ich bin damit einverstanden, dass mir Inhalte von Vimeo angezeigt
            werden. Damit können personenbezogene Daten an Vimeo übermittelt
            werden. Dazu ist ggf. die Speicherung von Cookies auf deinem Gerät
            notwendig. Mehr Informationen findest du in den
            Datenschutzbestimmungen.
          </p>
        </div>
      </span>
    );
  };
  const cookieMessage = () => {
    switch (props.embeddedData.videoSite) {
      case supportedVideoSites.youtube:
        return youtubeMessage();
      case supportedVideoSites.vimeo:
        return vimeoMessage();
      case supportedVideoSites.dailymotion:
        return dailymotionMessage();
    }
  };
  const checkCookie = () => {
    switch (props.embeddedData.videoSite) {
      case supportedVideoSites.youtube:
        return videoState.youtube;
      case supportedVideoSites.vimeo:
        return videoState.vimeo;
      case supportedVideoSites.dailymotion:
        return videoState.dailymotion;
    }
  };

  const onClickHandlerVideo = (e: MouseEvent<HTMLElement>) => {
    if (activeMode.mode === activeModes.grid) {
      e.preventDefault();
      return;
    }
    if (activeMode.mode !== activeModes.off) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (
      props.uploadData.controls === false &&
      props.uploadData.function.name !== "" &&
      props.uploadData.function.param !== ""
    ) {
      if (props.uploadData.function.name.startsWith("link")) {
        navigate("/" + props.uploadData.function.param);
        return;
      }
    }
  };
  const onClickHandler = (e: MouseEvent<HTMLElement>) => {
    if (activeMode.mode === activeModes.grid) {
      e.preventDefault();
      return;
    }
    if (activeMode.mode !== activeModes.off) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const refs = (el: HTMLElement) => {
    startTransition(() => {
      animations.ref(el);
      inViewRef(el);
    });
  };

  const renderElement = (isEditMode: boolean) => {
    if (props.mode === "upload") {
      return (
        <div
          className={classNames(
            "videointern",
            props.format,
            isEditMode ? "jslink" : "",
            activeMode.mode === activeModes.move ? "videodrag" : "",
            animations.animationClasses,
            animations.animate,
          )}
          ref={refs}
          onClick={onClickHandlerVideo}
        >
          <video
            loop={true}
            controls={props.uploadData.controls}
            muted={true}
            ref={videoRef}
            playsInline={true}
            preload="metadata"
            className={classNames(props.radius)}
          >
            <source
              src={url.video + props.uploadData.videoName + ".mp4"}
              type="video/mp4"
            />
          </video>
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            "video",
            props.format,
            isEditMode ? "jslink" : "",
            activeMode.mode === activeModes.move ? "videodrag" : "",
            animations.animationClasses,
            animations.animate,
          )}
          ref={refs}
          onClick={onClickHandler}
        >
          {checkCookie() ? (
            <iframe
              src={props.embeddedData.url}
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          ) : (
            cookieMessage()
          )}
        </div>
      );
    }
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
