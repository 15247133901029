import {
  ChangeEvent,
  HTMLProps,
  lazy,
  ReactNode,
  Suspense,
  useRef,
  useState,
} from "react";
import { FormText, formTextProps } from "./elements/formText";
import { FormTextInput, formTextInputProps } from "./elements/formTextInput";
import { FormCheckbox, formCheckboxProps } from "./elements/formCheckbox";
import { FormSelect, formSelectProps } from "./elements/formSelect";
import { FormSendButton, formSendButtonProps } from "./elements/formSendButton";
import { produce } from "immer";
import { FormRadio, formRadioProps } from "./elements/formRadio";
import { FormTextArea, formTextAreaProps } from "./elements/formTextArea";
import classNames from "classnames";
import { useActiveMode } from "../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../enums/edit/activeModes";
import { sendOnPageForm } from "../../../functions/api/page/sendOnPageForm";
import { useFeedback } from "../../../functions/elements/useFeedback";
import { delay } from "lodash";
import { FormFile, formFileProps } from "./elements/formFile";
const EditElement = lazy(
  () =>
    import(
      /* webpackChunkName: "editElement" */ "../../app/basicElements/editElement"
    ),
);

const onPageFormElements = {
  FormText: (props: formTextProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormText {...props} key={key} />;
  },
  FormTextInput: (props: formTextInputProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormTextInput {...props} key={key} />;
  },
  FormCheckbox: (props: formCheckboxProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormCheckbox {...props} key={key} />;
  },
  FormSelect: (props: formSelectProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormSelect {...props} key={key} />;
  },
  FormSendButton: (props: formSendButtonProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormSendButton {...props} key={key} />;
  },
  FormRadio: (props: formRadioProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormRadio {...props} key={key} />;
  },
  FormTextArea: (props: formTextAreaProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormTextArea {...props} key={key} />;
  },
  FormFile: (props: formFileProps) => {
    const data = props;
    const key = data["key"] as string;
    data["keyId"] = key;
    delete data["key"];
    return <FormFile {...props} key={key} />;
  },
};

export enum onPageFormInputTypes {
  FormText = "FormText",
  FormSelect = "FormSelect",
  FormTextInput = "FormTextInput",
  FormCheckbox = "FormCheckbox",
  FormSendButton = "FormSendButton",
  FormRadio = "FormRadio",
  FormTextArea = "FormTextArea",
  FormFile = "FormFile",
}

export type onPageFormDataType = (
  | formTextProps
  | formTextInputProps
  | formCheckboxProps
  | formSelectProps
  | formSendButtonProps
  | formRadioProps
  | formTextAreaProps
  | formFileProps
)[];

enum dependencyTypes {
  exact = "exact",
  contains = "contains",
  not = "not",
  notEmpty = "notEmpty",
}

export default function OnPageForm(props: {
  isLive: boolean;
  element: string;
  id: string;
  formContent: onPageFormDataType;
  formId: number;
}) {
  const [formContentData, setFormContentData] = useState({});
  const [valueCache, setValueCache] = useState({});
  const activeMode = useActiveMode();
  const [botDetect, setBotDetect] = useState("");
  const [showError, setShowError] = useState(false);
  const feedback = useFeedback();
  const formRef = useRef<HTMLFormElement>(null);
  const [fileKeys, setFileKeys] = useState<{ name: string; key: string }[]>([]);
  const jsonToHTML = (element: string, daten: HTMLProps<never>) => {
    if (typeof onPageFormElements[element] !== "undefined") {
      return onPageFormElements[element](daten);
    } else {
      return "";
    }
  };
  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setFormContentData(
      produce(formContentData, (draft) => {
        draft[e.currentTarget.name] = e.currentTarget.value;
      }),
    );
  };

  const handleFileInput = (file: File, number: number, name: string) => {
    if (typeof formContentData[name][number] !== "undefined") {
      // replace
      setFormContentData(
        produce(formContentData, (draft) => {
          draft[name][number] = file;
        }),
      );
    } else {
      //add the file to the array
      setFormContentData(
        produce(formContentData, (draft) => {
          if (draft[name] === "") {
            draft[name] = [file];
          } else {
            draft[name].push(file);
          }
        }),
      );
    }
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    setFormContentData(
      produce(formContentData, (draft) => {
        draft[e.currentTarget.name] = e.currentTarget.checked;
      }),
    );
  };

  const handleRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setFormContentData(
      produce(formContentData, (draft) => {
        draft[e.currentTarget.name] = e.currentTarget.dataset.value;
      }),
    );
  };

  const handleFormSend = () => {
    if (botDetect.trim() !== "") {
      console.log("bot detected");
      return;
    }
    feedback.showLoading();
    //handle form send
    //check if all required fields are filled
    let allFilled = true;
    const requiredFields = [];
    props.formContent.forEach((element) => {
      if (
        element["required"] !== undefined &&
        element["required"] === true &&
        (element["element"] as onPageFormInputTypes) !==
          onPageFormInputTypes.FormCheckbox &&
        (formContentData[element["name"]] === undefined ||
          formContentData[element["name"]] === "")
      ) {
        allFilled = false;
        requiredFields.push(element["name"]);
      } else if (
        (element["element"] as onPageFormInputTypes) ===
          onPageFormInputTypes.FormCheckbox &&
        element["required"] === true
      ) {
        let allFalse = true;

        element["values"].forEach((value) => {
          if (formContentData[value.name] === true) {
            allFalse = false;
          }
        });
        if (allFalse) {
          allFilled = false;
          requiredFields.push(element["name"]);
        }
      }
    });
    if (!allFilled) {
      setShowError(true);
      feedback.hideFeedback();
      return;
    }

    //all ok send form
    //remove all empty fields
    const formToSend = {};
    let filesToSend: File[] = [];
    Object.keys(formContentData).forEach((key) => {
      if (formContentData[key] !== "" && formContentData[key] !== false) {
        if (typeof formContentData[key] === "object") {
          filesToSend = formContentData[key] as File[];

          formToSend[key] = formContentData[key].map((file: File) => file.name);
          return;
        }

        formToSend[key] = formContentData[key];
        if (typeof formContentData[key] === "boolean") {
          formToSend[key] = formContentData[key] ? "ja" : "nein";
        }
      }
    });
    sendOnPageForm(props.formId, formToSend, filesToSend)
      .then((response) => {
        if (response) {
          feedback.showSuccess();
          delay(() => {
            feedback.hideFeedback();
            setFormContentData({});
            setFileKeys([]);
            if (formRef.current !== null) {
              formRef.current.reset();
            }
            setShowError(false);
          }, 2500);
        } else {
          console.log("form not sent");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // later maybe refactor
  const buildForm = () => {
    //build form from props.formContent
    if (props.formContent === null) {
      return null;
    }
    const elems: ReactNode[] = [];
    const initialFormContentData = {};
    props.formContent.forEach((element, i) => {
      if (typeof element["element"] === "undefined") {
        return;
      }
      element["key"] = props.id + i;
      element["showError"] = showError;
      let key = "";
      switch (element["element"] as onPageFormInputTypes) {
        case onPageFormInputTypes.FormCheckbox:
          element["handleInput"] = handleCheckBox;
          break;
        case onPageFormInputTypes.FormRadio:
          element["handleInput"] = handleRadio;
          break;
        case onPageFormInputTypes.FormSendButton:
          element["handleInput"] = handleFormSend;
          break;
        case onPageFormInputTypes.FormText: //ignore text
          break;
        case onPageFormInputTypes.FormFile:
          // look if name is already in fileKeys
          fileKeys.forEach((fileKey) => {
            if (fileKey.name === element["name"]) {
              key = fileKey.key;
            }
          });
          if (key === "") {
            key = Math.random().toString(36).substring(7);
            setFileKeys(
              produce(fileKeys, (draft) => {
                draft.push({ name: element["name"], key: key });
              }),
            );
          }
          element["key"] = key;
          element["handleInput"] = handleFileInput;
          break;
        default:
          element["handleInput"] = handleInput;
          break;
      }
      element["dependencyFulfilled"] = true;
      if (
        (element["element"] as onPageFormInputTypes) ===
        onPageFormInputTypes.FormTextInput
      ) {
        if (formContentData[element["name"]] !== undefined) {
          element["value"] = formContentData[element["name"]];
        } else {
          element["value"] = "";
        }
      }
      if (element["dependency"] !== undefined) {
        if (
          typeof formContentData[element["dependency"]["elem"]] === "undefined"
        ) {
          element["dependencyFulfilled"] = false;
        } else {
          switch (element["dependency"]["type"]) {
            case dependencyTypes.exact:
              if (
                formContentData[element["dependency"]["elem"]] !==
                element["dependency"]["value"]
              ) {
                element["dependencyFulfilled"] = false;
              }
              break;
            case dependencyTypes.contains:
              if (
                formContentData[element["dependency"]["elem"]].indexOf(
                  element["dependency"]["value"],
                ) === -1
              ) {
                element["dependencyFulfilled"] = false;
              }
              break;
            case dependencyTypes.not:
              if (
                formContentData[element["dependency"]["elem"]] ===
                element["dependency"]["value"]
              ) {
                element["dependencyFulfilled"] = false;
              }
              break;
            case dependencyTypes.notEmpty:
              if (formContentData[element["dependency"]["elem"]] === "") {
                element["dependencyFulfilled"] = false;
              }
              break;
          }
        }
        if (element["dependencyFulfilled"] === false) {
          if (
            formContentData[element["name"]] !== undefined &&
            formContentData[element["name"]] !== ""
          ) {
            if (element["element"] === onPageFormInputTypes.FormTextInput) {
              setValueCache(
                produce(valueCache, (draft) => {
                  draft[element["name"]] = formContentData[element["name"]];
                }),
              );
            }
            setFormContentData(
              produce(formContentData, (draft) => {
                draft[element["name"]] = "";
              }),
            );
          }
        } else {
          if (
            valueCache[element["name"]] !== undefined &&
            valueCache[element["name"]] !== "" &&
            formContentData[element["name"]] === "" &&
            element["element"] === onPageFormInputTypes.FormTextInput
          ) {
            element["value"] = valueCache[element["name"]];
            setFormContentData(
              produce(formContentData, (draft) => {
                draft[element["name"]] = valueCache[element["name"]];
              }),
            );
          }
        }
      }
      elems.push(
        jsonToHTML(element["element"], element as unknown as HTMLProps<never>),
      );
      switch (element["element"] as onPageFormInputTypes) {
        case onPageFormInputTypes.FormSelect:
          initialFormContentData[element["name"]] = element["options"][0].value;
          break;
        case onPageFormInputTypes.FormCheckbox:
          for (const value of element["values"]) {
            initialFormContentData[value.name] = false;
          }
          break;
        case onPageFormInputTypes.FormText: //ignore text
        case onPageFormInputTypes.FormSendButton: //ignore button
          break;
        default:
          initialFormContentData[element["name"] as string] = "";
          break;
      }
    });
    if (Object.keys(formContentData).length === 0) {
      setFormContentData(initialFormContentData);
    }
    return elems;
  };

  const handleHiddenInput = (e: ChangeEvent<HTMLInputElement>) => {
    setBotDetect(e.currentTarget.value);
  };

  const renderElement = (isEditMode: boolean) => {
    return (
      <form
        className={classNames(
          isEditMode ? "jslink" : "",
          activeMode.mode === activeModes.move ? "objectdrag" : "",
        )}
        ref={formRef}
      >
        <div>
          {buildForm()}
          <label htmlFor={"formhidden" + props.id} className={"inactive"}>
            <input
              type={"input"}
              name={"formhidden" + props.id}
              id={"formhidden" + props.id}
              onChange={handleHiddenInput}
            />
          </label>
        </div>
        {feedback.feedbackState.show !== "inactive"
          ? feedback.currentFeedback()
          : null}
      </form>
    );
  };

  if (activeMode.mode !== activeModes.off) {
    return (
      <Suspense fallback={renderElement(false)}>
        <EditElement currentElement={renderElement} elementProps={props} />
      </Suspense>
    );
  } else {
    return renderElement(false);
  }
}
