import { cloneElement, MouseEvent, PropsWithChildren } from "react";
import useGridMove from "../../../../functions/elements/useGridMove";
import { JSX } from "react/jsx-runtime";

export function GridMover(
  props: PropsWithChildren<{
    currentGrid: () => JSX.Element;
    gridSpecial: string | boolean;
    gridId: string;
  }>,
) {
  const gridMove = useGridMove(props.gridId);
  const constructGrid = () => {
    return cloneElement(props.currentGrid(), {
      ref: gridMove.setNodeRef,
      ...gridMove.setListeners(),
      ...gridMove.setAttributes(),
      style: gridMove.transformOnMove(),
      draggable: gridMove.gridModeActive ? true : null,
      onClick: (e: MouseEvent) => {
        e.preventDefault();
      },
    });
  };
  return (
    <>
      {props.gridSpecial !== "first" ? gridMove.setDropBefore() : null}
      {constructGrid()}
      {props.gridSpecial !== "last" ? gridMove.setDropAfter() : null}
    </>
  );
}
