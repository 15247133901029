import { RefObject, useRef } from "react";
import { useIsMounted } from "../generall/useIsMounted";

export function useBarOut(barRef: RefObject<HTMLDivElement>, time = 250) {
  const isMounted = useIsMounted();
  const isScroll = useRef<NodeJS.Timeout>(null);
  const addListener = () => {
    if (!isMounted) return;
    window.addEventListener(
      "scroll",
      () => {
        if (barRef.current) {
          window.clearTimeout(isScroll.current);
          barRef.current.classList.add("out");
          isScroll.current = setTimeout(() => {
            if (!barRef.current) return;
            barRef.current.classList.remove("out");
          }, time);
        }
      },
      { passive: true },
    );
  };

  const removeListener = () => {
    if (!isMounted) return;
    window.removeEventListener("scroll", () => {
      if (barRef.current) {
        window.clearTimeout(isScroll.current);
        barRef.current.classList.add("out");
        isScroll.current = setTimeout(() => {
          if (!barRef.current) return;
          barRef.current.classList.remove("out");
        }, time);
      }
    });
  };

  return {
    addListener: addListener,
    removeListener: removeListener,
  };
}
