import parse, { Element } from "html-react-parser";

export const ParseText = (props: {
  id: string;
  text: string;
  isEditMode: boolean;
}) => {
  const text = props.text.replace(/\n/g, "<br />");

  if (!props.isEditMode) {
    return parse(text, {});
  } else {
    return parse(text, {
      // @ts-expect-error no idea typescript
      transform(reactNode, domNode, i) {
        if ((domNode as Element).name === "a") {
          return (
            <u key={props.id + i}>{(domNode as Element).children[0]["data"]}</u>
          );
        } else {
          return reactNode;
        }
      },
    });
  }
};
