import { PropsWithChildren, useRef } from "react";
import { StatsController } from "../../../classes/statsController";
import { StatsContext } from "../../../contexts/statsContext";

export function StatsProvider(props: PropsWithChildren) {
  const useStatsContext = useRef<null | StatsController>(null);

  if (typeof window !== "undefined" && useStatsContext.current === null)
    useStatsContext.current = new StatsController();

  return (
    <StatsContext.Provider value={useStatsContext.current}>
      {props.children}
    </StatsContext.Provider>
  );
}
