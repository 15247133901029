import InlineSVG from "react-inlinesvg";
import { useLogoUrl } from "../../../functions/url/useLogoUrl";
import { useContext } from "react";
import { UrlContext } from "../../../contexts/urlContext";
import { Link } from "react-router";
import { PageDesignContext } from "../../../contexts/pageDesignContext";
import classNames from "classnames";
export function Logo(props: { title: string }) {
  const logoUrl = useLogoUrl();
  const url = useContext(UrlContext);
  const pageDesign = useContext(PageDesignContext);
  return pageDesign.logoPosition === "hidelogo" ? null : (
    <div className={classNames("logo")}>
      <Link to={url} title={props.title} viewTransition>
        <InlineSVG src={logoUrl} cacheRequests={false} loader={null} />
      </Link>
    </div>
  );
}
