import { useContext } from "react";
import { ImageZoomController } from "../../classes/imageZoomController";
import { imageZoomContext } from "../../contexts/imageZoomContext";

export function useImageZoomController(): ImageZoomController | null {
  const imageZoomController = useContext(imageZoomContext);
  const isServer = typeof window === "undefined";
  if (isServer) {
    return null;
  }

  if (!imageZoomController) {
    throw new Error("Error no imageZoomProvider set");
  }

  return imageZoomController;
}
