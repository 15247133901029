import PopupWrapper from "../popupWrapper";
import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { ChangeEvent, useState } from "react";
import { userRegisterProps } from "../../../interfaces/auth/userProps";

export function RegisterUser(props: { handleClosing: () => void }) {
  const [registerState, setRegisterState] = useState<userRegisterProps>({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    passwordRepeat: "",
    passwordRepeatError: false,
    emailError: false,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRegisterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkRepeatPassword = () => {
    if (registerState.password !== registerState.passwordRepeat) {
      setRegisterState((prevState) => ({
        ...prevState,
        passwordRepeatError: true,
      }));
    } else {
      setRegisterState((prevState) => ({
        ...prevState,
        passwordRepeatError: false,
      }));
    }
  };

  const handleRegister = () => {
    console.log(registerState);
  };

  return (
    <PopupWrapper>
      <div className="large">
        <span>Registrieren</span>
        <form onSubmit={handleRegister} id="userRegisterForm">
          <div className="container">
            <hr className="half" />
            <label htmlFor="userFirstname">
              <span className="icon">Vorname</span>
              <SvgImage image={Svg.user} />
              <input
                className="icon"
                type="text"
                name="firstname"
                id="userFirstname"
                value={registerState.firstname}
                onChange={handleChange}
              />
            </label>
            <label htmlFor="userLastName">
              <span className="icon">Nachname</span>
              <SvgImage image={Svg.user} />
              <input
                className="icon"
                type="text"
                name="lastname"
                id="userLastName"
                value={registerState.lastname}
                onChange={handleChange}
              />
            </label>
            <label htmlFor="userEmail">
              <span className="icon">E-Mail</span>
              <SvgImage image={Svg.at} />
              <input
                className="icon"
                type="email"
                name="email"
                id="userEmail"
                value={registerState.email}
                onChange={handleChange}
              />
            </label>
            {registerState.emailError && (
              <p className="error">Die E-Mail wird bereit verwendet </p>
            )}
            <label htmlFor="userPassword">
              <span className="icon">Passwort</span>
              <SvgImage image={Svg.key} />
              <input
                className="icon"
                type="password"
                name="password"
                id="userPassword"
                value={registerState.password}
                onChange={handleChange}
              />
            </label>
            <label htmlFor="userPasswordRepeat">
              <span className="icon">Passwort wiederholen</span>
              <SvgImage image={Svg.key} />
              <input
                className="icon"
                type="password"
                name="passwordRepeat"
                id="userPasswordRepeat"
                value={registerState.passwordRepeat}
                onChange={handleChange}
                onBlur={checkRepeatPassword}
              />
            </label>
            {registerState.passwordRepeatError && (
              <p className="error">Passwörter stimmen nicht überein</p>
            )}
            <hr />
          </div>
        </form>
        <button id="rt" onClick={props.handleClosing}>
          <SvgImage image={Svg.delete} />
        </button>
        <button id="rb" onClick={handleRegister}>
          <SvgImage image={Svg.check} />
        </button>
      </div>
    </PopupWrapper>
  );
}
