import { ReactNode } from "react";
import { BrowserRouter, Route, Routes } from "react-router";
import ScrollToTop from "./scrollToTop";
export function Routing(props: { app: ReactNode }) {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/:path?/:param?" element={props.app} loader={null} />
      </Routes>
    </BrowserRouter>
  );
}
