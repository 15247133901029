import {
  Children,
  lazy,
  PropsWithChildren,
  RefObject,
  startTransition,
  useContext,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { elementPos } from "../../../../enums/edit/elementPos";
import { useElementAdding } from "../../../../functions/elements/useElementAdding";
import { useGridEdit } from "../../../../functions/elements/useGridEdit";
import { gridInterface } from "../../../../interfaces/elements/grid/gridInterface";
import { gridStil } from "../../../../enums/edit/grid/gridStil";
import { elementEditorModes } from "../../../../enums/edit/elementEditorModes";
import { useUserGridMediaUrl } from "../../../../functions/url/useUserGridMediaUrl";
import { GridSvg } from "./gridSvg";
import { PageDesignContext } from "../../../../contexts/pageDesignContext";
import Section from "../section";
import ArticleHeader from "../../../app/structure/articleHeader";
import ArticleFooter from "../../../app/structure/articleFooter";
import GridPicture from "./gridPicture";
import GridVideo from "./gridVideo";
import { useParallax } from "../../../../functions/elements/useParallax";
import { useActiveMode } from "../../../../stateManagement/edit/useActiveMode";
import { activeModes } from "../../../../enums/edit/activeModes";
import { useElementMove } from "../../../../functions/elements/useElementMove";
import { useAdminLoginContext } from "../../../../functions/auth/useAdminLoginContext";
import { GridMover } from "./gridMover";
import { useGridDivCounter } from "../../../../functions/elements/useGridDivCounter";

const GridEditor = lazy(
  () =>
    import(
      /* webpackChunkName: "gridEditor" */ "../../../popups/edit/gridEditor"
    ),
);

export default function GridDiv(props: PropsWithChildren<gridInterface>) {
  const imageGridUrls = useUserGridMediaUrl();
  const elementAdding = useElementAdding();
  const gridDivCounter = useGridDivCounter();
  const gridSpecial = gridDivCounter.controller.getSpecial(gridDivCounter.id);
  const gridEdit = useGridEdit(props.id);
  const pageDesign = useContext(PageDesignContext);
  const activeMode = useActiveMode();
  const elementMove = useElementMove(props.id);
  const login = useAdminLoginContext((s) => s.isLogin);
  const [gridVisible, setGridVisible] = useState(props.gridVisible);
  const { ref, pictureRef, imageRef } = useParallax(props.gridStil);
  const isEmpty = () => {
    if (activeMode.mode === activeModes.off) return null;
    if (Children.toArray(props.children).length === 0) {
      if (activeMode.mode === activeModes.add) {
        return elementAdding.elementAddElement(elementPos.children, props.id);
      }
      if (activeMode.mode === activeModes.move) {
        return elementMove.setDropChild();
      }
      return null;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (login === true && !gridVisible) {
      startTransition(() => {
        setGridVisible(true);
      });
    } else if (login === false && props.gridVisible === false) {
      startTransition(() => {
        setGridVisible(false);
      });
    }
  }, [gridVisible, login, props.gridVisible]);

  const prepareSvgForEdit = () => {
    return props.svgImages.map((svgImage) => {
      return {
        ...svgImage,
        url: imageGridUrls.svg + svgImage.url + ".svg",
      };
    });
  };
  const editGrid = () => {
    if (gridEdit.isEditing === false) return null;
    if (props.image !== "") {
      return (
        <GridEditor
          elementData={{
            ...props,
            image: imageGridUrls.source + props.image + ".webp",
            svgImages: prepareSvgForEdit(),
            special: gridSpecial,
          }}
          mode={elementEditorModes.edit}
          handleElementSaving={gridEdit.handleGridSaving}
          handleClosing={gridEdit.handleClosing}
          handleDelete={gridEdit.handleSetDelete}
        />
      );
    } else if (props.video !== "") {
      return (
        <GridEditor
          elementData={{
            ...props,
            video: imageGridUrls.video + props.video + ".mp4",
            svgImages: prepareSvgForEdit(),
            special: gridSpecial,
          }}
          mode={elementEditorModes.edit}
          handleElementSaving={gridEdit.handleGridSaving}
          handleClosing={gridEdit.handleClosing}
          handleDelete={gridEdit.handleSetDelete}
        />
      );
    } else {
      return (
        <GridEditor
          elementData={{
            ...props,
            svgImages: prepareSvgForEdit(),
            special: gridSpecial,
          }}
          mode={elementEditorModes.edit}
          handleElementSaving={gridEdit.handleGridSaving}
          handleClosing={gridEdit.handleClosing}
          handleDelete={gridEdit.handleSetDelete}
        />
      );
    }
  };

  const isPicture = () => {
    return (
      props.gridStil === gridStil["Bild (fest)"] ||
      props.gridStil === gridStil["Bild (mitlaufend)"]
    );
  };
  const isVideo = () => {
    return (
      props.gridStil === gridStil["Video (fest)"] ||
      props.gridStil === gridStil["Video (mitlaufend)"]
    );
  };

  const hasPicture = () => {
    if (isPicture()) {
      return (
        <GridPicture
          stil={props.gridStil}
          imageName={props.imageName}
          cacheString={props.cacheString}
          mediaValues={getMediaValues()}
          pictureRef={pictureRef}
          imageRef={imageRef as RefObject<HTMLImageElement>}
          imageSize={props.imageSize}
        />
      );
    } else {
      return null;
    }
  };

  const hasVideo = () => {
    if (isVideo()) {
      return (
        <GridVideo
          stil={props.gridStil}
          videoName={props.video}
          cacheString={props.cacheString}
          mediaValues={getMediaValues()}
          pictureRef={pictureRef as RefObject<HTMLDivElement>}
          imageRef={imageRef as RefObject<HTMLVideoElement>}
        />
      );
    } else {
      return null;
    }
  };

  const getMediaValues = () => {
    if (isPicture() || isVideo()) {
      return [props.posY, props.posX, props.filter];
    }
    return null;
  };

  const svgObjects = () => {
    let i = 0;
    return props.svgImages.map((svgImage) => {
      return (
        <GridSvg
          key={svgImage.url + props.id + i++}
          image={svgImage.url}
          posY={svgImage.posY}
          posX={svgImage.posX}
          width={svgImage.width}
          level={svgImage.level}
          animations={svgImage.animations}
        />
      );
    });
  };

  const setHeaderFooterClasses = () => {
    if (gridSpecial === "first") {
      return [
        pageDesign.headerStyle,
        pageDesign.headerColor,
        pageDesign.logoPosition === "hidelogo" ? pageDesign.logoPosition : null,
      ];
    }
    if (gridSpecial === "last") {
      return [pageDesign.footerStyle, pageDesign.footerColor];
    }
    return null;
  };

  const displayGrid = () => {
    if (gridSpecial === "first") {
      return (
        <ArticleHeader>
          {isEmpty()}
          {props.children}
        </ArticleHeader>
      );
    }
    if (gridSpecial === "last") {
      return (
        <ArticleFooter>
          {isEmpty()}
          {props.children}
        </ArticleFooter>
      );
    }
    return (
      <Section>
        {isEmpty()}
        {props.children}
      </Section>
    );
  };

  const gridElement = () => {
    return (
      <div
        className={classNames(
          "grid",
          props.gridWidth,
          props.gridStil,
          props.minHeight,
          setHeaderFooterClasses(),
          props.gridVisible === false ? "invisible" : null,
          props.contentPosition ? props.contentPosition : "center",
        )}
        onClick={gridEdit.activateEdit}
        ref={ref}
      >
        {svgObjects()}
        {hasPicture()}
        {hasVideo()}
        {displayGrid()}
      </div>
    );
  };

  if (!gridVisible) return null;
  if (activeMode.mode === activeModes.gridMove) {
    return (
      <GridMover
        currentGrid={gridElement}
        gridSpecial={gridSpecial}
        gridId={props.id}
      />
    );
  }
  return (
    <>
      {gridSpecial !== "first"
        ? gridEdit.gridAdd(elementPos.before, props.id)
        : null}
      {gridElement()}
      {gridEdit.editPortal(editGrid())}
    </>
  );
}
