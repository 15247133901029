const randomString = {
  _pattern: /[a-zA-Z0-9_]/,

  _getRandomByte: () => {
    let result: Uint8Array;
    if (window.crypto && window.crypto.getRandomValues) {
      result = new Uint8Array(1);
      window.crypto.getRandomValues(result);

      return result[0];
    } else {
      return Math.floor(Math.random() * 256);
    }
  },

  generate: (length: number) => {
    return Array.from({ length: length })
      .map(() => {
        let result: string;
        do {
          result = String.fromCharCode(randomString._getRandomByte());
        } while (!randomString._pattern.test(result));
        return result;
      })
      .join("");
  },
};

export function createIdentifier(length: number): string {
  return randomString.generate(length);
}
