import { pageSeoData } from "../../../../backend/interfaces/requests/page/pageSeoData";
import { type } from "../../../../backend/enums/database/kunden/openGraph/type";
import { useImageUrls } from "../../../functions/api/graphics/useImageUrls";

interface Props {
  seo: pageSeoData;
  openGraph?: { type: type; value: string }[];
  style?: string;
  host?: string;
}

export function HeaderContent({ seo, openGraph, style, host }: Props) {
  const imageUrls = useImageUrls();
  const renderOpenGraph = () => {
    if (!openGraph || openGraph.length === 0) {
      return (
        <meta
          property="og:image"
          content={imageUrls.shareImage}
          key="ogimage"
        />
      );
    }

    let hasPicture = false;
    const openGraphData = openGraph.map(({ type: type, value }) => {
      switch (type) {
        case type["picture"]:
          hasPicture = true;
          return (
            <meta
              property="og:image"
              content={value || imageUrls.shareImage}
              key="ogimage"
            />
          );
        case type["alt"]:
          return <meta property="og:image:alt" content={value} key="ogalt" />;
        case type["description"]:
          return (
            <meta property="og:description" content={value} key="ogdesc" />
          );
        case type["title"]:
          return <meta property="og:title" content={value} key="ogtitle" />;
        default:
          return null;
      }
    });

    if (!hasPicture) {
      openGraphData.push(
        <meta
          property="og:image"
          content={imageUrls.shareImage}
          key="ogimage"
        />,
      );
    }

    return openGraphData;
  };
  const generateCssLink = () => {
    return (
      (host ? host : "") +
      "/style/" +
      (typeof style !== "undefined" ? style : "standard") +
      ".css"
    );
  };

  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="stylesheet"
        type="text/css"
        precedence="high"
        href={generateCssLink()}
      />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      {renderOpenGraph()}
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="article" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
    </>
  );
}
