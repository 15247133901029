import { useEffect, useState } from "react";
import { useImageZoomController } from "./useImageZoomController";
import { useIsMounted } from "../generall/useIsMounted";

export function useImageZoom(
  isZoom: boolean,
  imageSrc: string,
  title: string,
  description: string,
) {
  const controller = useImageZoomController();
  const [element, setElement] = useState<{
    id: number;
    imageSrc: string;
    title: string;
    description: string;
  }>();
  const isMounted = useIsMounted();

  useEffect(() => {
    let newEle: {
      id: number;
      imageSrc: string;
      title: string;
      description: string;
    };
    if (isZoom && isMounted) {
      newEle = controller?.addImage(imageSrc, title, description);
      if (newEle) {
        setElement(newEle);
      }
    }
    return () => {
      if (newEle) {
        controller.removeImage(newEle.id);
      }
    };
  }, [isMounted, isZoom, imageSrc]);

  return { controller, element };
}
