import { lazy, Suspense, useContext, useEffect, useRef, useState } from "react";
import {
  stickyContactInterface,
  stickyContactType,
} from "../../popups/settings/global/editStickyContact";
import { SvgImage } from "../basicElements/svgImage";
import { createPortal } from "react-dom";
import { ContactAndSocialMediaDataContext } from "../../../contexts/contactAndSocialMediaDataContext";
import { contactTypes } from "../../../../backend/enums/database/kunden/contact/contactTypes";
import { useIsMounted } from "../../../functions/generall/useIsMounted";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { useBarOut } from "../../../functions/elements/useBarOut";

const ContactForm = lazy(
  () =>
    import(
      /* webpackChunkName: "contactForm" */ "../../popups/contact/contactForm"
    ),
);

const CustomForm = lazy(
  () =>
    import(
      /* webpackChunkName: "customForm" */ "../../popups/contact/customForm"
    ),
);
export function StickyContact(props: {
  display: boolean;
  stickyContactData: stickyContactInterface;
}) {
  const [stickyContactOpen, setStickyContactOpen] = useState<boolean>(false);
  const contactAndSocialMedia = useContext(ContactAndSocialMediaDataContext);
  const isMounted = useIsMounted();
  const barRef = useRef(null);
  const barOut = useBarOut(barRef);
  useEffect(() => {
    if (isMounted) {
      barOut.addListener();
    } else {
      barOut.removeListener();
    }
  }, [barOut, isMounted]);
  const navigate = useNavigate();
  const openContact = () => {
    switch (props.stickyContactData.type) {
      case stickyContactType.contactform:
      case stickyContactType.special:
        setStickyContactOpen(true);
        break;
      case stickyContactType.email:
        window.location.href = `mailto:${contactAndSocialMedia.contact[contactTypes.mail]}`;
        return;
      case stickyContactType.phone:
        window.location.href = `tel:${contactAndSocialMedia.contact[contactTypes.phone]}`;
        return;
      case stickyContactType.link:
        navigate("/" + props.stickyContactData.link);
        return;
    }
  };

  const handleClosing = () => {
    setStickyContactOpen(false);
  };

  const getOpenContact = () => {
    switch (props.stickyContactData.type) {
      case stickyContactType.contactform:
        return (
          <Suspense fallback={null}>
            <ContactForm handleClosing={handleClosing} />
          </Suspense>
        );
      case stickyContactType.special:
        return (
          <Suspense fallback={null}>
            <CustomForm
              handleClosing={handleClosing}
              customFormNumber={props.stickyContactData.customForm}
            />
          </Suspense>
        );
      default:
        return null;
    }
  };

  if (!props.display || !isMounted) {
    return null;
  } else {
    return createPortal(
      <>
        <div id="fastcontact" className={classNames("bar")} ref={barRef}>
          <div className="jslink" onClick={openContact}>
            <SvgImage image={props.stickyContactData.svg} />
            <span>
              {props.stickyContactData.title !== "" ? (
                <>
                  <strong>{props.stickyContactData.title}</strong>
                  <br />
                </>
              ) : null}
              {props.stickyContactData.text !== ""
                ? props.stickyContactData.text
                : null}
            </span>
          </div>
        </div>
        {stickyContactOpen ? getOpenContact() : null}
      </>,
      document.body,
    );
  }
}
