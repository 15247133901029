import { useContext } from "react";
import { UrlContext } from "../../../contexts/urlContext";

export function useImageUrls() {
  const url = useContext(UrlContext);

  return {
    uploadLarge: url + "/media/upload/large/",
    uploadBig: url + "/media/upload/big/",
    uploadMedium: url + "/media/upload/medium/",
    uploadSmall: url + "/media/upload/small/",
    uploadOriginal: url + "/media/upload/original/",
    images: url + "/media/images/",
    svgObject: url + "/media/object/",
    download: url + "/media/download/",
    formImage: url + "/media/form/",
    video: url + "/media/video/",
    shareImage: url + "/share.png",
  };
}
