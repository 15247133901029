import { useUserGridMediaUrl } from "../../../../functions/url/useUserGridMediaUrl";
import { gridPosX, gridPosY } from "../../../../enums/edit/grid/gridMediaPos";
import classNames from "classnames";
import { animationInterface } from "../../../../interfaces/animations/animationInterface";
import { useAnimations } from "../../../../functions/elements/useAnimations";

export function GridSvg(props: {
  image: string;
  posY: gridPosY;
  posX: gridPosX;
  width: string;
  level: number;
  animations: animationInterface[];
  cacheString?: string;
}) {
  const url = useUserGridMediaUrl();
  const animations = useAnimations(props.animations);
  const setSource = () => {
    if (props.cacheString) {
      return url.svg + props.image + ".svg?" + props.cacheString;
    } else {
      return url.svg + props.image + ".svg";
    }
  };
  return (
    <img
      className={classNames(
        "gridsvg",
        "svg" + props.level,
        props.posX,
        props.posY,
        animations.animationClasses,
        animations.animate,
      )}
      src={setSource()}
      style={{ minWidth: props.width + "%" }}
      alt="Hintergrund Svg"
      ref={animations.ref}
    />
  );
}
