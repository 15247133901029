import { GridDivCounterContext } from "../../../contexts/gridDivCounterContext";
import { GridDivCounterController } from "../../../classes/GridDivCounterController";
import { PropsWithChildren } from "react";

export function GridDivCounterProvider(props: PropsWithChildren) {
  return (
    <GridDivCounterContext.Provider value={new GridDivCounterController()}>
      {props.children}
    </GridDivCounterContext.Provider>
  );
}
