import PopupWrapper from "../popupWrapper";
import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { elementEditorModes } from "../../../enums/edit/elementEditorModes";
import { ChangeEvent, useEffect, useState } from "react";
import {
  customElementDataInterface,
  customElementInterface,
} from "../../../interfaces/elements/customElementInterface";
import { getCustomElements } from "../../../functions/api/edit/getCustomElements";

export function CustomElementEdit(props: {
  elementData: customElementInterface;
  mode: elementEditorModes;
  handleElementSaving: (elementData: customElementInterface) => void;
  handleClosing?: () => void;
  handleDelete?: () => void;
}) {
  const [editorState, setEditorState] = useState<customElementDataInterface>(
    props.elementData.customElementData,
  );

  const [customElements, setCustomElements] = useState<
    customElementDataInterface[]
  >([]);

  useEffect(() => {
    //load custom elements from server
    getCustomElements()
      .then((data: customElementDataInterface[]) => {
        data.unshift({
          id: 0,
          name: "Drucken",
          contents: [
            {
              element: "cePrint",
            },
          ],
        });
        setCustomElements(data);
        if (editorState.id === 0) {
          setEditorState(data[0]);
        } else {
          setEditorState(
            data.find((element) => element.id === editorState.id) || data[0],
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const handleElementSaving = () => {
    props.handleElementSaving({
      ...props.elementData,
      customElementData: editorState,
    });
  };

  const handleSelectedElement = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedElement = customElements.find(
      (element) => element.id === Number(e.target.value),
    );
    if (selectedElement) {
      setEditorState(selectedElement);
    }
  };

  return (
    <PopupWrapper>
      <div id="customElements" className="large">
        <span>Spezial Elemente</span>
        <div className="container">
          <hr className="half" />
          <label htmlFor="costumeElementSelect">
            <span className="icon">
              Element
              <span></span>
            </span>
            <SvgImage image={Svg.button} />
            <select onChange={handleSelectedElement} value={editorState.id}>
              {customElements.map((form) => {
                return (
                  <option key={form.name + "elementOption"} value={form.id}>
                    {form.name}
                  </option>
                );
              })}
            </select>
          </label>
          <hr />
        </div>
        <button id="rt" onClick={props.handleClosing}>
          <SvgImage image={Svg.delete} />
        </button>
        {props.mode === elementEditorModes.edit && (
          <button id="lb" onClick={props.handleDelete}>
            <SvgImage image={Svg.trash} />
          </button>
        )}
        <button id="rb" onClick={handleElementSaving}>
          <SvgImage image={Svg.check} />
        </button>
      </div>
    </PopupWrapper>
  );
}
